import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  isLoadingTotalStockAvailable: false,
  error: null,
  stocks: {
    data: [],
    meta: {
      page: {},
    },
  },
  stock: {},
  stocksByWarehouse: {
    data: [],
    meta: {
      page: {},
    },
  },
  globalReports: {
    meta: {
      total: 0,
      total_nilai_barang_perusahaan: 0,
      total_nilai_barang_stockist: 0,
    },
    data: [],
  },
  totalStockAvailable: {
    total_price_stock_available: 0,
    total_point_stock_available: 0,
    total_bonus_stock_available: 0,
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getLoadingTotalStockAvailable: (state) => {
    return state.isLoadingTotalStockAvailable;
  },
  getStocks: (state) => {
    return state.stocks;
  },
  getStock: (state) => {
    return state.stock;
  },
  getStocksByWarehouse: (state) => {
    return state.stocksByWarehouse;
  },
  getError: (state) => {
    return state.error;
  },
  getGlobalReports: (state) => {
    return state.globalReports;
  },
  getTotalStockAvailable: (state) => {
    return state.totalStockAvailable;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setLoaderTotalStockAvailable: (state, status) =>
    (state.isLoadingTotalStockAvailable = status),
  setStocks: (state, data) => (state.stocks = data),
  setStock: (state, data) => (state.stock = data),
  setStocksByWarehouse: (state, data) => (state.stocksByWarehouse = data),
  setError: (state, data) => (state.error = data),
  setGlobalReports: (state, data) => (state.globalReports = data),
  setTotalStockAvailable: (state, data) => (state.totalStockAvailable = data),
};

const actions = {
  async fetchStocks(
    { commit },
    { keyword, pageNumber, pageSize, warehouseId, search, ...others }
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    if (keyword && keyword !== '') filter['product_code'] = keyword;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    Object.assign(params, { sort: '-createdAt' });

    try {
      let response = await ApiService.get(
        warehouseId
          ? `api/v1/warehouses/${warehouseId}/stocks`
          : 'api/v1/stocks',
        {
          params,
        }
      );
      commit('setStocks', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStocks', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchStockById({ commit }, { id, params }) {
    commit('setLoader', true);

    try {
      const res = await ApiService.get(`/api/v1/stocks/${id}`, { params });

      commit('setStock', res.data);
      commit('setError', null);

      return res;
    } catch (err) {
      commit('setError', err);
    } finally {
      commit('setLoader', false);
    }
  },
  async updateStockById({ commit, dispatch }, { id, payload }) {
    commit('setLoader', true);

    try {
      const res = await ApiService.patch(`/api/v1/stocks/${id}`, payload);

      commit('setStock', res.data);
      commit('setError', null);
      dispatch(
        'alert/createAlert',
        { data: 'Berhasil mengubah stocks', status: 'info' },
        { root: true }
      );

      return res;
    } catch (err) {
      commit('setError', err);
      dispatch(
        'alert/createAlert',
        { data: 'Gagal mengubah stocks', status: 'error' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchStocksByWarehouse(
    { commit },
    {
      warehouse_id,
      keyword,
      pageNumber,
      pageSize,
      search,
      sortName,
      sortDirection,
      ...others
    }
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    sortName = sortName || 'createdAt';
    sortDirection = sortDirection || 'desc';
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    if (keyword && keyword !== '') filter['product_code'] = keyword;
    if (sortDirection === 'desc') sortName = '-' + sortName;

    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    Object.assign(params, { sort: sortName });
    try {
      let response = await ApiService.get(
        'api/v1/warehouses/' + warehouse_id + '/stocks',
        {
          params,
        }
      );
      commit('setStocks', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStocks', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchGlobalReports({ dispatch, commit }, { params } = {}) {
    commit('setLoader', true);

    try {
      const res = await ApiService.get('api/v1/stocks/-actions/global-report', {
        params,
      });

      commit('setGlobalReports', res.data);
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: 'Gagal memuat laporan stok global', status: 'error' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchTotalStockAvailable({ dispatch, commit }, { params } = {}) {
    commit('setLoaderTotalStockAvailable', true);

    try {
      const res = await ApiService.get(
        'api/v1/stocks/-actions/total-stock-available',
        {
          params,
        }
      );

      commit('setTotalStockAvailable', res.data);
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: 'Gagal memuat total stok tersedia', status: 'error' },
        { root: true }
      );
    } finally {
      commit('setLoaderTotalStockAvailable', false);
    }
  },
  async exportPdf({ dispatch }, { params } = {}) {
    try {
      const res = await ApiService.get('api/v1/stocks/-actions/export-pdf', {
        params,
        responseType: 'blob',
      });

      return res.data;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: 'Gagal export pdf', status: 'error' },
        { root: true }
      );
    }
  },
  async exportExcel({ dispatch }, { params } = {}) {
    try {
      const res = await ApiService.get('api/v1/stocks/-actions/export-excel', {
        params,
      });

      return res.data;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: 'Gagal export excel', status: 'error' },
        { root: true }
      );
    }
  },
  async exportGlobalReports({ dispatch }, { params } = {}) {
    try {
      const res = await ApiService.get(
        'api/v1/stocks/-actions/global-report-export',
        {
          params,
        }
      );

      return res.data;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: 'Gagal export excel', status: 'error' },
        { root: true }
      );
    }
  },
  async downloadGlobalReports({ dispatch }, { params } = {}) {
    try {
      const res = await ApiService.get(
        'api/v1/stocks/-actions/global-report-download',
        {
          params,
          responseType: 'blob',
        }
      );

      return res.data;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: 'Gagal download excel', status: 'error' },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
