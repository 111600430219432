<template>
  <div class="flex min-h-screen justify-center bg-gray-50 px-4">
    <div class="relative hidden w-0 flex-1 lg:flex">
      <div class="flex w-full items-center justify-center p-8">
        <LoginIllustration
          src="@/assets/images/404.png"
          class="object-cover"
          alt="Login Illustrator"
        />
      </div>
    </div>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div
        class="flex flex-col justify-center rounded-xl bg-white py-12 px-10 shadow-xl sm:px-6 md:mx-8 lg:flex-none lg:px-12"
      >
        <div class="mx-auto w-full lg:w-96">
          <div class="flex flex-col">
            <div class="flex justify-center">
              <NasaLogo class="h-24" alt="Logo Nasa" />
            </div>
            <h2 class="mt-6 text-xl font-extrabold text-gray-900 sm:text-2xl">
              Kelola bisnis melalui browser kesayangan Anda.
            </h2>
            <p class="mt-2 text-sm text-gray-600">Dan raih hasil maksimal.</p>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <AlertMessage
                v-if="getError"
                class="mb-4"
                type="error"
                show
                :message="getError"
              />
              <base-form
                class="space-y-6"
                id="user_login"
                v-slot="{ errors }"
                v-on:submit="login"
                :form="form"
              >
                <base-input
                  v-model="form.email"
                  id="email"
                  name="email"
                  type="text"
                  autocomplete="email"
                  label="Email atau ID"
                  placeholder="Email atau ID"
                  :invalid="errors.email"
                  :message="errors.email"
                  fullwidth
                  with-label
                  :shadow="false"
                />
                <div class="relative">
                  <base-input
                    v-model="form.password"
                    id="password"
                    name="password"
                    :type="passwordType == 'password' ? 'password' : 'text'"
                    autocomplete="current-password"
                    fullwidth
                    with-label
                    label="Kata Sandi"
                    placeholder="Kata Sandi"
                    :invalid="errors.password"
                    :message="errors.password"
                    :shadow="false"
                  />
                  <svg
                    v-if="passwordType === 'password'"
                    xmlns="http://www.w3.org/2000/svg"
                    class="absolute top-5 right-3 h-5 w-5 cursor-pointer hover:opacity-80"
                    fill="none"
                    style="top: 2.4rem"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    @click="changeShowPassword"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="absolute bottom-3 right-3 h-5 w-5 cursor-pointer hover:opacity-80"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    style="top: 2.4rem"
                    stroke-width="2"
                    @click="changeShowPassword"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                </div>
                <div>
                  <base-button
                    type="submit"
                    fullwidth
                    :disabled="getAuthLoading || !isCaptchaValid"
                    :loading="getAuthLoading"
                    >Sign In</base-button
                  >
                </div>
                <div class="flex items-center justify-center">
                  <div class="text-sm">
                    <router-link
                      :to="{ name: 'ForgotPassword' }"
                      class="font-medium text-green-600 hover:text-green-500"
                    >
                      Lupa kata sandi?
                    </router-link>
                  </div>
                </div>
              </base-form>
            </div>
          </div>
        </div>
      </div>
      <button
        @click="openRelease()"
        class="mt-4 flex justify-center text-xs text-gray-400"
      >
        Versi {{ version }}
      </button>

      <transition name="fade">
        <release-note @on-close="openRelease" v-if="showRelease" />
      </transition>
    </div>
    <loading v-if="getAuthLoading" />
    <!-- <UnderDevelopment/> -->
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AlertMessage from '@/components/base/AlertMessage.vue';
// import GoogleCaptcha from '@/components/login/google-captcha.vue';
import { StorageService } from '@/services/storage.service';
import { checkRole } from '@/services/utils.service';
import { version } from '../../package.json';
import Pusat from '@/router/modules/pusat';
import Stockist from '@/router/modules/stockist';
import StockistCenter from '@/router/modules/stockist_center';
import Member from '@/router/modules/member';
import Pelayanan from '@/router/modules/kantor_pelayanan';
import VueRouter from 'vue-router';
import router from '@/router';
import { baseRoutes } from '@/router/base';
import { LoginIllustration, NasaLogo } from '@/assets/images';
import BaseForm from '@/components/base/BaseForm.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import ReleaseNote from '@/components/ReleaseNote.vue';
import BaseButton from '@/components/base/BaseButton.vue';
// import { loadScript } from '../services/script.service';
// import UnderDevelopment from './UnderDevelopment.vue';

export default {
  name: 'Login',
  components: {
    AlertMessage,
    NasaLogo,
    LoginIllustration,
    BaseForm,
    BaseInput,
    ReleaseNote,
    BaseButton,
    // UnderDevelopment
    // GoogleCaptcha,
  },
  data() {
    const isDevelopment = process.env.NODE_ENV === 'development';
    return {
      version: version,
      showRelease: false,
      passwordType: 'password',
      isCaptchaValid: true,
      isDevelopment,
      form: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      getError: 'auth/getError',
      getAuthLoading: 'auth/getLoading',
    }),
  },
  methods: {
    ...mapActions({
      authLogin: 'auth/login',
      authFetchUserCategory: 'auth/fetchUserOfficeCategory',
      detailOfficeCategory: 'office_categories/detailOfficeCategory',
      fetchOfficeCategories: 'office_categories/fetchOfficeCategories',
      fetchMenus: 'menus/fetchMenus',
    }),
    ...mapMutations({
      setAuthLoading: 'auth/setLoader',
      setAvailableOfficeCategories:
        'office_categories/setAvailableOfficeCategories',
    }),
    changeShowPassword() {
      if (this.showPassword) {
        this.passwordType = 'password';
      } else {
        this.passwordType = 'text';
      }
      this.showPassword = !this.showPassword;
    },
    onSuccessCaptcha() {
      this.isCaptchaValid = true;
    },
    login() {
      if (!this.isCaptchaValid) {
        return;
      }
      this.authLogin(this.form).then((response) => {
        if (response) {
          this.fetchMenus({});
          this.fetchOfficeCategories({
            'fields[office-categories]':
              'name,cashback_jawa,cashback_luar_jawa,min_order_jawa,min_order_luar_jawa',
          }).then((response) => {
            this.setAvailableOfficeCategories(response.data.data);
          });
          this.authFetchUserCategory().then((response) => {
            if (response) {
              let base = baseRoutes;
              // register router by role
              if (StorageService.getUser() !== null && checkRole()) {
                if (checkRole().attributes.name === 'Pusat') {
                  base = base.concat(Pusat);
                } else if (checkRole().attributes.name === 'Stockist') {
                  base = base.concat(Stockist);
                } else if (checkRole().attributes.name === 'Stockist Center') {
                  base = base.concat(StockistCenter);
                } else if (checkRole().attributes.name === 'Member') {
                  base = base.concat(Member);
                } else if (checkRole().attributes.name === 'Kantor Pelayanan') {
                  base = base.concat(Pelayanan);
                }
              }

              const createRouter = () =>
                new VueRouter({
                  mode: 'history',
                  routes: base,
                });
              const newRouter = createRouter();
              router.matcher = newRouter.matcher; // the relevant part

              this.$router.push('/');
            }
          });
        }
      });
    },
    openRelease() {
      this.showRelease = !this.showRelease;
    },
    redirectUser(office) {
      if (office === 'admin') {
        this.$router.push('/admin');
      } else if (office === 'Pusat') {
        this.$router.push('/');
      } else if (office === 'penjualan_pusat') {
        this.$router.push('/penjualan');
      } else if (office === 'kasir_pusat') {
        this.$router.push('/kasir/');
      } else if (office === 'Kantor Pelayanan') {
        this.$router.push('/kantor-pelayanan');
      } else if (office === 'Stockist Center') {
        this.$router.push('/');
      } else if (office === 'Stockist') {
        this.$router.push('/stockist');
      }
    },
  },
  mounted() {
    // loadScript('https://www.google.com/recaptcha/api.js');
  },
  created() {
    this.setAuthLoading(false);
    const handleEscape = () => {
      this.showRelease = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
