const LayoutDefault = () =>
  import(/* webpackChunkName: "bonus" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "bonus" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/dashboard/Index.vue'
  );
// Download Bonus
const DownloadBonus = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/download/Index.vue'
  );
const MasterBarang = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/admin/MasterBarang.vue'
  );
const MasterStockist = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/admin/MasterStockist.vue'
  );
const MasterMitraUsaha = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/admin/MasterMitraUsaha.vue'
  );
const DataMitraUsaha = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/bonus/DataMitraUsaha.vue'
  );
const DataCashbill = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/bonus/DataCashbill.vue'
  );
const DataTambahPoint = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/bonus/DataTambahBonus.vue'
  );
const ToolHapusMitraUsaha = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/tool/hapus-mitra-usaha.vue'
  );
const ToolTambahMitraUsaha = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/tool/tambah-mitra-usaha.vue'
  );
const ToolUpdateUplineMitraUsaha = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/tool/update-upline-mitra-usaha.vue'
  );
const ToolGenerateMitraUsahaNd = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/tool/generate-mitra-usaha-nd.vue'
  );
const ToolTaridDataKomisi = () =>
  import(
    /* webpackChunkName: "bonus" */ '@/views/pusat/bonus/tool/tarik-data-komisi.vue'
  );

let root = '/bonus';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/download/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: DownloadBonus,
        meta: {
          auth: true,
          title: 'Download Bonus',
          layout: LayoutDefault,
          breadcrumb: 'Download Bonus',
        },
      },
    ],
  },
  {
    path: root + '/admin/',
    component: LayoutData,
    children: [
      {
        path: 'master-barang',
        component: MasterBarang,
        meta: {
          auth: true,
          title: 'Master Barang',
          layout: LayoutDefault,
          breadcrumb: 'Master Barang',
        },
      },
      {
        path: 'master-stockist',
        component: MasterStockist,
        meta: {
          auth: true,
          title: 'Master Stockist',
          layout: LayoutDefault,
          breadcrumb: 'Master Stockist',
        },
      },
      {
        path: 'master-mitra-usaha',
        component: MasterMitraUsaha,
        meta: {
          auth: true,
          title: 'Master Mitra Usaha',
          layout: LayoutDefault,
          breadcrumb: 'Master Mitra Usaha',
        },
      },
    ],
  },
  {
    path: root + '/bonus/',
    component: LayoutData,
    children: [
      {
        path: 'data-mitra-usaha',
        component: DataMitraUsaha,
        meta: {
          auth: true,
          title: 'Data Mitra Usaha',
          layout: LayoutDefault,
          breadcrumb: 'Data Mitra Usaha',
        },
      },
      {
        path: 'data-cashbill',
        component: DataCashbill,
        meta: {
          auth: true,
          title: 'Data Cashbill',
          layout: LayoutDefault,
          breadcrumb: 'Data Cashbill',
        },
      },
      {
        path: 'data-tambahan-poin',
        component: DataTambahPoint,
        meta: {
          auth: true,
          title: 'Data Tambahan Poin',
          layout: LayoutDefault,
          breadcrumb: 'Data Tambahan Poin',
        },
      },
    ],
  },
  // Menu Tool
  {
    path: root + '/tool/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Tool',
    },
    children: [
      {
        path: 'hapus-mitra-usaha',
        component: ToolHapusMitraUsaha,
        meta: {
          auth: true,
          title: 'Hapus Mitra Usaha',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-mitra-usaha',
        component: ToolTambahMitraUsaha,
        meta: {
          auth: true,
          title: 'Tambah / Update Mitra Usaha',
          layout: LayoutDefault,
        },
      },
      {
        path: 'update-upline-mitra-usaha',
        component: ToolUpdateUplineMitraUsaha,
        meta: {
          auth: true,
          title: 'Update Upline Mitra Usaha',
          layout: LayoutDefault,
        },
      },
      {
        path: 'generate-mitra-usaha-nd',
        component: ToolGenerateMitraUsahaNd,
        meta: {
          auth: true,
          title: 'Generate Mitra Usaha ND',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tarik-data-komisi',
        component: ToolTaridDataKomisi,
        meta: {
          auth: true,
          title: 'Tarik Data Komisi',
          layout: LayoutDefault,
        },
      },
    ]
  }
];
