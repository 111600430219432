import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  orderDetails: {
    data: [],
    included: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getError: (state) => {
    return state.error;
  },
  getOrderDetails: (state) => state.orderDetails,
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setError: (state, data) => (state.error = data),
  setOrderDetails: (state, data) => (state.orderDetails = data),
};

const actions = {
  async fetchOrderDetailsByOrderId({ commit, dispatch }, { orderId, params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get(
        `api/v1/orders/${orderId}/order-details`,
        {
          params,
        }
      );

      commit('setError', null);
      commit('setOrderDetails', response.data);

      return response;
    } catch (error) {
      commit('setError', error);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, status: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async createOrderDetail({ commit, dispatch }, { payload, params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.post(
        'api/v1/order-details',
        payload,
        params
      );

      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, status: 'error' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async updateOrderDetail({ commit, dispatch }, { id, payload, params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.patch(
        `api/v1/order-details/${id}`,
        payload,
        params
      );

      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, status: 'error' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async deleteOrderDetail({ commit, dispatch }, { id }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.delete(`api/v1/order-details/${id}`);

      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
