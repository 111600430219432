import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  users: {
    data: [],
    meta: {
      page: {},
    },
  },
  user: {},
  rolesByUser: {
    data: [],
  },
  verify: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getUsers: (state) => {
    return state.users;
  },
  getUser: (state) => {
    return state.user;
  },
  getRolesByUser: (state) => {
    return state.rolesByUser;
  },
  getVerify: (state) => {
    return state.verify;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setUsers: (state, data) => (state.users = data),
  setUser: (state, data) => (state.user = data),
  setRolesByUser: (state, data) => (state.rolesByUser = data),
  setVerify: (state, data) => (state.verify = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchUsers(
    { commit },
    {
      pageNumber,
      pageSize,
      search,
      fields,
      include,
      withoutSort,
      officeCategoryId,
      ...others
    }
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    let url = 'api/v1/users';
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    if (include || include == '') {
      Object.assign(params, { include });
    } else {
      Object.assign(params, {
        include: 'office',
      });
    }
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    if (!withoutSort) {
      Object.assign(params, { sort: '-id' });
    }
    if (officeCategoryId) {
      url = `api/v1/office-categories/${officeCategoryId}/users`;
    }
    try {
      let response = await ApiService.get(url, {
        params,
      });
      commit('setUsers', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setUsers', { data: [], meta: { page: {} } });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchUser({ commit }, { userId, params }) {
    commit('setLoader', true);

    try {
      let response = await ApiService.get(`api/v1/users/${userId}`, {
        params,
      });
      commit('setUser', response.data);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setUser', { data: [] });
    }
    commit('setLoader', false);
  },
  async createUser({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/users', payload);
      commit('setUser', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setUser', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async fetchRolesByUser({ commit }, { user_id }) {
    commit('setLoader', true);
    let response = await ApiService.get('api/v1/users/' + user_id + '/roles');
    commit('setRolesByUser', response.data);
    commit('setLoader', false);
  },
  async updateUser({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/users/' + payload.data.id,
        payload
      );
      commit('setUser', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchUsersByOffice({ commit }, { officeId, params }) {
    try {
      let response = await ApiService.get(`api/v1/offices/${officeId}/users`, {
        params,
      });
      commit('setUsers', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setUsers', { data: [], meta: { page: {} } });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async verify({ commit }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/email/verify/resend');
      commit('setVerify', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async uploadAvatar({ commit, dispatch }, { id, payload }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        `api/v1/users/${id}/-actions/uploadAvatar`,
        payload
      );
      dispatch(
        'alert/createAlert',
        { data: 'Foto profile berhasil diubah', type: 'success' },
        { root: true }
      );
      commit('setUser', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
