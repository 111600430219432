<template>
  <!-- MENU -->
  <div>
    <!-- Show modal menu -->
    <Modal :open="isMenuShown" @close="onClose">
      <div
        class="fixed inset-0 z-10 overflow-y-auto text-gray-700"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        tabindex="1"
      >
        <div
          class="m-auto flex w-auto items-end justify-center px-4 pt-4 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="my-8 mx-auto inline-block w-full transform overflow-hidden rounded-lg bg-white px-6 py-10 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-2/3 sm:p-6 sm:align-middle md:w-1/2 lg:w-1/3 xl:w-1/4"
          >
            <div
              class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap"
            >
              <div class="ml-4 mt-2"></div>
              <div class="ml-4 mt-2 flex-shrink-0">
                <button
                  @click="onClose"
                  type="button"
                  class="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600"
                  id="menu-0-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <span class="sr-only">Close Modal</span>
                  <!-- Heroicon name: solid/dots-vertical -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div
              class="mx-auto block w-full space-y-4 rounded-lg bg-white py-6"
            >
              <div
                v-for="(menu, index) in getMenus.data.filter(
                  (it) => !it.attributes.name.includes('Admin')
                )"
                :key="index"
              >
                <div
                  v-if="menu.attributes.parent_id === null"
                  @click="setMenu(menu)"
                  class="cursor-pointer rounded-lg border p-4 hover:bg-green-100"
                >
                  <div class="flex items-center">
                    <img
                      :src="getPath(menu) + '.gif'"
                      :alt="'Gambar ' + menu.attributes.name"
                      class="h-10 w-10 object-cover"
                    />
                    <span class="ml-4">{{ menu.attributes.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/NewModal.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
    };
  },
  props: {
    showMenu: {
      required: true,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      getMenus: 'menus/getMenus',
    }),
    isMenuShown: {
      get() {
        return this.showMenu;
      },
      set() {
        this.$emit('toggle', this.showMenu);
        return this.showMenu;
      },
    },
  },
  methods: {
    ...mapMutations({
      setAuthMenuId: 'auth/setMenuId',
      setAuthMenu: 'auth/setMenu',
    }),
    getPath(menu) {
      let filePath = `${this.baseUrl}${menu.attributes.icon}`;
      return filePath.lastIndexOf('.svg') !== -1
        ? filePath.substring(0, filePath.lastIndexOf('.svg'))
        : `${filePath}`;
    },
    onClickOutside(params) {
      console.log('heree', this.isMenuShown, params);
      this.isMenuShown = !this.isMenuShown;
    },
    setMenu(menu) {
      // this.showTree = false;
      const menuName = menu.attributes.name;
      const menuId = menu.id;

      this.setAuthMenu(menu);
      this.setAuthMenuId(menuId);

      if (menuName === 'Superadmin') {
        this.$router.push('/superadmin');
      } else if (menuName === 'Admin') {
        this.$router.push('/admin');
      } else if (menuName === 'Gudang') {
        this.$router.push('/gudang');
      } else if (menuName === 'Kasir') {
        this.$router.push('/kasir');
      } else if (menuName === 'Penjualan') {
        this.$router.push('/penjualan');
      } else if (menuName === 'Cash Bill') {
        this.$router.push('/cashbill');
      } else if (menuName === 'Bonus') {
        this.$router.push('/bonus');
      }

      this.onClose();
      // setTimeout(() => (this.showTree = true), 0);
    },
    onOpen() {
      this.$emit('onOpen');
    },
    onClose() {
      this.$emit('onClose');
    },
  },
};
</script>
