import ApiService from '@/services/api.service';
const Order = {
  data: {
    attributes: {
      origin_code: null,
      cancel_warehouse_description: null,
    },
    relationships: {
      'destination-office': {
        data: {
          id: '',
        },
      },
    },
  },
  included: [],
};

const state = {
  isLoading: false,
  error: null,
  orders: {
    data: [],
    meta: {
      page: {},
    },
  },
  payments: {
    data: [],
    meta: {
      page: {},
    },
  },
  previousOrder: {
    data: {
      attributes: {
        origin_code: null,
      },
      relationships: {
        'destination-office': {
          data: {
            id: '',
          },
        },
      },
    },
    included: [],
  },
  errorPreviousOrder: null,
  isLoadingPreviousOrder: false,
  isLoadingDailyReport: false,
  isLoadingExportDbf: false,
  order: {
    data: {
      attributes: {},
      relationships: {
        product: {
          data: {
            id: null,
          },
        },
        'origin-office': {
          data: {
            id: null,
          },
        },
        'destination-office': {
          data: {
            id: null,
          },
        },
        'origin-warehouse': {
          data: {
            id: null,
          },
        },
        'destination-warehouse': {
          data: {
            id: null,
          },
        },
      },
    },
    included: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getOrders: (state) => {
    return state.orders;
  },
  getPayments: (state) => {
    return state.payments;
  },
  getOrder: (state) => {
    return state.order;
  },
  getError: (state) => {
    return state.error;
  },
  getPreviousOrder: (state) => {
    return state.previousOrder;
  },
  getErrorPreviousOrder: (state) => {
    return state.errorPreviousOrder;
  },
  getLoadingPreviousOrder: (state) => {
    return state.isLoadingPreviousOrder;
  },
  getLoadingDailyReport: (state) => {
    return state.isLoadingDailyReport;
  },
  getLoadingExportDbf: (state) => {
    return state.isLoadingExportDbf;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setOrders: (state, data) => (state.orders = data),
  setOrder: (state, data) => (state.order = data),
  setError: (state, data) => (state.error = data),
  setPayments: (state, data) => (state.payments = data),
  setPreviousOrder: (state, data) => (state.previousOrder = data),
  setErrorPreviousOrder: (state, data) => (state.errorPreviousOrder = data),
  setLoaderPreviousOrder: (state, status) =>
    (state.isLoadingPreviousOrder = status),
  setLoaderDailyReport: (state, status) =>
    (state.isLoadingDailyReport = status),
  setLoaderExportDbf: (state, status) => (state.isLoadingExportDbf = status),
};

const actions = {
  async fetchOrders(
    { commit, dispatch, rootGetters },
    {
      origin_office_category,
      destination_office_category,
      fields,
      page,
      ...others
    }
  ) {
    commit('setLoader', true);
    const office_categories =
      rootGetters['office_categories/getAvailableOfficeCategories'];
    const params = { ...others };

    if (destination_office_category) {
      let destination_office_category_id = office_categories.filter(function (
        el
      ) {
        return el.attributes.name === destination_office_category;
      });
      params['filter[destination_office_category_id]'] =
        destination_office_category_id[0].id;
    }

    if (origin_office_category) {
      let origin_office_category_id = office_categories.filter(
        (it) => it.attributes.name === origin_office_category
      )[0].id;
      params['filter[origin_office_category_id]'] = origin_office_category_id;
    }

    if (page) {
      Object.keys(page).map((item) => {
        const key = `page[${item}]`;
        Object.assign(params, { [key]: page[item] });
      });
    }
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    try {
      let response = await ApiService.get('api/v1/orders', {
        params,
      });
      commit('setOrders', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setOrders', {
        data: [],
        meta: { page: {} },
      });
    }
    commit('setLoader', false);
  },
  async fetchOrder({ commit, dispatch }, { id, fields, include, ...others }) {
    commit('setLoader', true);
    const params = { ...others };

    Object.assign(params, {
      include:
        include ??
        'area,buyer-type,destination-office,order-details,origin-office,stock-movements,origin-warehouse,destination-warehouse,payments',
    });

    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    try {
      let response = await ApiService.get('api/v1/orders/' + id, {
        params,
      });
      commit('setOrder', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setOrders', {
        data: [],
        meta: { page: {} },
      });
    }
    commit('setLoader', false);
  },
  async createOrder({ commit, dispatch }, payload) {
    const params = {};
    params.include =
      'destination-office,origin-office,order-details,origin-warehouse,destination-warehouse,payments';
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/orders', payload, {
        params,
      });
      commit('setOrder', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors[0].detail);
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors);
      }
    }
    commit('setLoader', false);
  },
  async createOrderWithParams({ commit, dispatch }, { payload, params }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/orders', payload, {
        params: {
          include:
            params.include ??
            'destination-office,origin-office,order-details,origin-warehouse,destination-warehouse,payments',
          ...params,
        },
      });
      commit('setOrder', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors[0].detail);
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors);
      }
    }
    commit('setLoader', false);
  },
  async duplicateOrder({ commit }, { id }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        `api/v1/orders/${id}/-actions/duplicate`
      );
      commit('setOrder', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOrder', {});
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
  async accAtasan({ commit }, { id, reason }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        `api/v1/orders/${id}/-actions/acc-payment`,
        {
          acc_payment_reason: reason,
        }
      );
      commit('setOrder', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors[0]?.detail);
    }
    commit('setLoader', false);
  },
  async updateOrder({ commit, dispatch }, payload) {
    commit('setLoader', true);
    const params = {
      include:
        'destination-office,origin-office,order-details,origin-warehouse,destination-warehouse,payments',
    };

    try {
      let response = await ApiService.patch(
        'api/v1/orders/' + payload.data.id,
        payload,
        { params }
      );
      commit('setOrder', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors[0].detail);
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors);
      }

      commit('setOrder', Order);
    }
    commit('setLoader', false);
  },
  async deleteOrder({ commit, dispatch }, id) {
    commit('setLoader', true);

    try {
      let response = await ApiService.patch('api/v1/orders/' + id, {
        data: {
          type: 'orders',
          id: id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      });
      commit('setError', null);
      commit('setLoader', false);

      dispatch(
        'alert/createAlert',
        { data: 'Faktur penjualan berhasil dihapus', type: 'success' },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setOrder', {});
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
  async cancelOrder({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/orders/' + payload.data.id + '/-actions/cancelPayment',
        payload
      );
      commit('setOrder', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setOrder', {});
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
  async cancelOrderFromWarehouse({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/orders/' + payload.data.id + '/-actions/cancelStockMovement',
        payload
      );
      commit('setOrder', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setOrder', {});
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
  async fetchOrderPayments({ commit }, { id, include, fields }) {
    commit('setLoader', true);

    const params = {
      include,
    };

    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    try {
      const res = await ApiService.get(`api/v1/orders/${id}/payments`, {
        params,
      });

      commit('setPayments', res.data);
      commit('setError', false);

      return res;
    } catch (err) {
      commit('setPayments', {
        data: [],
        meta: {
          page: {},
        },
      });

      commit('setError', err);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchPrevOrder({ commit }, { id, include, fields }) {
    commit('setLoaderPreviousOrder', true);

    const params = {
      include,
      'filter[with-trashed]': true,
    };

    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    try {
      const res = await ApiService.get(`api/v1/orders/${id}/previous-order`, {
        params,
      });

      commit('setPreviousOrder', res.data);
      commit('setErrorPreviousOrder', false);

      return res;
    } catch (err) {
      commit('setPreviousOrder', {
        data: [],
        meta: {
          page: {},
        },
      });

      commit('setErrorPreviousOrder', err);
    } finally {
      commit('setLoaderPreviousOrder', false);
    }
  },
  async dailyReport(
    { commit },
    {
      action,
      fromDate,
      toDate,
      origin_warehouse_id,
      destination_warehouse_id,
      stockistType,
    }
  ) {
    commit('setLoaderDailyReport', true);

    try {
      const res = await ApiService.get(`api/v1/orders/-actions/dailyReport`, {
        params: {
          action,
          ['filter[from_date]']: fromDate,
          ['filter[to_date]']: toDate,
          ['filter[origin_warehouse_id]']: origin_warehouse_id,
          ['filter[destination_warehouse_id]']: destination_warehouse_id,
          ['filter[stockist_type]']: stockistType,
        },
        responseType: 'blob',
      });

      return res;
    } catch (err) {
      //
    } finally {
      commit('setLoaderDailyReport', false);
    }
  },
  async onBeforeValidation({ dispatch }, { orderId }) {
    try {
      const response = await ApiService.post(
        `api/v1/orders/${orderId}/-actions/onBeforeValidation`
      );
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    }
  },
  async exportDbf(
    { commit, rootGetters },
    { bonus_month, created_month, from_date, to_date, year }
  ) {
    commit('setLoaderExportDbf', true);

    try {
      const office_categories =
        rootGetters['office_categories/getAvailableOfficeCategories'];
      const stockist_office_category = office_categories.find(function (el) {
        return el.attributes.name === 'Stockist';
      });

      const res = await ApiService.get(`api/v1/orders`, {
        params: {
          exportDbf: true,
          ['filter[bonus_month]']: bonus_month,
          ['filter[created_month]']: created_month,
          ['filter[from_date]']: from_date,
          ['filter[to_date]']: to_date,
          ['filter[createdYear]']: year,
          'filter[origin_office_category_id]': stockist_office_category.id,
        },
        responseType: 'blob',
      });

      return res;
    } catch (err) {
      //
    } finally {
      commit('setLoaderExportDbf', false);
    }
  },
  async validate({ commit, dispatch }, { id, data }) {
    commit('setLoader', true);

    try {
      const res = await ApiService.patch(
        `api/v1/orders/${id}/-actions/validateOrder`, data
      );

      return res;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
