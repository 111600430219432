const LayoutDefault = () =>
  import(/* webpackChunkName: "kp-admin" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "kp-admin" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "kp-admin" */ '@/views/pelayanan/admin/dashboard/Index.vue'
  );

const Barang = () =>
  import(
    /* webpackChunkName: "kp-admin" */ '@/views/pelayanan/admin/master/barang/Index.vue'
  );
const Stockist = () =>
  import(
    /* webpackChunkName: "kp-admin" */ '@/views/pelayanan/admin/master/stockist/Index.vue'
  );
const StockistCenter = () =>
  import(
    /* webpackChunkName: "kp-admin" */ '@/views/pelayanan/admin/master/stockist-center/Index.vue'
  );
const Ekspedisi = () =>
  import(
    /* webpackChunkName: "kp-admin" */ '@/views/pelayanan/admin/master/ekspedisi/Index.vue'
  );
// const Setup = () =>
//   import(
//     /* webpackChunkName: "kp-admin" */ '@/views/pelayanan/admin/setup/Index.vue'
//   );

let root = '/admin';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/master/',
    component: LayoutData,
    children: [
      // Route Master Barang
      {
        path: 'barang/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Barang',
        },
        children: [
          {
            path: '',
            component: Barang,
            meta: {
              auth: true,
              title: 'Master Barang',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master Stockist
      {
        path: 'stockist/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Stockist',
        },
        children: [
          {
            path: '',
            component: Stockist,
            meta: {
              auth: true,
              title: 'Stockist',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master Stockist Center
      {
        path: 'stockist-center/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Stockist Center',
        },
        children: [
          {
            path: '',
            component: StockistCenter,
            meta: {
              auth: true,
              title: 'Stockist Center',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master Ekspedisi
      {
        path: 'ekspedisi/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Ekspedisi',
        },
        children: [
          {
            path: '',
            component: Ekspedisi,
            name: 'expedition.index',
            meta: {
              auth: true,
              title: 'Ekspedisi',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
];
