const LayoutDefault = () =>
  import(
    /* webpackChunkName: "stock-stockist" */ '@/layouts/LayoutDefault.vue'
  );
const LayoutData = () =>
  import(/* webpackChunkName: "stock-stockist" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "stock-stockist" */ '@/views/pusat/stock-stockist/dashboard/Index.vue'
  );
const DataStockStockist = () =>
  import(
    /* webpackChunkName: "stock-stockist" */ '@/views/pusat/stock-stockist/data-stock-stockist.vue'
  );

let root = '/stock-stockist';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/data-stock-stockist/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: DataStockStockist,
        meta: {
          auth: true,
          title: 'Data Stock Stockist',
          layout: LayoutDefault,
          breadcrumb: 'Data Stock Stockist',
        },
      },
    ],
  },
];
