import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  kantorPelayanans: {
    data: [],
    meta: {
      page: {},
    },
  },
  kantorPelayanan: {},
  officeCategory: {
    data: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getKantorPelayanan: (state) => {
    return state.kantorPelayanan;
  },
  getKantorPelayanans: (state) => {
    return state.kantorPelayanans;
  },
  getOfficeCategory: (state) => {
    return state.officeCategory;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setKantorPelayanans: (state, data) => (state.kantorPelayanans = data),
  setKantorPelayanan: (state, data) => (state.kantorPelayanan = data),
  setOfficeCategory: (state, data) => (state.officeCategory = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchKantorPelayanan(
    { commit, rootGetters },
    { keyword, pageNumber, pageSize, search }
  ) {
    commit('setLoader', true);
    let stockist_office_category_id = rootGetters[
      'office_categories/getAvailableOfficeCategories'
    ].filter(function (el) {
      return el.attributes.name === 'Kantor Pelayanan';
    });
    const params = {};
    let filter = [];
    let page = [];
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, { include: 'addresses,area,warehouses' });
    Object.assign(params, { sort: '-id' });
    try {
      let response = await ApiService.get(
        'api/v1/office-categories/' +
          stockist_office_category_id[0].id +
          '/offices',
        {
          params,
        }
      );
      commit('setKantorPelayanans', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setKantorPelayanan', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },

  async createKantorPelayanan({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/offices', payload);
      commit('setKantorPelayanan', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setKantorPelayanan', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
