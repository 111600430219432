import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  stockists: {
    data: [],
    meta: {
      page: {},
    },
  },
  stockist: {},
  officeCategory: {
    data: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getStockists: (state) => {
    return state.stockists;
  },
  getStockist: (state) => {
    return state.stockist;
  },
  getOfficeCategory: (state) => {
    return state.officeCategory;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setStockists: (state, data) => (state.stockists = data),
  setStockist: (state, data) => (state.stockist = data),
  setOfficeCategory: (state, data) => (state.officeCategory = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async createStockist({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/offices', payload);
      commit('setStockist', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStockists', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async updateStockist({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/offices/' + payload.data.id + '?include=addresses',
        payload
      );
      commit('setStockist', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStockists', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchStockists(
    { commit, rootGetters },
    {
      keyword,
      pageNumber,
      pageSize,
      search,
      include,
      withoutSort,
      ...others
    } = {}
  ) {
    commit('setLoader', true);
    const office_categories =
      rootGetters['office_categories/getAvailableOfficeCategories'];
    let stockist_office_category_id = office_categories.filter(function (el) {
      return el.attributes.name === 'Stockist';
    });
    const params = { ...others };
    let filter = [];
    let page = [];
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    if (include || include == '') {
      Object.assign(params, { include });
    } else {
      Object.assign(params, {
        include: 'users,addresses,area',
      });
    }
    if (!withoutSort) {
      Object.assign(params, { sort: '-id' });
    }
    try {
      let response = await ApiService.get(
        'api/v1/office-categories/' +
          stockist_office_category_id[0].id +
          '/offices',
        {
          params,
        }
      );
      commit('setStockists', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStockists', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchOfficeCategory({ commit }) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    filter['name'] = 'stockist';
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    try {
      let response = await ApiService.get('api/v1/office-categories', {
        params,
      });
      commit('setOfficeCategory', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOfficeCategory', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
