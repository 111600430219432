import Vue from 'vue';
import Loading from '@/components/Loading.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ClickOutside from 'vue-click-outside';
import { toCurrency } from './services/currency.service';
import VuePusher from 'vue-pusher';
import ApiService from '@/services/api.service';
import { Icon } from '@iconify/vue2';
import { keydownMixin } from '@/mixins/event/keydown.mixin';
import BaseCard from '@/components/base/BaseCard.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseBadge from '@/components/base/BaseBadge';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import Datatable from '@/components/base/Datatable';
import BaseWrapper from '@/components/base/BaseWrapper';

Vue.config.productionTip = false;

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_API_URL);

import dayjs from 'dayjs';
import 'dayjs/locale/id';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.locale('id');
dayjs.extend(localizedFormat);
Vue.directive('click-outside', ClickOutside);
Vue.use(dayjs);
// register component
Vue.component('Loading', Loading);
Vue.component('BaseCard', BaseCard);
Vue.component('BaseButton', BaseButton);
Vue.component('BaseBadge', BaseBadge);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseSelect', BaseSelect);
Vue.component('Datatable', Datatable);
Vue.component('BaseWrapper', BaseWrapper);
Vue.component('Icon', Icon);
// Vue.use(LottieAnimation)
Vue.filter('toCurrency', toCurrency);
Vue.filter('toFixed', function (value, digits = 2) {
  if (typeof value !== 'number') {
    return value;
  }

  return value.toFixed(digits);
});
Vue.filter('truncate', (value, limit = 30) => {
  return value.slice(0, limit - 3) + (value.length > limit ? '...' : '')
})
Vue.filter('formatDate', function (value, format = 'll LT') {
  if (!value) {
    return '-';
  }
  return dayjs(value).format(format || 'll LT');
});
Vue.filter('optional', function (value, str = '-') {
  return value ?? str;
});
Vue.mixin(keydownMixin);
Vue.mixin({
  methods: {
    isNull(val) {
      return val === null;
    },
    getSingleIncluded(data, id) {
      return data.included.find((included) => included.id === id);
    },
    getIncludedByType(data, type) {
      if (!data.included) {
        return []
      }
      
      return data.included.filter((included) => included.type === type);
    },
    getSingleIncludedType(type, data, document) {
      if (!document.relationships) {
        return null;
      }

      const id = document.relationships[`${type}`]?.data?.id;

      if (!id) {
        return null;
      }

      return this.getSingleIncluded(data, id);
    },
  },
});

// usage: <button v-back>Go back</button>
Vue.directive('router-back', {
  bind(el) {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      router.go(-1);
    });
  },
});
Vue.directive('currency', {
  bind(el) {
    el.addEventListener('input', (e) => {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      e.target.value = Intl.NumberFormat('id-ID', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    });
  },
});

Vue.use(VuePusher, {
  api_key: process.env.VUE_APP_PUSHER_APP_KEY,
  options: {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    authEndpoint: `${process.env.VUE_APP_API_URL}/api/broadcasting/auth`,
    authorizer: (channel) => {
      return {
        authorize: (socketId, callback) => {
          ApiService.post('/api/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
          })
            .then((response) => {
              callback(false, response.data);
            })
            .catch((error) => {
              callback(true, error);
            });
        },
      };
    },
  },
});

import '@/assets/css/main.css';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
