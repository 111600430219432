import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  stockTrxes: {
    data: [],
    meta: {
      page: {},
    },
  },
  stockTrx: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getStockTrxes: (state) => {
    return state.stockTrxes;
  },
  getStockTrx: (state) => {
    return state.stockTrx;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setStockTrxes: (state, data) => (state.stockTrxes = data),
  setStockTrx: (state, data) => (state.stockTrx = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchStockTrxes({ commit }, params) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get('api/v1/stock-trxes', params);
      commit('setStockTrxes', response.data);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setStockTrxes', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchStockTrxesByStock({ commit }, { stockId, params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get(
        `api/v1/stocks/${stockId}/stock-trxes`,
        params
      );

      commit('setStockTrxes', response.data);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setStockTrxes', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
