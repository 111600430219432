import ApiService from '@/services/api.service';
const expeditionDetail = {
  id: '',
  attributes: {},
};
const state = {
  isLoading: false,
  error: null,
  expeditionDetail,
  expeditionDetails: {
    data: [expeditionDetail],
    meta: {
      page: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getExpeditionDetails: (state) => {
    return state.expeditionDetails;
  },
  getExpeditionDetail: (state) => {
    return state.expeditionDetail;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setError: (state, status) => (state.error = status),
  setExpeditionDetails: (state, data) => (state.expeditionDetails = data),
  setExpeditionDetail: (state, data) => (state.expeditionDetail = data),
};

const actions = {
  async fetchExpeditionDetails({ commit }, { params, expedition_id } = {}) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(
        expedition_id
          ? `api/v1/expeditions/${expedition_id}/expedition-details`
          : 'api/v1/expedition-details',
        {
          params,
        }
      );
      commit('setExpeditionDetails', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setExpeditionDetails', { data: [], meta: { page: {} } });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async createExpeditionDetail({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        'api/v1/expedition-details',
        payload
      );
      commit('setExpeditionDetail', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setExpeditionDetail', expeditionDetail);
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async updateExpeditionDetail({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/expedition-details/' + payload.data.id,
        payload
      );
      commit('setExpeditionDetail', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
  async fetchExpeditionDetailsByExpedition({ commit }, { id, params } = {}) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(
        `api/v1/expeditions/${id}/expedition-details`,
        {
          params,
        }
      );
      commit('setExpeditionDetails', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setExpeditionDetails', { data: [], meta: { page: {} } });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
