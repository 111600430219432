import ApiService from '@/services/api.service';

export const education = {
  type: '',
  id: '',
  attributes: {
    name: '',
    description: '',
    deletedAt: null,
    createdAt: '',
    updatedAt: '',
  },
  relationships: {
    offices: {
      links: {
        related: '',
        self: '',
      },
    },
  },
  links: {
    self: '',
  },
};

const state = {
  isLoading: false,
  error: null,
  education: education,
  educations: {
    data: [education],
    included: [],
    meta: {
      page: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getEducation: (state) => {
    return state.education;
  },
  getEducations: (state) => {
    return state.educations;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setEducations: (state, data) => (state.educations = data),
  setEducation: (state, data) => (state.education = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchEducations({ commit }, { params = {}, fields } = {}) {
    commit('setLoader', true);
    try {
      if (fields) {
        Object.entries(fields).forEach(([prop, value]) => {
          Object.assign(params, {
            [`fields[${prop}]`]: value,
          });
        });
      }

      let response = await ApiService.get(`api/v1/educations`, { params });
      commit('setEducations', response.data);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async createEducation({ commit, dispatch }, { payload }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(`api/v1/educations`, payload);
      commit('setEducation', response.data);
      dispatch(
        'alert/createAlert',
        { data: 'Data pendidikan berhasil ditambahkan' },
        { root: true }
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        commit('setError', error.response.data.errors[0].detail);
      } else {
        commit('setError', 'Gagal');
      }
    } finally {
      commit('setLoader', false);
    }
  },
  async updateEducation({ commit, dispatch }, { payload }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        `api/v1/educations/${payload.data.id}`,
        payload
      );
      commit('setEducation', response.data);
      dispatch(
        'alert/createAlert',
        {
          data: `Data pendidikan berhasil di${
            payload.data.attributes.deletedAt ? 'hapus' : 'ubah'
          }`,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        commit('setError', error.response.data.errors[0].detail);
      } else {
        commit('setError', 'Gagal');
      }
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
