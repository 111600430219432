<template>
  <header
    v-if="user.current_period && userOfficeCategory?.attributes?.name"
    style="justify-content: space-between"
    class="no-print flex w-full items-center bg-white p-1 text-gray-700 md:px-6"
  >
    <div class="block px-4 text-xs">
      <div class="flex gap-2">
        <div class="">{{ user.name }}</div>
      </div>
    </div>
    <div class="text-xs">
      <template
        v-if="
          userOfficeCategory.attributes.name.toLowerCase() !=
          user.office_code.toLowerCase()
        "
      >
        {{ userOfficeCategory.attributes.name }}
      </template>
      {{ user.office_code }} (Wilayah {{ getWilayah }})
    </div>
    <div>
      <div
        class="block text-xs font-medium text-gray-500 dark:text-gray-400 sm:text-center"
      >
        Periode {{ user.current_period.name }}
      </div>
      <div
        style="font-size: 0.5rem; line-height: 0.75rem"
        class="flex justify-between text-right text-gray-500 dark:text-gray-400"
      >
        <p v-if="userOfficeCategory.attributes.name == 'Pusat'">
          Pusat pukul
          {{ dayjs(user.current_period.end_date).format('ll LT') }}
        </p>
        <p
          v-else-if="
            userOfficeCategory.attributes.name == 'Kantor Pelayanan' ||
            userOfficeCategory.attributes.name == 'Stockist Center'
          "
        >
          SC/KP pukul
          {{ dayjs(user.current_period.end_date).format('ll LT') }}
        </p>
        <template v-else>
          <div>
            {{ dayjs(user.current_period.start_date).format('ll LT') }}
          </div>
          <div>
            {{ dayjs(user.current_period.end_date).format('ll LT') }}
          </div>
        </template>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'Banner',
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      userOfficeCategory: 'auth/getUserOfficeCategory',
    }),
    getWilayah() {
      const list = ['Jawa', 'I', 'II', 'III', 'IV', 'loading...'];
      return list[this.user.office_area_code ?? 5];
    },
  },
  methods: {
    dayjs,
  },
};
</script>
