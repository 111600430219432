import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  purchasesorders: {
    data: [],
    meta: {
      page: {},
    },
  },
  order: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getPurchaseOrders: (state) => {
    return state.purchasesorders;
  },
  getPurchaseOrder: (state) => {
    return state.purchaseorder;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setPurchaseOrders: (state, data) => (state.purchasesorders = data),
  setPurchaseOrder: (state, data) => (state.order = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchPurchaseOrders(
    { commit },
    { keyword, pageNumber, pageSize, search, is_completed }
  ) {
    commit('setLoader', true);
    const params = {};
    let page = [];
    let filter = [];
    filter['order_type'] = 'purchase';
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (search && search !== '') filter['search'] = search;
    if (is_completed !== undefined) filter['is_completed'] = is_completed;

    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, {
      include: 'area,buyer-type,destination-office,order-details,origin-office',
    });
    Object.assign(params, { sort: '-createdAt' });
    try {
      let response = await ApiService.get('api/v1/orders', {
        params,
      });
      commit('setPurchaseOrders', response.data);
    } catch (error) {
      commit('setPurchaseOrders', {
        data: [],
        meta: { page: {} },
      });
    }
    commit('setLoader', false);
  },
  async createPurchaseOrder({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/purchase-orders', payload);
      commit('setPurchaseOrder', response.data);
      return response;
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
