import ApiService from '@/services/api.service';

const getDefaultState = () => {
  return {
    loading: {},
    offices: {},
    orders: {},
    banks: {},
    areas: {},
    cities: {},
    products: {},
    addresses: {},
    'product-promos': {},
    'product-bundles': {},
    'order-bundles': {},
    'user-logs': {},
    'classify-stocks': {},
    error: {},
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getLoading: (state) => {
    return state.loading;
  },
  getAll: (state) => {
    return state;
  },
  getOne: (state) => {
    return state;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.loading = status),
  setResources: (state, { schema, ...data }) => (state[schema] = data),
  setResource: (state, { schema, ...data }) => (state[schema] = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchAll({ commit }, { schema, params }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(`api/v1/${schema}`, {
        params,
      });
      commit('setResources', { schema, ...response.data });
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setResources', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchOne({ commit, dispatch }, { schema, id, ...others }) {
    commit('setLoader', true);
    const normalizeSchema = schema?.slice(0, -1);

    try {
      let response = await ApiService.get(`api/v1/${schema}/` + id, {
        params: { ...others },
      });
      commit('setResource', { schema: normalizeSchema, ...response.data });
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setResource', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async createOne({ commit, dispatch }, { schema, params, payload }) {
    commit('setLoader', true);
    const normalizeSchema = schema.slice(0, -1);

    try {
      let response = await ApiService.post(`api/v1/${schema}`, payload, {
        params,
      });
      commit('setResource', { schema: normalizeSchema, ...response.data });
      commit('setError', null);
      commit('setLoader', false);
      dispatch(
        'alert/createAlert',
        { data: 'Berhasil Menambahkan Data', type: 'success' },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setResources', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async updateOne({ commit, dispatch }, { schema, id, params, payload }) {
    commit('setLoader', true);
    const normalizeSchema = schema.slice(0, -1);

    try {
      let response = await ApiService.patch(`api/v1/${schema}/${id}`, payload, {
        params,
      });
      commit('setResource', { schema: normalizeSchema, ...response.data });
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setResources', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async deleteOne({ commit, dispatch }, { schema, id, params, payload }) {
    commit('setLoader', true);
    const normalizeSchema = schema.slice(0, -1);

    try {
      let response = await ApiService.delete(
        `api/v1/${schema}/${id}`,
        payload,
        {
          params,
        }
      );
      commit('setResource', { schema: normalizeSchema, ...response.data });
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setResources', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchAllRelated({ commit }, { schema, id, related, params }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(`api/v1/${schema}/${id}/${related}`, {
        params,
      });
      commit('setResources', { schema: related, ...response.data });
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setResources', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async updateOneRelated(
    { commit, dispatch },
    { schema, related, id, params, payload }
  ) {
    commit('setLoader', true);
    const normalizeSchema = related.slice(0, -1);

    try {
      let response = await ApiService.patch(
        `api/v1/${schema}/${id}/${related}`,
        payload,
        {
          params,
        }
      );
      commit('setResource', { schema: normalizeSchema, ...response.data });
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setResources', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  clearState() {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
