const ACCESS_TOKEN = 'access_token';
const ACCESS_REFRESH_TOKEN = 'refresh_token';
const ME = 'me';

import store from '@/store';

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const StorageService = {
  getToken() {
    return store.getters['auth/getAccessToken'];
  },

  saveToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  },

  getUser() {
    return store.getters['auth/getUser'];
  },

  saveUser(user) {
    localStorage.setItem(ME, JSON.stringify(user));
  },

  removeToken() {
    localStorage.removeItem(ACCESS_TOKEN);
    store.commit('auth/setLoginSuccess', null);
  },

  removeUser() {
    localStorage.removeItem(ME);
  },

  getRole() {
    return store.getters['auth/getUser'].current_role;
  },

  getRefreshToken() {
    return localStorage.getItem(ACCESS_REFRESH_TOKEN);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(ACCESS_REFRESH_TOKEN, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(ACCESS_REFRESH_TOKEN);
  },

  saveDashboardStats(stats) {
    localStorage.setItem('dashboard_stats', JSON.stringify(stats));
  },
  getDashboardStats() {
    return store.getters['auth/getUser'].dashboard_stats;
  },

  setAuthStateFromLocalStorage() {
    store.commit('auth/setAccessTokenFromLocalStorage');
    store.commit('auth/setMenuFromLocalStorage');
  },
};

export { StorageService };
