import Gudang from './gudang.route';
import Kasir from './kasir.route';
import Penjualan from './penjualan.route';
import Admin from './admin.route';

let stockistcenter = [];

stockistcenter = stockistcenter.concat(Gudang, Kasir, Penjualan, Admin);

export default stockistcenter;
