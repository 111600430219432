export const validationMixin = {
  methods: {
    async validate(schema, body) {
      const { success, error, data } = await schema.safeParseAsync(body);

      return success
        ? [data, false]
        : [
            null,
            Object.fromEntries(
              error.issues.map((issue) => [issue.path[0], issue.message])
            ),
          ];
    },
  },
};
