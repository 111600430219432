<template>
  <span
    @click="$emit('click')"
    class="inline-flex items-center rounded-full font-medium"
    :class="style"
  >
    <slot>{{ label }}</slot>
  </span>
</template>

<script>
export default {
  name: 'BaseBadge',
  props: {
    label: String,
    color: {
      type: String,
      default: 'green',
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  computed: {
    style: function () {
      const colors = {
        green: 'bg-green-100  text-green-800',
        yellow: 'bg-yellow-100  text-yellow-800',
        red: 'bg-red-100  text-red-800',
        purple: 'bg-purple-100  text-purple-800',
        blue: 'bg-blue-100  text-blue-800',
        indigo: 'bg-indigo-100  text-indigo-800',
      };
      const sizes = {
        sm: 'px-2 py-1 text-xs',
        md: 'px-3 py-1 text-sm',
      };

      return [colors[this.color], sizes[this.size]];
    },
  },
};
</script>
