import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  stockGlobalReports: {
    data: [],
    meta: {
      page: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getStockGlobalReports: (state) => {
    return state.stockGlobalReports;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setStockGlobalReports: (state, data) => (state.stockGlobalReports = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchStockGlobalReports({ commit }, params) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get(
        'api/v1/stock-global-reports',
        params
      );

      commit('setStockGlobalReports', response.data);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setStockGlobalReports', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async exportStockGlobalReports({ dispatch }, { params } = {}) {
    try {
      const res = await ApiService.get('api/v1/stock-global-reports/-actions/export', {
        params,
      });
      
      return res.data
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: 'Gagal export excel', status: 'error' },
        { root: true }
      );
    }
  },
  async downloadStockGlobalReports({ dispatch }, { params } = {}) {
    try {
      const res = await ApiService.get('api/v1/stock-global-reports/-actions/download', {
        params,
        responseType: 'blob',
      });
      
      return res.data
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: 'Gagal download excel', status: 'error' },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
