import ApiService from '@/services/api.service';

const state = {
  data: null,
  paymentBundles: {},
};

const getters = {
  getStatus: (state) => {
    return state.status;
  },
  getData: (state) => state.data,
  getPaymentBundles: (state) => state.paymentBundles,
};

const mutations = {
  setData: (state, payload) => (state.data = payload),
  setType: (state, payload) => (state.status = payload),
  setPaymentBundles: (state, payload) => (state.paymentBundles = payload),
};

const actions = {
  async fetchPaymentBundles({ commit, dispatch }, params) {
    try {
      let response = await ApiService.get(`api/v1/payment-bundles`, { params });
      commit('setPaymentBundles', response.data);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setPaymentBundles', {});
    }
  },
  async fetchPaymentBundle({ commit, dispatch }, { id, params }) {
    try {
      let response = await ApiService.get(`api/v1/payment-bundles/${id}`, {
        params,
      });
      commit('setData', response.data);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setData', {});
    }
  },
  async createPaymentBundles({ commit, dispatch }, { payload }) {
    try {
      let response = await ApiService.post(`api/v1/payment-bundles`, payload);
      commit('setData', response.data);
      dispatch(
        'alert/createAlert',
        { data: 'Pembayaran Berhasil' },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setData', {});
    }
  },
  async updatePaymentBundles({ commit, dispatch }, { payload, id }) {
    try {
      let response = await ApiService.patch(
        `api/v1/payment-bundles/${id}`,
        payload
      );
      commit('setData', response.data);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setData', {});
    }
  },
  async verifyPaymentBundles({ commit, dispatch }, { id }) {
    try {
      let response = await ApiService.patch(
        `api/v1/payment-bundles/${id}/-actions/verify`
      );
      commit('setData', response.data);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setData', {});
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
