<template>
  <nav
    class="w-full border-gray-200 text-white dark:border-gray-600 dark:bg-gray-900"
    :class="[bgColor(office_type)]"
  >
    <div
      v-click-outside="onClickOutside"
      class="relative mx-auto flex items-center justify-between pr-2"
    >
      <div
        style="flex-shrink: 0"
        class="shadow-right flex flex-shrink-0 justify-center rounded-r-md border border-gray-200 bg-white px-6 py-2"
      >
        <router-link to="/" class="flex items-center space-x-3">
          <NasaLogo class="h-8" alt="Nasa Logo" />
          <div class="self-center whitespace-nowrap">
            <span class="text-2xl font-semibold text-nasa dark:text-white"
              >NASA</span
            >
            <p class="text-xs text-gray-400">
              {{ menu.attributes?.name ?? office_type }}
            </p>
          </div>
        </router-link>
      </div>
      <!-- responsive burger icon -->
      <button
        @click="showMobileDropdown = !showMobileDropdown"
        data-collapse-toggle="mega-menu-full"
        type="button"
        class="focus:outline-none inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-white focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden"
        aria-controls="mega-menu-full"
        aria-expanded="false"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="h-5 w-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
      <template v-if="windowWidth && windowWidth > 768">
        <TreeItem
          v-if="this.$route.fullPath != '/'"
          class="item"
          :items="childrenMenus"
          :textColor="textColor(office_type)"
          :borderColor="borderColor(office_type)"
          :activeColor="activeColor(office_type)"
          :defaultLink="defaultLink(office_type)"
          :subfolderActive="subfolderActive(office_type)"
          :selected-menu="selectedMenu"
        />
        <div style="flex-shrink: 0" class="flex items-center gap-x-1">
          <notification-pill @click="onClickOutside" />
          <menu-icon @show-modal="showMenuModal" />
          <profile @click-profile="onClickOutside" />
        </div>
      </template>
    </div>
    <div v-if="childrenMenus.length">
      <item-dropdown v-model="showDropdown" :menus="childrenMenus" />
    </div>
    <modal-menu
      :show-menu="showMenu"
      @show="showMenuModal"
      @onClose="onCloseMenu"
    />
    <Banner />
    <mobile-navbar
      v-if="showMobileDropdown && windowWidth < 768"
      @click="onClickItem"
      :menus="currentMenus"
      :active-menu="selectedMenu"
      :childs="childrenMenus"
      :is-child-visible="showDropdown"
    />
  </nav>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import TreeItem from './TreeItem';
import { StorageService } from '@/services/storage.service';
// import listItem from './list-item.vue';
import itemDropdown from './item-dropdown.vue';
import profile from './profile.vue';
import menuIcon from './menu-icon.vue';
import NasaLogo from '@/assets/images/logo.svg';
import ModalMenu from '@/components/base/ModalMenu.vue';
import dayjs from 'dayjs';

import {
  defaultLink,
  subfolderActive,
  activeColor,
  bgColor,
  textColor,
  borderColor,
} from '@/services/themes.service';
import NotificationPill from './NotificationPill.vue';
import MobileNavbar from './mobile-navbar.vue';
import Banner from './banner.vue';

export default {
  name: 'Navbar',
  props: {
    menus: {
      type: Array,
    },
  },
  components: {
    menuIcon,
    // listItem,
    itemDropdown,
    profile,
    // TreeItem,
    NasaLogo,
    ModalMenu,
    // NavbarSearch,
    NotificationPill,
    MobileNavbar,
    TreeItem,
    Banner,
  },
  data() {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    const selectedMenu = JSON.parse(localStorage.getItem('menu'));
    return {
      preferences,
      showSidebar: false,
      showProfile: false,
      windowWidth: window.innerWidth,
      showMobileDropdown: false,
      showDropdown: false,
      me: StorageService.getUser(),
      showRole: false,
      showMenu: false,
      showTree: true,
      office_name: '',
      office_type: '',
      office_code: '',
      menu_title: '',
      baseUrl: process.env.VUE_APP_API_URL,
      activePeriod: null,
      selectedMenu: selectedMenu,
    };
  },
  created() {
    const handleEscape = () => {
      this.showProfile = false;
      this.showSidebar = false;
      this.showRole = false;
      this.showMenu = false;
    };

    this.office_type = this.userOfficeCategory.attributes.name;
    this.setEscape(handleEscape);

    if (this.$route.fullPath == '/') {
      this.authSetMenu(this.user.current_role);
    }
  },
  computed: {
    ...mapGetters({
      getMenus: 'menus/getMenus',
      getPeriod: 'periods/getPeriod',
      getRolesByUser: 'users/getRolesByUser',
      getOfficeByOfficesCategories:
        'office_categories/getOfficeByOfficesCategories',
      user: 'auth/getUser',
      getUser: 'users/getUser',
      userOfficeCategory: 'auth/getUserOfficeCategory',
      menu: 'auth/getMenu',
      getCurrentPeriod: 'mitra_periods/getCurrentPeriod',
    }),
    childrenMenus() {
      if (!this.selectedMenu?.id) {
        return [];
      }

      return this.getMenus.data.filter(
        (it) => it.attributes.parent_id == this.selectedMenu?.id
      );
    },
    currentMenus() {
      const parentMenu = JSON.parse(localStorage.getItem('menu'));
      if (this.getMenus.data.length < 1) {
        return [];
      }
      return this.getMenus.data.filter(
        (menu) => menu.attributes.parent_id == parentMenu.id
      );
    },
    getWilayah() {
      const list = ['Jawa', 'I', 'II', 'III', 'IV', 'loading...'];
      return list[this.user.office_area_code ?? 5];
    },
  },
  methods: {
    dayjs,
    defaultLink,
    subfolderActive,
    activeColor,
    bgColor,
    textColor,
    borderColor,
    ...mapActions({
      updateUser: 'users/updateUser',
      fetchMenus: 'menus/fetchMenus',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchCurrentPeriod: 'periods/fetchCurrentPeriod',
      fetchCurrentMitraPeriod: 'mitra_periods/fetchCurrentMitraPeriod',
      fetchOfficesByOfficeCategory:
        'office_categories/fetchOfficesByOfficeCategory',
    }),
    ...mapMutations({
      setMenuId: 'auth/setMenuId',
      authSetMenu: 'auth/setMenu',
    }),
    onClickOutside() {
      this.showDropdown = false;
    },
    onCloseDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    onClickItem(value) {
      if (this.selectedMenu.id === value.id) {
        this.toggleDropdown();
      } else {
        this.showDropdown = true;
        this.selectedMenu = value;
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      if (!this.showDropdown) {
        this.selectedMenu = {};
      }
    },
    showMenuModal({ toggleProfile } = { toggleProfile: true }) {
      this.showMenu = !this.showMenu;
      if (toggleProfile) {
        this.showProfile = !this.showProfile;
      }
    },
    setPeriod() {
      const usingMitraPeriod = ['Mitra Usaha', 'Stockist'];
      if (usingMitraPeriod.includes(this.user.current_role)) {
        this.fetchCurrentMitraPeriod({
          params: {
            'fields[mitra-periods]': 'name,end_date,start_date',
          },
        }).then((response) => {
          this.activePeriod = response.data.data;
        });
      } else {
        if (!this.getCurrentPeriod.data.attributes) {
          this.fetchCurrentPeriod().then(() => {
            this.setActivePeriod();
          });
        } else {
          this.setActivePeriod();
        }
      }
    },
    setActivePeriod() {
      this.activePeriod = this.getPeriod.data;
    },
    redirectHome() {
      this.authSetMenu(this.user.current_role);
      this.$router.push('/');
    },
    onCloseMenu() {
      this.showMenu = false;
    },
    sidebarAct: function () {
      this.showSidebar = !this.showSidebar;
      this.$emit('event-child', this.action);
    },

    setRole(role) {
      const payload = {
        data: {
          type: 'users',
          id: StorageService.getUser().uuid,
          attributes: {
            current_role: role.attributes.name,
          },
        },
      };
      this.updateUser(payload).then((response) => {
        if (response) {
          this.showRole = !this.showRole;
          if (this.$route.name === 'Home') this.$router.go();
          else this.$router.push('/');
        }
      });
    },
    recursiveSearch(id, data) {
      return data.reduce((a, item) => {
        if (a) return a;
        if (item.id === id) return item;
        if (item.children) return this.recursiveSearch(id, item.children);
      }, null);
    },

    // nanti bakal dimasukin ke treeitem
    extractMenus() {},
    getUrl(url) {
      if (url === null) {
        return null;
      } else {
        if (url[0] !== '/' && url) {
          return '/' + url;
        } else {
          return url;
        }
      }
    },
    makeFolder: function (item) {
      this.$set(item, 'children', []);
    },
    addItem: function (parent, children) {
      parent?.children?.push(children);
    },
    onResizeWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResizeWidth);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeWidth);
  },
};
</script>

<style>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
