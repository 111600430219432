<template>
  <div class="my-2">
    <router-link
      :is="menu.attributes.type == 'group' ? 'span' : 'router-link'"
      :disabled="menu.attributes.type == 'group'"
      :to="getUrl(menu.attributes.url)"
      @click="setMenu(menu)"
      :class="isActive ? 'bg-gray-100 text-green-600' : null"
      exact-active-class="text-green-600 bg-gray-100"
      class="group flex items-center rounded-lg text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
    >
      <div v-if="expanding" class="w-full cursor-pointer p-2">
        <span
          :style="`padding-left: ${depth}rem`"
          class="flex w-full items-center justify-between"
        >
          <span>
            {{ menu.attributes.name }}
          </span>
          <svg
            v-if="!menu.attributes.parent_id || menu.attributes.type == 'group'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-4 w-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </span>
      </div>
      <span class="tooltip relative mx-auto cursor-pointer p-2" v-else>
        <img
          :src="`${baseUrl}${menu.attributes.icon}`"
          class="h-4 w-4"
          :alt="`Gambar ${menu.attributes.name}`"
        />
        <p style="left: 100px; top: 0" class="tooltiptext absolute">
          {{ menu.attributes.name }}
        </p>
      </span>
    </router-link>
    <template v-if="children.length > 0 && selected">
      <Recursive
        v-for="item in children"
        :key="item.id"
        :menu="item"
        :expanding="expanding"
        :depth="depth + 1"
      />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Recursive',
  props: {
    menu: {
      type: Object,
      required: true,
    },
    expanding: {
      type: Boolean,
      required: false,
    },
    expandable: {
      type: Boolean,
      required: false,
      default: true,
    },
    depth: {
      type: Number,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: '',
      baseUrl: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    ...mapGetters({
      getMenus: 'menus/getMenus',
    }),
    children() {
      return this.getMenus.data.filter(
        (it) => it.attributes.parent_id === this.menu.id
      );
    },
  },
  methods: {
    setMenu(menu) {
      this.$emit('click', menu);
      if (!this.expandable) {
        return;
      }

      if (this.selected) {
        this.selected = '';
        return;
      }
      this.selected = menu.id;
    },
    getUrl(url) {
      if (url === null) {
        return null;
      } else {
        if (url[0] !== '/' && url) {
          return '/' + url;
        } else {
          return url;
        }
      }
    },
  },
  created() {
    // console.log(this.$route.fullPath.includes(this.menu.attributes.url))
  },
};
</script>
