import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  productPrices: {
    data: [],
    meta: {
      page: {},
    },
  },
  productPrice: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getProductPrices: (state) => {
    return state.productPrices;
  },
  getProductPrice: (state) => {
    return state.productPrice;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setProductPrices: (state, data) => (state.productPrices = data),
  setProductPrice: (state, data) => (state.productPrice = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchProductPrices({ commit }, { productId, params }) {
    commit('setLoader', true);

    Object.assign(params, { sort: '-createdAt' });

    try {
      const response = await ApiService.get(
        'api/v1/products/' + productId + '/product-prices',
        {
          params,
        }
      );

      commit('setProductPrices', response.data);
      commit('setError', null);
      commit('setLoader', false);
      
      return response;
    } catch (error) {
      commit('setProductPrices', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }

    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
