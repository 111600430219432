<template>
  <div class="flex justify-between">
    <h1 class="text-lg font-bold text-gray-900">
      {{ title }}
    </h1>
  </div>
</template>
<script>
export default {
  name: 'FormHeader',
  props: {
    title: {
      type: String,
      default: 'Pencatatan Data Kantor',
    },
  },
  data() {
    return {
      isEdit: this.$route.params.id,
    };
  },
};
</script>
