<template>
  <div
    class="fixed inset-0 z-10 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:h-screen sm:align-middle"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
      >
        <div class="border-b border-gray-200 bg-white pb-5">
          <div
            class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
          >
            <div class="ml-4 mt-4">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Release Notes
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Pelajari tentang peningkatan dan penyempurnaan di versi terbaru
                NASA ERP.
              </p>
            </div>
            <div class="ml-4 mt-4 flex-shrink-0">
              <button
                @click="onToggleReleaseNote"
                type="button"
                class="focus:outline-none inline-flex items-center rounded-full border border-transparent p-1 text-gray-600 focus:ring-2 focus:ring-offset-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-6 flow-root">
          <ul role="list" class="-my-5 divide-y divide-gray-200">
            <li
              v-for="note in notes.filter((_, index) =>
                showList ? true : index < 3
              )"
              :key="note.date"
              class="py-5"
            >
              <div
                class="relative focus-within:ring-2 focus-within:ring-indigo-500"
              >
                <div class="flex min-w-0 flex-row justify-between pb-3">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  <span
                    class="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800"
                  >
                    {{ note.status }}
                  </span>
                  <p class="truncate text-sm text-gray-500">
                    {{ note.date }}
                  </p>
                </div>
                <h3 class="text-xl font-semibold text-gray-800">
                  {{ note.version }}
                </h3>
                <p
                  v-for="feature in note.features"
                  :key="feature.title"
                  class="line-clamp-2 mt-1 text-sm text-gray-600"
                >
                  - {{ feature.description }}
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="mt-6">
          <a
            @click="toggleShowList"
            href="#"
            class="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
          >
            Lihat Semua
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import notes from '@/release-notes.json';
export default {
  name: 'ReleaseNote',
  data() {
    return {
      notes,
      showList: false,
    };
  },
  methods: {
    toggleShowList() {
      this.showList = !this.showList;
    },
    onToggleReleaseNote() {
      this.$emit('on-close');
    },
  },
};
</script>
