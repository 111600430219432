<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-100 ease-in"
    leave-to-class="transform scale-95 opacity-0"
  >
    <div
      v-if="visible"
      style="animation-duration: 0.4s"
      class="focus:outline-none absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white py-1 shadow-lg"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu-button"
      tabindex="-1"
    >
      <button
        v-if="
          user.current_role === 'Stockist' ||
          user.current_role === 'Stockist Center'
        "
        @click="toSaldo"
        class="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 h-5 w-5 flex-shrink-0"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
            clip-rule="evenodd"
          />
        </svg>
        <p>Saldo</p>
      </button>
      <div>
        <button
          v-if="hasAdminMenu"
          @click="setMenu('Admin')"
          class="flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          role="menuitem"
          tabindex="-1"
          id="user-menu-item-0"
        >
          <Icon
            icon="heroicons:user-circle-solid"
            class="mr-2 h-5 w-5 flex-shrink-0"
          />
          Admin
        </button>
        <button
          @click="toSetting"
          class="flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          role="menuitem"
          tabindex="-1"
          id="user-menu-item-0"
        >
          <Icon
            class="mr-2 h-5 w-5 flex-shrink-0"
            icon="heroicons:cog-8-tooth-20-solid"
          />
          Profile
        </button>
        <a
          @click="onLogout"
          href="#"
          class="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          role="menuitem"
          tabindex="-1"
          id="logout"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="mr-2 h-5 w-5 flex-shrink-0"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
            />
          </svg>

          Logout</a
        >
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'ProfileDropdown',
  props: {
    visible: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getMenus: 'menus/getMenus',
      getRolesByUser: 'users/getRolesByUser',
      getOfficeByOfficesCategories:
        'office_categories/getOfficeByOfficesCategories',
      user: 'auth/getUser',
      userOfficeCategory: 'auth/getUserOfficeCategory',
      menu: 'auth/getMenu',
    }),
    hasAdminMenu() {
      return this.getMenus.data.some(menu => menu.attributes.name === 'Admin')
    }
  },
  methods: {
    ...mapActions({
      authLogout: 'auth/logout',
      updateUser: 'users/updateUser',
      fetchMenus: 'menus/fetchMenus',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchOfficesByOfficeCategory:
        'office_categories/fetchOfficesByOfficeCategory',
    }),
    ...mapMutations({
      authSetMenu: 'auth/setMenu',
    }),
    toSaldo() {
      this.authSetMenu(this.user.current_role);
      this.$router.push('/saldo');
    },
    toSetting() {
      this.authSetMenu(this.user.current_role);
      this.$router.push('/settings');
    },
    getTotalMenus(menus) {
      let totalMenu = menus.filter(function (menu) {
        return menu.attributes.parent_id === null;
      });
      if (totalMenu.length > 1) {
        return true;
      }
      return false;
    },
    setMenu(params) {
      const menu = this.getMenus.data.find((it) =>
        it.attributes.name.includes(params)
      );
      if (!menu) {
        throw Error('Menu not found');
      }
      this.$emit('set-menu', menu);
    },
    onLogout() {
      this.$emit('logout');
    },
    showMenuModal() {
      this.$emit('toggle-menu');
    },
    changeRole() {
      this.$emit('change-role');
    },
  },
};
</script>
