import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import { baseRoutes } from '@/router/base';
import setupRoutesByRole from './setup-routes-by-role';
import { StorageService } from '../services/storage.service';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: baseRoutes,
});

router.beforeEach(async (to, from, next) => {
  StorageService.setAuthStateFromLocalStorage();

  if (StorageService.getToken() !== null && StorageService.getUser() === null) {
    try {
      if (!(await store.dispatch('auth/fetchMe'))) throw null;

      store.dispatch('menus/fetchMenus', {});

      const officeCategories = await store.dispatch(
        'office_categories/fetchOfficeCategories',
        {
          'fields[office-categories]':
            'name,cashback_jawa,cashback_luar_jawa,min_order_jawa,min_order_luar_jawa',
        }
      );

      if (!officeCategories) throw null;

      store.commit(
        'office_categories/setAvailableOfficeCategories',
        officeCategories.data.data
      );

      if (!(await store.dispatch('auth/fetchUserOfficeCategory'))) throw null;

      setupRoutesByRole(router);

      return next(to.path);
    } catch (err) {
      StorageService.removeToken();

      return next('/login');
    }
  }
  // Window Title on every page
  const company = ' | ERP Natural Nusantara';
  window.document.title =
    to.meta && to.meta.title ? to.meta.title + company : 'Home' + company;

  // Check Authentication
  if (to.matched.some((record) => record.meta.auth)) {
    if (StorageService.getToken() === null) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

/**
 * Remove navigation duplicate error
 * https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
 */
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

export default router;
