import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  provinces: {
    data: [],
    meta: {
      page: {},
    },
  },
  cities: {
    data: [],
    meta: {
      page: {},
    },
  },
  city: {
    data: [],
    meta: {
      page: {},
    },
  },
  districts: {
    data: [],
    meta: {
      page: {},
    },
  },
  villages: {
    data: [],
    meta: {
      page: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getProvinces: (state) => {
    return state.provinces;
  },
  getCities: (state) => {
    return state.cities;
  },
  getCity: (state) => {
    return state.city;
  },
  getDistricts: (state) => {
    return state.districts;
  },
  getVillages: (state) => {
    return state.villages;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setCity: (state, data) => (state.city = data),
  setProvinces: (state, data) => (state.provinces = data),
  setCities: (state, data) => (state.cities = data),
  setDistricts: (state, data) => (state.districts = data),
  setVillages: (state, data) => (state.villages = data),
};

const actions = {
  async fetchProvinces(
    { commit },
    {
      pageNumber,
      pageSize,
      search,
      fields,
      city_id,
      withoutParams,
      ...others
    } = {}
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    pageNumber ? (page['number'] = pageNumber) : (page['number'] = 1);
    pageSize ? (page['size'] = pageSize) : null;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    let response = await ApiService.get(
      city_id ? `api/v1/cities/${city_id}/province` : 'api/v1/provinces',
      withoutParams
        ? undefined
        : {
            params,
          }
    );
    commit('setProvinces', response.data);

    commit('setLoader', false);
    return response;
  },
  async fetchCities(
    { commit },
    { province_id, pageNumber, pageSize, search, fields, ...others }
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    let response = await ApiService.get(
      'api/v1/provinces/' + province_id + '/cities',
      {
        params,
      }
    );
    commit('setCities', response.data);
    commit('setLoader', false);
  },
  async fetchCityById({ commit }, { city_id, fields }) {
    commit('setLoader', true);
    const params = {};
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    try {
      let response = await ApiService.get('api/v1/cities/' + city_id, {
        params,
      });
      commit('setCity', response.data);
      return response;
    } catch (e) {
      return e;
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchDistricts(
    { commit },
    { city_id, pageNumber, pageSize, search, fields, ...others }
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    let response = await ApiService.get(
      'api/v1/cities/' + city_id + '/districts',
      {
        params,
      }
    );
    commit('setDistricts', response.data);
    commit('setLoader', false);
  },
  async fetchVillages(
    { commit },
    { district_id, pageNumber, pageSize, search, fields, ...others }
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    let response = await ApiService.get(
      'api/v1/districts/' + district_id + '/villages',
      {
        params,
      }
    );
    commit('setVillages', response.data);
    commit('setLoader', false);
  },
  async fetchCityProvince(commit, { cityId }) {
    return await ApiService.get(`api/v1/cities/${cityId}/province`);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
