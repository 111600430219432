<template>
  <div class="flex relative min-h-screen justify-center bg-gray-50 px-4">
    <div class="z-0">
      <div class="absolute bottom-0 left-0 text-red-50">
        <Kado class="w-28 object-cover sm:w-36" alt="Login Illustrator" />
      </div>
      <div class="absolute top-0 right-0 text-red-50">
        <OrnamentAtas class="object-cover" alt="Login Illustrator" />
      </div>
      <div class="absolute bottom-0 right-0 text-red-50">
        <OrnamentBawah
          class="w-36 object-cover sm:w-48"
          alt="Login Illustrator"
        />
      </div>
    </div>
    <div class="relative z-10 hidden w-0 flex-1 lg:flex">
      <div class="flex w-full items-center justify-center p-8">
        <div class="flex flex-col">
          <div class="lg:text-center">
            <h2
              class="text-xl font-semibold uppercase tracking-widest text-green-600"
            >
              Selamat
            </h2>
            <p
              class="mt-2 text-2xl font-extrabold uppercase leading-8 tracking-wide text-red-500 sm:text-3xl"
            >
              Natal dan Tahun Baru
            </p>
          </div>
          <div class="flex mt-6 w-full flex-row items-center justify-center">
            <PohonNatal class="object-cover" alt="Login Illustrator" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex z-10 min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
      <div
        class="flex flex-col justify-center rounded-xl bg-white py-12 px-10 shadow-xl sm:px-6 md:mx-8 lg:flex-none lg:px-12"
      >
        <div class="mx-auto w-full lg:w-96">
          <div class="flex flex-col">
            <div class="flex justify-center">
              <NasaLogo class="h-24" alt="Workflow" />
            </div>
            <h2 class="mt-6 text-xl font-extrabold text-gray-900 sm:text-2xl">
              Kelola bisnis melalui browser kesayangan Anda.
            </h2>
            <p class="mt-2 text-sm text-gray-600">Dan raih hasil maksimal.</p>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <AlertMessage
                v-if="getError"
                class="mb-4"
                type="error"
                show
                :message="getError"
              />
              <form @submit.prevent="login" class="space-y-6">
                <div>
                  <label
                    for="email"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Alamat Email
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="form.email"
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      required
                      class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div class="space-y-1">
                  <label
                    for="password"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Kata Sandi
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="form.password"
                      id="password"
                      name="password"
                      type="password"
                      autocomplete="current-password"
                      required
                      class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      for="remember-me"
                      class="ml-2 block text-sm text-gray-900"
                    >
                      Ingat saya
                    </label>
                  </div>

                  <div class="text-sm">
                    <router-link
                      :to="'/lupa-password'"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Lupa kata sandi?
                    </router-link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AlertMessage from '@/components/base/AlertMessage.vue';
import {
  Kado,
  OrnamentBawah,
  OrnamentAtas,
  PohonNatal,
  NasaLogo,
} from '@/assets/images';
export default {
  name: 'Login',
  components: {
    AlertMessage,
    Kado,
    OrnamentBawah,
    OrnamentAtas,
    PohonNatal,
    NasaLogo,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      getError: 'auth/getError',
    }),
  },
  methods: {
    ...mapActions({
      authLogin: 'auth/login',
      detailOfficeCategory: 'office_categories/detailOfficeCategory',
      fetchOfficeCategories: 'office_categories/fetchOfficeCategories',
    }),
    login() {
      this.authLogin(this.form).then((response) => {
        if (response) {
          localStorage.setItem('role', response.data.data.user.current_role);
          this.detailOfficeCategory({
            office_category_id: response.data.data.user.office_category_id,
          }).then((response) => {
            if (response) {
              this.fetchOfficeCategories().then((response) => {
                localStorage.setItem(
                  'office_categories',
                  JSON.stringify(response.data.data)
                );
              });
              this.redirectUser(response.data.data.attributes.name);
            }
          });
        }
      });
    },
    redirectUser(office) {
      if (office === 'admin') {
        this.$router.push('/admin');
      } else if (office === 'Pusat') {
        this.$router.push('/');
      } else if (office === 'penjualan_pusat') {
        this.$router.push('/penjualan');
      } else if (office === 'kasir_pusat') {
        this.$router.push('/kasir/');
      } else if (office === 'Kantor Pelayanan') {
        this.$router.push('/kantor-pelayanan');
      } else if (office === 'Stockist Center') {
        this.$router.push('/stockist-center');
      } else if (office === 'Stockist') {
        this.$router.push('/stockist');
      }
    },
  },
};
</script>
