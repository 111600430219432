import ApiService from '@/services/api.service';

const root = 'api/v1/office-categories';

const state = {
  isLoading: false,
  availableOfficeCategories: {},
  officeCategories: {
    data: [],
  },
  detailOfficeCategory: {},
  officesByOfficeCategory: {
    data: [],
    meta: {
      page: {
        total: 0,
        perPage: 0,
        currentPage: 0,
      },
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getAvailableOfficeCategories: (state) => {
    return state.availableOfficeCategories;
  },
  getOfficeCategories: (state) => {
    return state.officeCategories;
  },
  getDetailOfficeCategory: (state) => {
    return state.detailOfficeCategory;
  },
  getOfficeByOfficesCategories: (state) => {
    return state.officesByOfficeCategory;
  },
  getOfficeCategoryByName: state => (name) => state.officeCategories.data.find(item => item.attributes.name === name)
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setAvailableOfficeCategories: (state, data) =>
    (state.availableOfficeCategories = data),
  setOfficeCategories: (state, data) => (state.officeCategories = data),
  setOfficeByOfficesCategories: (state, data) =>
    (state.officesByOfficeCategory = data),
  setDetailOfficeCategory: (state, data) =>
    (state.officesByOfficeCategory = data),
  setMenus: (state, data) => (state.menus = data),
};

const actions = {
  async fetchAllOfficeCategory({ commit }, { pageNumber, pageSize, name }) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (name && name !== '') filter['name'] = name;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    try {
      let response = await ApiService.get('api/v1/office-categories', {
        params,
      });
      commit('setOfficeByOfficesCategories', response.data);
      return response;
    } catch (error) {
      commit('setOfficeByOfficesCategories', { data: [] });
    }
    commit('setLoader', false);
  },
  async fetchOfficeCategories({ commit }, params = {}) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(`${root}`, { params: { ...params } });
      commit('setOfficeCategories', response.data);
      return response;
    } catch (error) {
      commit('setOfficeCategories', { data: [] });
    }
    commit('setLoader', false);
  },
  async updateOfficeCategory({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        `${root}/${payload.data.id}`,
        payload
      );
      dispatch(
        'alert/createAlert',
        { data: 'Data berhasil diupdate' },
        { root: true }
      );
      commit('setOfficeCategories', response.data);
      return response;
    } catch (error) {
      commit('setOfficeCategories', { data: [] });
    }
    commit('setLoader', false);
  },
  async detailOfficeCategory({ commit }, { office_category_id }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(`${root}/${office_category_id}`);
      commit('setDetailOfficeCategory', response.data);
      return response;
    } catch (error) {
      commit('setDetailOfficeCategory', { data: [] });
    }
    commit('setLoader', false);
  },
  async fetchOfficesByOfficeCategory(
    { commit },
    { office_category_id, pageNumber, pageSize, search, ...others }
  ) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    const query = {
      ...params,
      ...others,
    }

    if (query.sort === '-createdAt' || query.sort === 'createdAt') {
      query.sort === 'id'
    }

    try {
      let response = await ApiService.get(
        'api/v1/office-categories/' + office_category_id + '/offices',
        {
          params: query,
        }
      );
      commit('setOfficeByOfficesCategories', response.data);
      return response;
    } catch (error) {
      commit('setOfficeByOfficesCategories', { data: [] });
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
