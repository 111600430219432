/**
 *
 * @param {String} value
 * @returns
 */

export function inputCurrency(value) {
  if (value === null || value === '') {
    return null
  }

  if (typeof value != 'string') {
    value = value + '';
  }
  const sanitizeValue = Number(value.split(',')[0].replace(/\D/gi, ''));
  return toCurrency(sanitizeValue);
}

export function toCurrency(value, options = {}) {
  if (typeof value !== 'number') {
    return value;
  }
  var formatter = new Intl.NumberFormat('id-ID', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  });
  return formatter.format(value);
}
