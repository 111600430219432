<template>
  <div
    v-if="expanding"
    class="relative flex h-full flex-col gap-4 overflow-y-auto bg-white dark:bg-gray-800"
  >
    <div
      @click="$router.push('/')"
      style="height: 4rem"
      class="flex w-full cursor-pointer items-center justify-start border-b bg-gray-50 px-4"
    >
      <div class="flex items-center gap-2">
        <div v-if="expanding">
          <h1 class="text-2xl font-bold tracking-widest text-nasa">NASA</h1>
          <p style="font-size: 8px">PT. Natural Nusantara</p>
        </div>
      </div>
    </div>
    <div class="px-4">
      <div style="width: 12rem"></div>
      <!-- <base-input
        v-if="true"
        :value="query.filter.search"
        @input="changeSearch"
        type="text"
        placeholder="Cari Menu..."
        id="cari_menu"
        fullwidth
      /> -->
      <ul class="space-y-2 text-sm font-medium">
        <li v-for="item in menu" :key="item.id">
          <Recursive
            :expanding="true"
            :selected-menu="selectedMenu"
            :menu="item"
            :depth="0"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { bgColor } from '@/services/themes.service';
import Recursive from './recursive.vue';
// import BaseInput from '@/components/base/BaseInput.vue';

export default {
  name: 'ChildMenuSidebar',
  components: {
    Recursive,
    // BaseInput,
  },
  props: {
    expanding: {
      type: Boolean,
      required: true,
    },
    selectedMenu: {
      type: String,
    },
    query: {
      type: Object,
    },
    menu: {
      type: Array,
      required: true,
    },
  },
  methods: {
    bgColor,
    changeSearch(text) {
      this.$emit('search', text);
    },
  },
};
</script>
