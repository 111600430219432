<template>
  <div
    style="
      position: fixed;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-tap-highlight-color: transparent;
      color: #fff;
      z-index: 1201;
    "
  >
    <div class="flex flex-col items-center justify-center">
      <svg
        style=""
        width="50"
        height="50"
        viewBox="0 0 100 100"
        class="z-10"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="translate(73 50)">
          <circle r="5" fill="#FFFFFF">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.875s"
              values="1.34 1.34;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.875s"
            />
          </circle>
        </g>
        <g transform="rotate(45 -46.855 113.119)">
          <circle r="5" fill="#FFFFFF" fill-opacity=".875">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.75s"
              values="1.34 1.34;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.75s"
            />
          </circle>
        </g>
        <g transform="rotate(90 -11.5 61.5)">
          <circle r="5" fill="#FFFFFF" fill-opacity=".75">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.625s"
              values="1.34 1.34;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.625s"
            />
          </circle>
        </g>
        <g transform="rotate(135 3.145 40.119)">
          <circle r="5" fill="#FFFFFF" fill-opacity=".625">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.5s"
              values="1.34 1.34;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.5s"
            />
          </circle>
        </g>
        <g transform="rotate(180 13.5 25)">
          <circle r="5" fill="#FFFFFF" fill-opacity=".5">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.375s"
              values="1.34 1.34;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.375s"
            />
          </circle>
        </g>
        <g transform="rotate(-135 23.855 9.881)">
          <circle r="5" fill="#FFFFFF" fill-opacity=".375">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.25s"
              values="1.34 1.34;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.25s"
            />
          </circle>
        </g>
        <g transform="rotate(-90 38.5 -11.5)">
          <circle r="5" fill="#FFFFFF" fill-opacity=".25">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.125s"
              values="1.34 1.34;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.125s"
            />
          </circle>
        </g>
        <g transform="rotate(-45 73.855 -63.119)">
          <circle r="5" fill="#FFFFFF" fill-opacity=".125">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="0s"
              values="1.34 1.34;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="0s"
            />
          </circle>
        </g>
      </svg>
      <p v-if="text">{{ text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
<style></style>
