import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  orderBundles: {
    data: [],
    meta: {
      page: {},
    },
  },
  orderBundle: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getOrderBundles: (state) => {
    return state.orderBundles;
  },
  getOrderBundle: (state) => {
    return state.orderBundle;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setOrderBundles: (state, data) => (state.orderBundles = data),
  setOrderBundle: (state, data) => (state.orderBundle = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async createOrderBundle({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/order-bundles', payload, {
        params: { include: 'paymentBundles' },
      });
      commit('setOrderBundle', response.data);
      commit('setError', null);
      commit('setLoader', false);
      dispatch(
        'alert/createAlert',
        { data: 'Berhasil Tambah Laporan', type: 'success' },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setOrderBundles', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async updateOrderBundle({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/order-bundles/' + payload.data.id,
        payload
      );
      commit('setOrderBundle', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setOrderBundles', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async validateOrderBundle({ commit, dispatch }, id) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/order-bundles/' + id + '/-actions/validate-order-bundle'
      );
      commit('setOrderBundle', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchOrderBundles(
    { commit },
    {
      keyword,
      pageNumber,
      pageSize,
      search,
      is_submitted,
      is_validated,
      is_paid,
      officeType,
      fromDate,
      toDate,
      ...others
    }
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (officeType) filter['office_type'] = officeType;
    if (search && search !== '') filter['search'] = search;
    if (is_submitted !== undefined && is_submitted !== null)
      filter['is_submitted'] = is_submitted;
    if (is_validated !== undefined && is_validated !== null)
      filter['is_validated'] = is_validated;
    if (is_paid !== undefined && is_paid !== null) filter['is_paid'] = is_paid;
    if (fromDate !== undefined && fromDate !== null)
      filter['fromDate'] = fromDate;
    if (toDate !== undefined && toDate !== null) filter['toDate'] = toDate;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, { include: 'orders,paymentBundles' });
    Object.assign(params, { sort: '-createdAt' });
    try {
      let response = await ApiService.get('api/v1/order-bundles', {
        params,
      });
      commit('setOrderBundles', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOrderBundles', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchOrderBundle({ commit, dispatch }, { id, ...others }) {
    commit('setLoader', true);
    const params = { ...others };
    Object.assign(params, { include: params.include ?? 'orders,office' });
    try {
      let response = await ApiService.get('api/v1/order-bundles/' + id, {
        params,
      });
      commit('setOrderBundle', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setOrderBundle', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async createPaymentOrderBundle({ commit, dispatch }, { id }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        'api/v1/order-bundles/' + id + '/-actions/pay'
      );
      commit('setOrderBundle', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setOrderBundles', {});
    }
    commit('setLoader', false);
  },
  async fetchTotalTransaction({ commit, dispatch }, { params }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(
        `/api/v1/order-bundles/-actions/getTotalTransaction`,
        { params }
      );
      commit('setOrderBundle', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setOrderBundles', {});
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
