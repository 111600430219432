<template>
  <div class="flex min-h-screen bg-white">
    <div class="relative hidden w-0 flex-1 bg-gray-50 lg:flex">
      <div class="flex w-full items-center justify-center p-8">
        <!-- <img class="object-cover" src="@/assets/images/login_illust.svg" alt="Login Illustrator" /> -->
      </div>
    </div>
    <div
      class="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div class="flex flex-col">
          <div class="flex justify-center">
            <!-- <img
              class="h-24"
              src="@/assets/images/logo_nasa.svg"
              alt="Workflow"
            /> -->
          </div>
          <h2 class="mt-6 text-2xl font-extrabold text-gray-900">
            Gabung bersama kami.
          </h2>
          <p class="mt-2 text-sm text-gray-600">
            Raih berbagai macam keuntungan.
          </p>
        </div>

        <div class="mt-8">
          <div class="mt-6">
            <form @submit.prevent="register" class="space-y-6">
              <div>
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Nama
                </label>
                <div class="mt-1">
                  <input
                    v-model="form.name"
                    id="name"
                    name="name"
                    type="text"
                    autocomplete="name"
                    required
                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div class="space-y-1">
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Alamat Email
                </label>
                <div class="mt-1">
                  <input
                    v-model="form.email"
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required
                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Kata Sandi
                </label>
                <div class="mt-1">
                  <input
                    v-model="form.password"
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div class="space-y-1">
                <label
                  for="confirmation"
                  class="block text-sm font-medium text-gray-700"
                >
                  Konfirmasi Kata Sandi
                </label>
                <div class="mt-1">
                  <input
                    v-model="form.password_confirmation"
                    id="confirmation"
                    name="confirmation"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="mt-6">
              <div class="mt-4 justify-items-center">
                <div class="text-center text-sm">
                  Sudah memiliki akun?
                  <router-link
                    to="/admin/login"
                    class="cursor-pointer font-bold text-indigo-600 hover:text-indigo-400"
                  >
                    Masuk!</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  methods: {
    ...mapActions({
      authRegister: 'auth/register',
    }),
    register() {
      this.authRegister(this.form).then((response) => {
        if (response.status == 201) {
          this.$router.push('/dashboard');
        }
      });
    },
  },
};
</script>
