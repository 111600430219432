import ApiService from '@/services/api.service';

const permission = {
  type: '',
  id: '',
  attributes: {
    name: '',
    description: '',
    deletedAt: null,
    createdAt: '',
    updatedAt: '',
  },
  relationships: {
    offices: {
      links: {
        related: '',
        self: '',
      },
    },
  },
  links: {
    self: '',
  },
};

const state = {
  isLoading: false,
  error: null,
  permission: permission,
  permissions: {
    data: [permission],
    included: [],
    meta: {
      page: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0,
      },
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getPermission: (state) => {
    return state.permission;
  },
  getPermissions: (state) => {
    return state.permissions;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setPermissions: (state, data) => (state.permissions = data),
  setPermission: (state, data) => (state.permission = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchPermissions({ commit }, { ...params } = {}) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(`api/v1/permissions`, { params });
      commit('setPermissions', response.data);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async createPermission({ commit, dispatch }, { payload }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(`api/v1/permissions`, payload);
      commit('setPermission', response.data);
      dispatch(
        'alert/createAlert',
        { data: 'Data pendidikan berhasil ditambahkan' },
        { root: true }
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        commit('setError', error.response.data.errors[0].detail);
      } else {
        commit('setError', 'Gagal');
      }
    } finally {
      commit('setLoader', false);
    }
  },
  async updatePermission({ commit, dispatch }, { payload }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        `api/v1/permissions/${payload.data.id}`,
        payload
      );
      commit('setPermission', response.data);
      dispatch(
        'alert/createAlert',
        {
          data: `Data pendidikan berhasil di${
            payload.data.attributes.deletedAt ? 'hapus' : 'ubah'
          }`,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        commit('setError', error.response.data.errors[0].detail);
      } else {
        commit('setError', 'Gagal');
      }
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
