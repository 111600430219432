import ApiService from '@/services/api.service';
const options = {
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'multipart/form-data',
    'cache-control': 'no-cache',
  },
};

const state = {
  data: null,
  isLoading: false,
};

const getters = {
  getData: (state) => state.data,
  getLoading: (state) => state.isLoading,
};

const mutations = {
  setData: (state, payload) => (state.data = payload),
  setLoading: (state, isLoading) => (state.isLoading = isLoading),
};

const actions = {
  async createDisputes({ commit, dispatch }, { payload } = {}) {
    commit('setLoading', true);

    try {
      let response = await ApiService.post('api/v1/disputes', payload);
      commit('setData', response.data);
      dispatch(
        'alert/createAlert',
        { data: 'Laporan Berhasil Dikirimkan' },
        { root: true }
      );
      return response;
    } catch (err) {
      dispatch(
        'alert/createAlert',
        { data: err.response.data },
        { root: true }
      );
    } finally {
      commit('setLoading', false);
    }
  },
  async uploadFileDisputes({ commit }, { payload }) {
    let response = await ApiService.post(
      'api/v1/dispute-images/-actions/uploads',
      payload,
      { options }
    );
    commit('setData', response.data);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
