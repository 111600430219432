import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  journalEntries: {
    meta: {
      page: {
        total: 0,
      },
    },
    data: [],
  },
  loadingActions: {
    fetchAllJournalEntries: false,
    downloadDbf: false,
  },
};

const getters = {
  getLoadingActions: (state) => (action) => state.loadingActions[action],
  getLoading: (state) => {
    return state.isLoading;
  },
  getError: (state) => {
    return state.error;
  },
  getJournalEntries: (state) => {
    return state.journalEntries;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setLoadingActions: (state, { action, value }) =>
    (state.loadingActions[action] = value),
  setJournalEntries: (state, value) => (state.journalEntries = value),
};

const actions = {
  async downloadDbf(
    { commit, dispatch },
    { action } = { action: 'downloadDbf' }
  ) {
    commit('setLoadingActions', { action: 'downloadDbf', value: true });

    const url = `/api/v1/journal-entries/-actions/${action}`;
    try {
      let response = await ApiService.get(url, {
        responseType: 'blob',
      });

      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoadingActions', { action: 'downloadDbf', value: false });
    }
  },
  async fetchAllJournalEntries({ commit, dispatch }, params) {
    commit('setLoadingActions', {
      action: 'fetchAllJournalEntries',
      value: true,
    });

    try {
      const res = await ApiService.get('/api/v1/journal-entries', { params });

      commit('setJournalEntries', res.data);
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoadingActions', {
        action: 'fetchAllJournalEntries',
        value: false,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
