var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user.current_period && _vm.userOfficeCategory?.attributes?.name)?_c('header',{staticClass:"no-print flex w-full items-center bg-white p-1 text-gray-700 md:px-6",staticStyle:{"justify-content":"space-between"}},[_c('div',{staticClass:"block px-4 text-xs"},[_c('div',{staticClass:"flex gap-2"},[_c('div',{},[_vm._v(_vm._s(_vm.user.name))])])]),_c('div',{staticClass:"text-xs"},[(
        _vm.userOfficeCategory.attributes.name.toLowerCase() !=
        _vm.user.office_code.toLowerCase()
      )?[_vm._v(" "+_vm._s(_vm.userOfficeCategory.attributes.name)+" ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.user.office_code)+" (Wilayah "+_vm._s(_vm.getWilayah)+") ")],2),_c('div',[_c('div',{staticClass:"block text-xs font-medium text-gray-500 dark:text-gray-400 sm:text-center"},[_vm._v(" Periode "+_vm._s(_vm.user.current_period.name)+" ")]),_c('div',{staticClass:"flex justify-between text-right text-gray-500 dark:text-gray-400",staticStyle:{"font-size":"0.5rem","line-height":"0.75rem"}},[(_vm.userOfficeCategory.attributes.name == 'Pusat')?_c('p',[_vm._v(" Pusat pukul "+_vm._s(_vm.dayjs(_vm.user.current_period.end_date).format('ll LT'))+" ")]):(
          _vm.userOfficeCategory.attributes.name == 'Kantor Pelayanan' ||
          _vm.userOfficeCategory.attributes.name == 'Stockist Center'
        )?_c('p',[_vm._v(" SC/KP pukul "+_vm._s(_vm.dayjs(_vm.user.current_period.end_date).format('ll LT'))+" ")]):[_c('div',[_vm._v(" "+_vm._s(_vm.dayjs(_vm.user.current_period.start_date).format('ll LT'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.dayjs(_vm.user.current_period.end_date).format('ll LT'))+" ")])]],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }