<template>
  <div>
    <div class="flex sm:flex-row sm:gap-x-4">
      <base-input
        v-model="value.alamat.detail"
        tag="textarea"
        type="text"
        name="detail"
        id="alamat_detail"
        class="block w-full border-0 px-0 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
        placeholder="Nama Jalan, Nomor, RT/RW, Desa/Kelurahan"
        label="Alamat"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.alamat.detail"
        :message="errors.alamat.detail"
        fullwidth
        rows="3"
      />
    </div>
    <div class="mt-4 flex sm:flex-row sm:gap-x-4">
      <base-input
        label="Provinsi"
        inset
        with-label
        :disabled="disabled"
        :invalid="errors.alamat.provinsi"
        :message="errors.alamat.provinsi"
        fullwidth
      >
        <select
          v-model="value.alamat.provinsi"
          @change="changeProvince"
          id="alamat_provinsi"
          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 disabled:bg-gray-100 sm:text-sm"
          :disabled="disabled"
        >
          <option :value="null">Pilih Provinsi</option>
          <option
            v-for="provinsi in getProvinces.data"
            :key="provinsi.id"
            :value="provinsi.id"
          >
            {{ provinsi.attributes.name }}
          </option>
        </select>
      </base-input>
      <base-input
        label="Kab/Kota"
        inset
        with-label
        :disabled="disabled"
        :invalid="errors.alamat.kabupaten"
        :message="errors.alamat.kabupaten"
        fullwidth
        :class="!value.alamat.provinsi ? disabledClass() : null"
      >
        <select
          v-model="value.alamat.kabupaten"
          @change="changeCity"
          id="alamat_kabupaten"
          :disabled="disabled"
          :class="!value.alamat.provinsi ? disabledClass() : null"
          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 disabled:bg-gray-100 sm:text-sm"
        >
          <option :value="null">Pilih Kabupaten</option>
          <option v-for="kab in getCities.data" :key="kab.id" :value="kab.id">
            {{ kab.attributes.name }}
          </option>
        </select>
      </base-input>
    </div>
    <div class="mt-4 flex sm:flex-row sm:gap-x-4">
      <base-input
        label="Kecamatan"
        inset
        with-label
        :invalid="errors.alamat.kecamatan"
        :message="errors.alamat.kecamatan"
        fullwidth
        :disabled="disabled"
      >
        <select
          v-model="value.alamat.kecamatan"
          @change="changeDistrict"
          :disabled="disabled"
          id="alamat_kecamatan"
          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 disabled:bg-gray-100 sm:text-sm"
        >
          <option :value="null">Pilih Kecamatan</option>
          <option
            v-for="kec in getDistricts.data"
            :key="kec.id"
            :value="kec.id"
          >
            {{ kec.attributes.name }}
          </option>
        </select>
      </base-input>
      <base-input
        label="Desa"
        inset
        with-label
        :invalid="errors.alamat.desa"
        :message="errors.alamat.desa"
        fullwidth
      >
        <select
          v-model="value.alamat.desa"
          id="alamat_desa"
          :disabled="disabled"
          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 disabled:bg-gray-100 sm:text-sm"
        >
          <option :value="null">Pilih desa</option>
          <option
            v-for="desa in getVillages.data"
            :key="desa.id"
            :value="desa.id"
          >
            {{ desa.attributes.name }}
          </option>
        </select>
      </base-input>
    </div>
    <div class="mt-4 flex sm:flex-row sm:gap-x-4">
      <base-input
        v-model="value.alamat.kode_pos"
        type="text"
        name="alamat_kode_pos"
        id="alamat_kode_pos"
        placeholder="Kode Pos"
        label="Kode Pos"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.alamat.kode_pos"
        :message="errors.alamat.kode_pos"
        fullwidth
      />
    </div>
    <div class="mt-4 flex sm:flex-row sm:gap-x-4">
      <div class="field-inset-default w-full bg-gray-100">
        <label for="area" class="block text-xs font-bold text-gray-700">
          Area
        </label>
        <input
          @change="changeArea"
          type="text"
          name="area"
          id="area"
          readonly
          class="block w-full border-0 bg-gray-100 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
          placeholder="Silakan Pilih Kabupaten/Kota"
          v-model="value.area"
        />
      </div>
    </div>
    <div
      v-if="viewAs != 'kantor_pelayanan'"
      class="mt-4 flex sm:flex-row sm:gap-x-4"
    >
      <div class="w-full">
        <label
          for="is_kta_need_delivery"
          class="block text-xs font-bold text-gray-700"
        >
          KTA
        </label>
        <div class="mt-3 flex items-center space-x-8">
          <div class="items-center text-sm text-gray-700">
            <input
              v-model="value.is_kta_need_delivery"
              type="checkbox"
              name="is_kta_need_delivery"
              id="is_kta_need_delivery"
              class="mr-2 h-4 w-4 rounded border-gray-300 text-gray-500 focus:ring-gray-500"
              :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
              aria-labelledby="privacy-setting-1-label"
              aria-describedby="privacy-setting-1-description"
              :disabled="disabled"
            />
            <label for="is_kta_need_delivery"
              >Dikirim langsung ke alamat mitra usaha</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@/components/base/BaseInput.vue';
import { mapGetters, mapActions } from 'vuex';
// import BaseButton from '@/components/base/BaseButton.vue';
// import BaseSelect from '@/components/base/BaseSelect.vue';

export default {
  name: 'AddressForm',
  components: {
    // BaseButton,
    BaseInput,
    // BaseSelect,
  },
  props: {
    value: {
      type: Object,
    },
    viewAs: {
      type: String,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
    },
    selectProfessionOptions: {
      type: Array,
    },
    selectEducationOptions: {
      type: Array,
    },
    selectedMarital: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getAreas: 'areas/getAreas',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
    }),
  },
  methods: {
    ...mapActions({
      fetchAreas: 'areas/fetchAreas',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
    }),
    disabledClass() {
      return 'bg-gray-100 cursor-not-allowed';
    },
    saving() {},
    changeMarital() {
      this.$emit('change-marital');
    },
    changeProvince(value) {
      this.$emit('change-province', value);
    },
    changeCity(value) {
      this.$emit('change-city', value);
    },
    changeDistrict(value) {
      this.$emit('change-district', value);
    },
    changeArea(value) {
      this.$emit('change-area', value);
    },
  },
  created() {
    this.fetchProvinces({
      pageNumber: 1,
      fields: {
        provinces: 'name',
      },
    });
    this.fetchAreas({
      fields: {
        areas: 'code',
      },
    });
    if (this.value.alamat.provinsi) {
      this.fetchCities({
        province_id: this.value.alamat.provinsi,
        pageNumber: 1,
      });
    }
    if (this.value.alamat.kabupaten) {
      this.fetchDistricts({
        city_id: this.value.alamat.kabupaten,
        pageNumber: 1,
        fields: {
          provinces: 'name',
        },
      });
    }

    if (this.value.alamat.kecamatan) {
      this.fetchVillages({
        district_id: this.value.alamat.kecamatan,
        pageNumber: 1,
        fields: {
          provinces: 'name',
        },
      });
    }
  },
};
</script>
