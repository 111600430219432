import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  warehouseByOffice: {
    data: [],
    meta: {
      page: {},
    },
  },
  warehouseStock: {
    data: [],
    meta: {
      page: {},
    },
  },
  warehouses: {
    data: [],
    meta: {
      page: {},
    },
  },
  warehouse: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getWarehouses: (state) => {
    return state.warehouses;
  },
  getWarehouse: (state) => {
    return state.warehouse;
  },
  getWarehouseByOffice: (state) => {
    return state.warehouseByOffice;
  },
  getWarehouseStock: (state) => {
    return state.warehouseStock;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setWarehouses: (state, data) => (state.warehouses = data),
  setWarehouse: (state, data) => (state.warehouse = data),
  setWarehouseByOffice: (state, data) => (state.warehouseByOffice = data),
  setWarehouseStock: (state, data) => (state.warehouseStock = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchWarehouses(
    { commit },
    {
      sort,
      pageNumber,
      pageSize,
      search,
      keyword,
      warehouseType,
      ...others
    } = {}
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (warehouseType) filter['warehouse_type'] = warehouseType;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    Object.assign(params, { sort });
    try {
      let response = await ApiService.get('api/v1/warehouses', {
        params: {
          ...params,
          ...others,
        },
      });
      commit('setWarehouses', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setWarehouses', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchWarehouseById({ commit }, { id, params }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get('api/v1/warehouses/' + id, {
        params,
      });
      commit('setWarehouse', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setWarehouse', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchWarehouseByOffice(
    { commit },
    {
      userOfficeId,
      keyword,
      sort,
      pageNumber,
      pageSize,
      search,
      notCode,
      warehouseType,
      ...others
    }
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (notCode && notCode !== '') filter['not-code'] = notCode; // usage:  notCode = 'nasa'
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (warehouseType) filter['warehouse_type'] = warehouseType;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    Object.assign(params, { sort: sort || '-id' });
    try {
      let response = await ApiService.get(
        'api/v1/offices/' + userOfficeId + '/warehouses',
        {
          params,
        }
      );
      commit('setWarehouseByOffice', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setWarehouseByOffice', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchWarehouseStock(
    { commit },
    {
      warehouseId,
      sellerOfficeCode,
      buyerOfficeCode,
      productCode,
      areaCode,
      buyerTypeCode,
      pageNumber,
      pageSize,
      search,
      include,
      isMinusBV,
      ...others
    }
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    if (sellerOfficeCode && sellerOfficeCode !== '')
      filter['seller_office_code'] = sellerOfficeCode;
    if (buyerOfficeCode && buyerOfficeCode !== '')
      filter['buyer_office_code'] = buyerOfficeCode;
    if (productCode && productCode !== '') filter['product_code'] = productCode;
    if (areaCode !== undefined) filter['area_code'] = areaCode;
    if (include !== undefined) params.include = include;
    else params.include = 'product';
    if (isMinusBV) params.is_minus_bv = isMinusBV;
    if (buyerTypeCode && buyerTypeCode !== '')
      filter['buyer_type_code'] = buyerTypeCode;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    Object.assign(params, { sort: '-createdAt' });
    try {
      let response = await ApiService.get(
        'api/v1/warehouses/' + warehouseId + '/stocks',
        {
          params,
        }
      );
      commit('setWarehouseStock', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setWarehouseStock', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
