import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './modules/auth.module';
import alert from './modules/alert.module';
import indonesia from './modules/indonesia.module';
import menus from './modules/menus.module';
import office_categories from './modules/office_categories.module';
import orders from './modules/orders.module';
import products from './modules/products.module';
import product_categories from './modules/product_categories.module';
import sales from './modules/sales.module';
import stockists from './modules/stockists.module';
import units from './modules/units.module';
import suppliers from './modules/suppliers.module';
import purchaseorders from './modules/purchaseorders.module';
import incoming_goods from './modules/incoming_goods.module';
import users from './modules/users.module';
import stocks from './modules/stocks.module';
import warehouses from './modules/warehouses.module';
import areas from './modules/areas.module';
import buyer_types from './modules/buyer_types.module';
import stock_movements from './modules/stock_movements.module';
import product_limits from './modules/product_limits.module';
import stockists_center from './modules/stockists_center.module';
import addresses from './modules/addresses.module';
import setups from './modules/setups.module';
import offices from './modules/offices.module';
import banks from './modules/banks.module';
import office_banks from './modules/office_banks.module';
import payments from './modules/payments.module';
import order_bundles from './modules/order_bundles.module';
import roles from './modules/roles.module';
import payment_methods from './modules/payment_methods.module';
import expeditions from './modules/expeditions.module';
import packets from './modules/packets.module';
import members from './modules/members.module';
import kantor_pelayanan from './modules/kantor_pelayanan.module';
import disputes from './modules/disputes.module';
import balance_trxes from './modules/balance_trxes.module';
import payment_bundles from './modules/payment_bundles.module';
import office_expeditions from './modules/office_expeditions.module';
import topup_types from './modules/topup_types.module';
import educations from './modules/educations.module';
import professions from './modules/professions.module';
import journal_entries from './modules/journal_entries.module';
import periods from './modules/periods.module';
import mitra_periods from './modules/mitra_periods.module';
import movement_details from './modules/movement_details.module';
import stock_trxes from './modules/stock_trxes.module';
import expedition_details from './modules/expedition_details.module';
import order_details from './modules/order_details.module';
import returs from './modules/returs.module';
import retur_details from './modules/retur_details.module';
import stock_global_reports from './modules/stock_global_reports.module';
import payment_method_types from './modules/payment_method_types.module';
import notification from './modules/notification.module';
import permissions from './modules/permissions.module';
import product_prices from './modules/product_prices.module';
import simple_offices from './modules/simple_offices.module';
import main from './modules/main.module';
import retur_shipments from './modules/retur_shipments.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    auth,
    sales,
    menus,
    banks,
    areas,
    units,
    users,
    roles,
    returs,
    stocks,
    setups,
    orders,
    packets,
    periods,
    members,
    offices,
    payments,
    disputes,
    products,
    addresses,
    indonesia,
    stockists,
    suppliers,
    warehouses,
    educations,
    buyer_types,
    permissions,
    stock_trxes,
    topup_types,
    expeditions,
    professions,
    office_banks,
    retur_details,
    balance_trxes,
    mitra_periods,
    order_bundles,
    incoming_goods,
    purchaseorders,
    product_limits,
    product_prices,
    journal_entries,
    payment_methods,
    payment_bundles,
    stock_movements,
    stockists_center,
    kantor_pelayanan,
    movement_details,
    office_categories,
    office_expeditions,
    product_categories,
    expedition_details,
    order_details,
    stock_global_reports,
    payment_method_types,
    notification,
    simple_offices,
    main,
    retur_shipments,
  },
});
