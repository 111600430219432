<template>
  <div class="space-y-6">
    <div
      v-if="getMember?.data?.attributes?.office_type !== 'member'"
      class="space-y-4"
    >
      <h4 class="font-medium text-gray-900">Data Mitra Usaha</h4>
      <div class="flex sm:flex-row sm:gap-x-4">
        <base-input
          label="Kode Mitra Usaha"
          placeholder="Kode Mitra Usaha"
          with-label
          inset
          disabled
          fullwidth
          :value="getUplineHistory('promoted_to_stockist_by').code"
        />
        <base-input
          label="Nama Mitra Usaha"
          placeholder="Nama Mitra Usaha"
          with-label
          inset
          disabled
          fullwidth
          :value="getUplineHistory('promoted_to_stockist_by').name"
        />
      </div>
    </div>
    <div
      v-if="getMember?.data?.attributes?.office_type === 'stockist'"
      class="space-y-4"
    >
      <h4 class="font-medium text-gray-900">Data Stockist Center</h4>
      <div class="flex sm:flex-row sm:gap-x-4">
        <base-input
          label="Kode Stockist Center"
          placeholder="Kode Stockist Center"
          with-label
          inset
          disabled
          fullwidth
          :value="getDownlineHistory('promoting_to_sc').code"
        />
        <base-input
          label="Nama Stockist Center"
          placeholder="Nama Stockist Center"
          with-label
          inset
          disabled
          fullwidth
          :value="getDownlineHistory('promoting_to_sc').name"
        />
      </div>
    </div>
    <div
      v-if="getMember?.data?.attributes?.office_type === 'stockist_center'"
      class="space-y-4"
    >
      <h4 class="font-medium text-gray-900">Data Stockist</h4>
      <div class="flex sm:flex-row sm:gap-x-4">
        <base-input
          label="Kode Stockist"
          placeholder="Kode Stockist"
          with-label
          inset
          disabled
          fullwidth
          :value="getUplineHistory('promoted_to_sc_by').code"
        />
        <base-input
          label="Nama Stockist"
          placeholder="Nama Stockist"
          with-label
          inset
          disabled
          fullwidth
          :value="getUplineHistory('promoted_to_sc_by').name"
        />
      </div>
    </div>
    <div
      v-if="getMember?.data?.attributes?.office_type === 'member'"
      class="space-y-4"
    >
      <h4 class="font-medium text-gray-900">Data Stockist</h4>
      <div class="flex sm:flex-row sm:gap-x-4">
        <base-input
          label="Kode Stockist"
          placeholder="Kode Stockist"
          with-label
          inset
          disabled
          fullwidth
          :value="getDownlineHistory('promoting_to_stockist').code"
        />
        <base-input
          label="Nama Stockist"
          placeholder="Nama Stockist"
          with-label
          inset
          disabled
          fullwidth
          :value="getDownlineHistory('promoting_to_stockist').name"
        />
      </div>
    </div>
    <div
      v-if="getMember?.data?.attributes?.office_type === 'member'"
      class="space-y-4"
    >
      <h4 class="font-medium text-gray-900">Data Stockist Center</h4>
      <div class="flex sm:flex-row sm:gap-x-4">
        <base-input
          label="Kode Stockist Center"
          placeholder="Kode Stockist Center"
          with-label
          inset
          disabled
          fullwidth
          :value="getDownlineHistory('promoting_to_sc').code"
        />
        <base-input
          label="Nama Stockist Center"
          placeholder="Nama Stockist Center"
          with-label
          inset
          disabled
          fullwidth
          :value="getDownlineHistory('promoting_to_sc').name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseInput,
  },
  computed: {
    ...mapGetters({
      getMember: 'members/getMember',
    }),
  },
  methods: {
    getUplineHistory(type) {
      return (
        this.getMember.data?.attributes?.upline_histories?.find(
          (upline) => upline.type === type
        ) ?? {}
      );
    },
    getDownlineHistory(type) {
      return (
        this.getMember.data?.attributes?.downline_histories?.find(
          (downline) => downline.type === type
        ) ?? {}
      );
    },
  },
};
</script>
