const LayoutDefault = () =>
  import(/* webpackChunkName: "sc" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "sc" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/penjualan/dashboard/Index.vue'
  );

// Transaksi Penjualan
const TransaksiPenjualan = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/penjualan/transaksi-penjualan/Index.vue'
  );
const TambahTransaksiPenjualan = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/penjualan/transaksi-penjualan/Tambah.vue'
  );

// Laporan Penjualan SC
const LaporanPenjualanSC = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/penjualan/laporan-penjualan-sc/Index.vue'
  );
const TambahLaporanPenjualanSC = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/penjualan/laporan-penjualan-sc/Tambah.vue'
  );

let root = '/penjualan';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Penjualan Pusat',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/transaksi-penjualan/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Transaksi Penjualan',
    },
    children: [
      {
        path: '',
        component: TransaksiPenjualan,
        meta: {
          auth: true,
          title: 'Transaksi Penjualan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-transaksi-penjualan',
        component: TambahTransaksiPenjualan,
        meta: {
          auth: true,
          title: 'Tambah Transaksi Penjualan | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Transaksi Penjualan',
        },
      },
      {
        path: ':id',
        component: TambahTransaksiPenjualan,
        meta: {
          auth: true,
          title: 'Edit Transaksi Penjualan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/laporan-penjualan-sc/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Laporan Penjualan SC',
    },
    children: [
      {
        path: '',
        component: LaporanPenjualanSC,
        meta: {
          auth: true,
          title: 'Laporan Penjualan SC | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-laporan-penjualan-sc',
        component: TambahLaporanPenjualanSC,
        meta: {
          auth: true,
          title: 'Tambah Laporan Penjualan SC | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Laporan Penjualan SC',
        },
      },
    ],
  },
];
