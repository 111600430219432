import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  members: {
    data: [],
    meta: {
      page: {},
    },
  },
  member: {},
  upline: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getMembers: (state) => {
    return state.members;
  },
  getMember: (state) => {
    return state.member;
  },
  getUpline: (state) => {
    return state.upline;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setMembers: (state, data) => (state.members = data),
  setMember: (state, data) => (state.member = data),
  setUpline: (state, data) => (state.upline = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async createMember({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        'api/v1/offices/-actions/createMember',
        payload
      );
      commit('setMember', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setMembers', {});
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
    }
    commit('setLoader', false);
  },
  async updateMember({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/offices/' + payload.data.id + '?include=addresses',
        payload
      );
      commit('setMember', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setMembers', {});
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
  async fetchMembers(
    { commit, rootGetters },
    {
      keyword,
      pageNumber,
      pageSize,
      search,
      include,
      fields,
      filters,
      withSort = true,
      ...others
    } = {}
  ) {
    commit('setLoader', true);
    let member_office_category_id = rootGetters[
      'office_categories/getAvailableOfficeCategories'
    ].filter(function (el) {
      return el.attributes.name === 'Member';
    });
    const params = {};
    let filter = [];
    let page = [];
    if (keyword && keyword !== '') filter['search'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, {
      include: include ?? 'addresses,area,upline-relations',
    });

    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    if (filters) {
      Object.entries(filters).forEach(([prop, value]) => {
        Object.assign(params, {
          [`filter[${prop}]`]: value,
        });
      });
    }

    if (withSort) {
      Object.assign(params, { sort: '-id' });
    }
    try {
      let response = await ApiService.get(
        'api/v1/office-categories/' +
          member_office_category_id[0].id +
          '/offices',
        {
          params: {
            ...params,
            ...others,
          },
        }
      );
      commit('setMembers', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setMembers', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchMember({ commit }, { id, include, fields }) {
    commit('setLoader', true);
    const params = {};
    Object.assign(params, {
      include:
        include ?? 'addresses,upline-relations,education,profession,area',
    });
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    try {
      let response = await ApiService.get(`api/v1/offices/${id}`, {
        params,
      });
      commit('setMember', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setMember', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchUpline({ commit }, { id }) {
    commit('setLoader', true);
    const params = {};
    try {
      let response = await ApiService.get(
        `api/v1/offices/${id}/uplines?filter[office_category_id]=5`,
        {
          params,
        }
      );
      commit('setUpline', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setUpline', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchMembersByUpline({ commit }, { stockistId, params }) {
    try {
      commit('setLoader', true);

      const res = await ApiService.get(
        `api/v1/offices/${stockistId}/downlines`,
        {
          params: {
            ...params,
            'filter[office_type]': 'member',
          },
        }
      );

      commit('setMembers', res.data);
      commit('setError', null);
    } catch (err) {
      commit('setMembers', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('error', err);
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
