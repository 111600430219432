import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  officeBanks: {
    data: [],
    meta: {
      page: {},
    },
  },
  officeBank: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getOfficeBanks: (state) => {
    return state.officeBanks;
  },
  getOfficeBank: (state) => {
    return state.officeBank;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setOfficeBanks: (state, data) => (state.officeBanks = data),
  setOfficeBank: (state, data) => (state.officeBank = data),
  setError: (state, error) => (state.error = error),
};

const actions = {
  async fetchOfficeBanks({ commit }, { pageNumber, pageSize, search }) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, { sort: '-createdAt' });
    try {
      let response = await ApiService.get('api/v1/office-banks', {
        params,
      });
      commit('setOfficeBanks', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOfficeBanks', { data: [], meta: { page: {} } });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async createOfficeBank({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/office-banks', payload);
      commit('setOfficeBank', response.data);
      commit('setError', null);
      commit('setLoader', false);
      dispatch(
        'alert/createAlert',
        { data: 'Data Berhasil Ditambahkan' },
        { root: true }
      );
      return response;
    } catch (error) {
      commit('setOfficeBank', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async updateBank({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/office-banks/' + payload.data.id,
        payload
      );
      commit('setOfficeBank', response.data);
      commit('setError', null);
      dispatch(
        'alert/createAlert',
        { data: 'Data Berhasil Diubah' },
        { root: true }
      );
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
  async deleteBank({ commit }, id) {
    commit('setLoader', true);
    try {
      const options = {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        },
      };
      let response = await ApiService.delete(
        'api/v1/office-banks/' + id,
        options
      );
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
