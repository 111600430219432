<template>
  <div>
    <dl class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        class="relative flex cursor-pointer items-start overflow-hidden rounded-lg bg-white px-4 shadow sm:px-6 sm:pt-6"
        :class="
          activeTab == item.value ? 'bg-blue-100 hover:bg-blue-200' : null
        "
        @click="setActiveTab(item)"
      >
        <div
          class="mr-4 inline-flex rounded-lg bg-green-50 p-2 text-green-700 ring-4 ring-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </div>
        <div>
          <dt>
            <p class="truncate text-sm font-medium text-gray-500">
              {{ item.name }}
            </p>
          </dt>
          <dd class="flex items-baseline pb-6 sm:pb-7">
            <p :id="item.value" class="text-2xl font-semibold text-gray-900">
              {{ item.stat | toCurrency }}
            </p>
          </dd>
        </div>
      </div>
    </dl>
  </div>
</template>

<script>
import { StorageService } from '@/services/storage.service';
import { mapGetters, mapActions } from 'vuex';

const stats = [];

export default {
  name: 'Stats',
  data() {
    return {
      stats,
    };
  },
  props: {
    tabs: {
      type: Array,
    },
    activeTab: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
      me: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      fetchOfficeById: 'offices/fetchOfficeById',
    }),
    setActiveTab(stat) {
      this.$emit('set-active-tab', stat);
    },
  },
  mounted() {
    this.fetchOfficeById({
      office_id: StorageService.getUser().office_id,
    }).then((res) => {
      const { attributes } = res.data.data;
      const tabs = [
        {
          name: 'Total Deposit',
          stat: attributes.deposit_balance ?? 0,
          value: 'total_deposit',
        },
        {
          name: 'Nilai Deposit Awal / Tambahan',
          stat: attributes.multiplied_deposit_balance,
          value: 'deposit_awal',
        },
        {
          name: 'Saldo Restock',
          stat: attributes.restock_balance,
          value: 'restock',
        },
        {
          name: 'Saldo Sisa Pembayaran',
          stat: attributes.overpayment_balance,
          value: 'sisa_pembayaran',
        },
        // { name: 'Saldo Utang', stat: attributes.debt_balance }, // Sementara Di Hide
      ];
      this.$emit('set-tabs', tabs);
    });
  },
};
</script>
