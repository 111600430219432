export const keydownMixin = {
  methods: {
    setEscape(callback) {
      const handleEscape = (e) => {
        if (e.key === 'Esc' || e.key === 'Escape') {
          callback(e);
        }
      };
      document.addEventListener('keydown', handleEscape);
      this.$once('hook:beforeDestroy', () => {
        document.removeEventListener('keydown', handleEscape);
      });
    },
  },
};
