import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  isLoadingDailyReport: false,
  error: null,
  paymentMethod: {},
  paymentMethods: {
    data: [],
    meta: {
      page: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getLoadingDailyReport: (state) => {
    return state.isLoadingDailyReport;
  },
  getPaymentMethods: (state) => state.paymentMethods,
  getBankByPaymentMethod: (state) => {
    return state.paymentMethod;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setLoaderDailyReport: (state, status) =>
    (state.isLoadingDailyReport = status),
  setPaymentMethod: (state, data) => (state.paymentMethod = data),
  setPaymentMethods: (state, data) => (state.paymentMethods = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchPaymentMethods({ commit }, params) {
    commit('setLoader', true);

    try {
      const res = await ApiService.get(`api/v1/payment-methods`, params);

      commit('setPaymentMethods', res.data);
      commit('setError', null);

      return res;
    } catch (err) {
      commit('setPaymentMethod', {});
      commit('setError', null);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchPaymentMethod({ commit }, { id }) {
    commit('setLoader', true);
    const params = {};
    Object.assign(params, { include: 'bank' });
    try {
      let response = await ApiService.get(`api/v1/payment-methods/${id}`, {
        params,
      });
      commit('setPaymentMethod', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setPaymentMethod', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async updatePaymentMethod(
    { dispatch },
    {
      paymentId,
      officeBankId,
      attributes,
      paymentMethodId,
      paymentMethodTypeId,
    }
  ) {
    try {
      return await ApiService.patch(
        `api/v1/payment-methods/${paymentMethodId}`,
        {
          data: {
            type: 'payment-methods',
            id: paymentMethodId,
            attributes,
            relationships: paymentId
              ? {
                  payment: {
                    data: {
                      type: 'payments',
                      id: paymentId,
                    },
                  },
                  ...(officeBankId
                    ? {
                        'office-bank': {
                          data: {
                            type: 'office-banks',
                            id: officeBankId,
                          },
                        },
                      }
                    : {}),
                  ...(paymentMethodTypeId
                    ? {
                        'payment-method-type': {
                          data: {
                            type: 'payment-method-types',
                            id: paymentMethodTypeId,
                          },
                        },
                      }
                    : {}),
                }
              : undefined,
          },
        }
      );
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
      }
    }
  },
  async createPaymentMethod(
    { dispatch, commit },
    { paymentId, officeBankId, attributes, paymentMethodTypeId }
  ) {
    try {
      const res = await ApiService.post(`api/v1/payment-methods`, {
        data: {
          type: 'payment-methods',
          attributes,
          relationships: {
            payment: {
              data: {
                type: 'payments',
                id: paymentId,
              },
            },
            ...(officeBankId
              ? {
                  'office-bank': {
                    data: {
                      type: 'office-banks',
                      id: officeBankId,
                    },
                  },
                }
              : {}),
            ...(paymentMethodTypeId
              ? {
                  'payment-method-type': {
                    data: {
                      type: 'payment-method-types',
                      id: paymentMethodTypeId,
                    },
                  },
                }
              : {}),
          },
        },
      });
      commit('setPaymentMethod', res.data);

      return res;
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
      }
    }
  },
  async softDeletePaymentMethod({ dispatch }, { paymentMethodId }) {
    try {
      const res = await ApiService.patch(
        `api/v1/payment-methods/${paymentMethodId}`,
        {
          data: {
            type: 'payment-methods',
            id: paymentMethodId,
            attributes: {
              deletedAt: new Date(),
            },
          },
        }
      );
      dispatch(
        'alert/createAlert',
        { data: 'Berhasil Menghapus Pembayaran', type: 'warning' },
        { root: true }
      );
      return res;
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
      }
    }
  },
  async deletePaymentMethod({ dispatch }, { paymentMethodId }) {
    try {
      return await ApiService.delete(
        `api/v1/payment-methods/${paymentMethodId}`
      );
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
      }
    }
  },
  async verifyPaymentMethod({ dispatch }, { paymentMethodId }) {
    try {
      return await ApiService.post(
        `api/v1/payment-methods/${paymentMethodId}/-actions/verify`
      );
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    }
  },
  async dailyReport({ commit }, params) {
    commit('setLoaderDailyReport', true);

    try {
      const res = await ApiService.get(
        `api/v1/payment-methods/-actions/daily-report`,
        {
          params,
          responseType: 'blob',
        }
      );

      return res;
    } catch (err) {
      //
    } finally {
      commit('setLoaderDailyReport', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
