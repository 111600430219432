import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  returs: {
    data: [],
    meta: {
      page: {},
    },
  },
  retur: {
    data: {
      attributes: {
        origin_code: null,
        cancel_warehouse_description: null,
      },
      relationships: {
        'destination-office': {
          data: {
            id: '',
          },
        },
      },
    },
    included: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getReturs: (state) => {
    return state.returs;
  },
  getRetur: (state) => {
    return state.retur;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setReturs: (state, data) => (state.returs = data),
  setRetur: (state, data) => (state.retur = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchReturs({ commit, dispatch }, { params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get('api/v1/returs', {
        params,
      });
      
      commit('setReturs', response.data);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setReturs', {
        data: [],
        meta: { page: {} },
      });
    }
    commit('setLoader', false);
  },
  async fetchRetur({ commit, dispatch }, { id, ...others }) {
    commit('setLoader', true);

    try {
      let response = await ApiService.get('api/v1/returs/' + id, {
        params: { ...others }
      });
      commit('setRetur', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setReturs', {
        data: [],
        meta: { page: {} },
      });
    }
    commit('setLoader', false);
  },
  async createRetur({ commit, dispatch }, { payload, params }) {
    commit('setLoader', true);

    try {
      let response = await ApiService.post('api/v1/returs', payload, { params });

      commit('setRetur', response.data);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors[0].detail);
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors);
      }
    }
    commit('setLoader', false);
  },
  async validateRetur({ commit, dispatch }, { id }) {
    commit('setLoader', true);
    try {
      const response = await ApiService.patch(`api/v1/returs/${id}/-actions/validate-retur`);

      commit('setRetur', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors[0].detail);
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors);
      }
    }
    commit('setLoader', false);
  },
  async setReturPrice({ commit, dispatch }, { id, payload }) {
    commit('setLoader', true);
    try {
      const response = await ApiService.patch(`api/v1/returs/${id}/-actions/set-price`, payload);

      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors[0].detail);
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors);
      }
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
