import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  expeditions: {
    data: [],
    meta: {
      page: {},
    },
  },
  expedition: {},
  expeditionsByOffice: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getExpeditions: (state) => {
    return state.expeditions;
  },
  getExpedition: (state) => {
    return state.expedition;
  },
  getExpeditionsByOffice: (state) => {
    return state.expeditionsByOffice;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setExpeditions: (state, data) => (state.expeditions = data),
  setExpedition: (state, data) => (state.expedition = data),
  setExpeditionsByOffice: (state, data) => (state.expeditionsByOffice = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchExpeditions({ commit }, { params } = {}) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get('api/v1/expeditions', {
        params,
      });
      commit('setExpeditions', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setExpeditions', { data: [], meta: { page: {} } });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchExpedition({ commit }, { id, params } = {}) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(`api/v1/expeditions/${id}`, {
        params,
      });
      commit('setExpedition', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setExpedition', { data: [], meta: { page: {} } });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async createExpedition({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/expeditions', payload);
      commit('setExpedition', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setExpedition', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async updateExpedition({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/expeditions/' + payload.data.id,
        payload
      );
      commit('setExpedition', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
