import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  returDetails: {
    data: [],
    meta: {
      page: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getReturDetails: (state) => {
    return state.returDetails;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setReturDetails: (state, data) => (state.returDetails = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchReturDetailsByRetur({ commit, dispatch }, { returId, params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get(`api/v1/returs/${returId}/details`, {
        params,
      });

      commit('setReturDetails', response.data);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setReturDetails', {
        data: [],
        meta: { page: {} },
      });
    }
    commit('setLoader', false);
  },
  async createReturDetail({ commit, dispatch }, { payload, params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.post(
        'api/v1/retur-details',
        payload,
        params
      );

      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async updateReturDetail({ commit, dispatch }, { id, payload, params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.patch(
        `api/v1/retur-details/${id}`,
        payload,
        params
      );

      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async deleteReturDetail({ commit, dispatch }, { id }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.delete(`api/v1/retur-details/${id}`);

      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
