<template>
  <div>
    <div
      class="relative flex h-full flex-col gap-4 overflow-y-auto border-r bg-white dark:bg-gray-800"
      style="overflow: inherit"
    >
      <div
        @click="$router.push('/')"
        style="height: 4rem"
        class="flex w-full cursor-pointer items-center justify-center border-b bg-gray-50"
      >
        <div class="flex items-center gap-2">
          <NasaLogo class="mx-auto h-8" alt="Logo Nasa" />
        </div>
      </div>
      <div class="px-4">
        <ul class="space-y-2 text-sm font-medium">
          <li
            v-for="item in menu"
            :key="item.id"
            class="relative cursor-pointer"
          >
            <Recursive
              :key="item.id"
              :expanding="false"
              :expandable="false"
              :selected-menu="selectedMenu"
              :menu="item"
              :depth="0"
              @click="handleChangeMenu"
              :is-active="isActive(item)"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Recursive from './recursive.vue';
import NasaLogo from '@/assets/images/logo.svg';
import {
  defaultLink,
  subfolderActive,
  activeColor,
  bgColor,
} from '@/services/themes.service';
import { mapGetters } from 'vuex';

export default {
  name: 'RootMenuSidebar',
  components: {
    Recursive,
    NasaLogo,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
    selectedMenu: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userOfficeCategory: 'auth/getUserOfficeCategory',
    }),
  },
  methods: {
    defaultLink,
    subfolderActive,
    activeColor,
    bgColor,
    handleChangeMenu(menu) {
      this.$emit('set-menu', menu);
    },
    isActive(item) {
      return localStorage.getItem('menu') === item.attributes.name;
    },
  },
};
</script>
