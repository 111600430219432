import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  areas: {
    data: [],
    meta: {
      page: {
        total: 0,
        perPage: 0,
        currentPage: 0,
      },
    },
  },
  areaCities: {
    data: [],
    meta: {
      page: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getAreas: (state) => {
    return state.areas;
  },
  getAreaCities: (state) => {
    return state.areaCities;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setAreas: (state, data) => (state.areas = data),
  setAreaCities: (state, data) => (state.areaCities = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchAreas(
    { commit },
    { keyword, pageNumber, pageSize, search, fields, ...others } = {}
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (keyword !== '') filter['code'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    Object.assign(params, { sort: '-createdAt' });
    try {
      let response = await ApiService.get('api/v1/areas', {
        params,
      });
      commit('setAreas', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setAreas', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchAreaCities({ commit }, { areaId, pageNumber, pageSize, search }) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    let page = [];

    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;

    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, { include: 'province' });

    Object.assign(params, { sort: '-createdAt' });

    try {
      let response = await ApiService.get(`api/v1/areas/${areaId}/cities`, {
        params,
      });
      commit('setAreaCities', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setAreaCities', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
