import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  simpleOffices: {
    data: [],
    meta: {
      page: {
        total: 1,
        currentPage: 1,
        perPage: 1,
      },
    },
  },
  uplineSimpleOffices: {
    data: [],
    meta: {
      page: {
        total: 1,
        currentPage: 1,
        perPage: 1,
      },
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getSimpleOffices: (state) => {
    return state.simpleOffices;
  },
  getUplineSimpleOffices: (state) => {
    return state.uplineSimpleOffices;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setSimpleOffices: (state, data) => (state.simpleOffices = data),
  setUplineSimpleOffices: (state, data) => (state.uplineSimpleOffices = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchSimpleOffices({ commit }, params) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get('api/v1/offices', {
        params,
      });

      commit('setSimpleOffices', response.data);
      commit('setError', null);

      return response;
    } catch (error) {
      commit('setSimpleOffices', {
        data: [],
        meta: {
          page: {},
        },
      });

      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchSimpleOfficesByUpline({ commit }, config) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get(
        `api/v1/offices/${config.uplineId}/simple-office-downlines`,
        {
          params: config.params,
        }
      );

      commit('setUplineSimpleOffices', response.data);
      commit('setError', null);

      return response;
    } catch (error) {
      commit('setUplineSimpleOffices', {
        data: [],
        meta: {
          page: {},
        },
      });

      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
