import ApiService from '@/services/api.service';

export function makeApiRequest(
  { commit, dispatch },
  mutationType,
  apiEndpoint
) {
  let loaderSet = false;
  const setLoader = (value) => {
    if (!loaderSet) {
      commit('setLoader', value);
      loaderSet = true;
    }
  };

  const executeRequest = async (method, params = {}) => {
    setLoader(true);

    try {
      const response = await ApiService[method](apiEndpoint, params);
      commit(mutationType, response.data);
      commit('setError', null);
      const successMessages = {
        post: 'Berhasil Menambahkan Data',
        patch: 'Berhasil Mengubah Data',
        delete: 'Berhasil Menghapus Data',
      };

      const successMessage = successMessages[method];

      if (successMessage) {
        dispatch('alert/createAlert', { data: successMessage }, { root: true });
      }
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data.errors },
        { root: true }
      );
      commit('setError', error.response.data.errors);
    } finally {
      setLoader(false);
    }
  };

  return {
    get: (params) => executeRequest('get', params),
    post: (params) => executeRequest('post', params),
    patch: (params) => executeRequest('patch', params),
    delete: (params) => executeRequest('delete', params),
  };
}
