import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  topupTypes: {
    data: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getTopupTypes: (state) => {
    return state.topupTypes;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setTopupTypes: (state, data) => (state.topupTypes = data),
};

const actions = {
  async fetchTopupTypes({ commit }) {
    commit('setLoader', true);
    let response = await ApiService.get('api/v1/topup-types');
    commit('setTopupTypes', response.data);
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
