<template>
  <div class="relative sm:w-12" v-if="visible">
    <aside
      id="sidebar"
      :style="false ? '' : 'width: fit-content'"
      class="fixed z-40 h-screen w-64 -translate-x-full shadow-md transition-all sm:translate-x-0"
      aria-label="Sidebar"
    >
      <!-- <SidebarHeader :expanding="expanding" /> -->
      <div class="flex h-full">
        <RootMenuSidebar
          :menu="
            getMenus.data.filter((menu) => {
              return !menu.attributes.parent_id;
            })
          "
          :selected-menu="selectedMenu"
          @set-menu="setMenu"
        />
        <ChildMenuSidebar
          :menu="menu"
          :expanding="expanding"
          @search="handleSearchMenu"
          :query="query"
        />
      </div>
      <SidebarButton @click="toggleExpand" />
    </aside>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SidebarButton from './button.vue';
import RootMenuSidebar from './root-menu.vue';
import ChildMenuSidebar from './child-menu.vue';
// import SidebarHeader from './header.vue';

export default {
  name: 'Sidebar',
  components: {
    ChildMenuSidebar,
    SidebarButton,
    RootMenuSidebar,
    // SidebarHeader
  },
  data() {
    return {
      visible: true,
      expanding: false,
      selectedMenu: '',
      selectedRootMenu: localStorage.getItem('menu') ?? 'Penjualan',
      query: {
        filter: {
          search: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getMenus: 'menus/getMenus',
      getRolesByUser: 'users/getRolesByUser',
      getOfficeByOfficesCategories:
        'office_categories/getOfficeByOfficesCategories',
      user: 'auth/getUser',
      userOfficeCategory: 'auth/getUserOfficeCategory',
      menu: 'auth/getMenu',
    }),
    menu() {
      if (this.query.filter.search?.length > 1) {
        const query = this.query.filter.search
          .toLowerCase()
          .split(' ')
          .join('');
        const result = this.getMenus.data.filter((menu) => {
          return menu.attributes.name
            .toLowerCase()
            .split(' ')
            .join('')
            .includes(query);
        });
        return result;
      }
      const root = this.getMenus.data.find((it) => {
        return it.attributes.name == this.selectedRootMenu;
      });
      return this.getMenus.data.filter(
        (it) => it.attributes.parent_id == root.id
      );
    },
  },
  methods: {
    handleSearchMenu(text) {
      this.query.filter.search = text;
    },
    setSelectedMenu(menu) {
      this.selectedMenu = menu.id;
    },
    toggleExpand() {
      this.expanding = !this.expanding;
      localStorage.setItem(
        'settings',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('settings')),
          expanding: this.expanding,
        })
      );
    },
    setMenu(menu) {
      localStorage.setItem('menu', menu.attributes.name);
      this.selectedRootMenu = menu.attributes.name;
    },
  },
  created() {
    const settings = JSON.parse(localStorage.getItem('settings'));
    if (settings) {
      this.expanding = settings.expanding;
    }
  },
};
</script>
