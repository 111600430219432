import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  address: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getAddress: (state) => {
    return state.address;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setAddress: (state, data) => (state.address = data),
};

const actions = {
  async createAddress({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/addresses', payload);
      commit('setAddress', response.data);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async updateAddress({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/addresses/' + payload.data.id,
        payload
      );
      commit('setAddress', response.data);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
