import ApiService from '@/services/api.service';
import { makeApiRequest } from './utils';

const state = {
  isLoading: false,
  error: null,
  roles: {
    data: [],
    meta: {
      page: {},
    },
  },
  role: {
    data: {},
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getRoles: (state) => {
    return state.roles;
  },
  getRole: (state) => {
    return state.role;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setRoles: (state, data) => (state.roles = data),
  setRole: (state, data) => (state.role = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchRoles({ commit }, { pageNumber, pageSize, search, ...others }) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber ?? 1;
    if (pageSize) page['size'] = pageSize ?? 5;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    try {
      let response = await ApiService.get(`api/v1/roles`, {
        params,
      });
      commit('setRoles', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      commit('setRoles', {
        data: [],
        meta: { page: {} },
      });
    }
    commit('setLoader', false);
  },
  async fetchRole(context, { id, ...params }) {
    return makeApiRequest(context, 'setRole', `api/v1/roles/${id}`).get(params);
  },
  async createRole(context, payload) {
    return makeApiRequest(context, 'setRole', `api/v1/roles`).post(payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
