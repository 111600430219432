const LayoutDefault = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/layouts/LayoutDefault.vue'
  );
const LayoutData = () =>
  import(/* webpackChunkName: "admin-penjualan" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/dashboard/Index.vue'
  );
const TransaksiPenjualan = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/transaksi-penjualan/Index.vue'
  );
const TambahTransaksiPenjualan = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/transaksi-penjualan/TambahPenjualan.vue'
  );
const FakturSC = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/faktur-sc/Index.vue'
  );
const TambahFakturSC = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/faktur-sc/Tambah.vue'
  );
const Laporan = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/laporan/Index.vue'
  );
const LaporanPenjualanKP = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/validasi/laporan-penjualan-kp/Index.vue'
  );
const LaporanPenjualanSC = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/validasi/laporan-penjualan-sc/Index.vue'
  );

const LaporanPenjualanHarian = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/laporan/LaporanPenjualanHarian.vue'
  );
const LaporanJumlahBarang = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/laporan/LaporanJumlahBarang.vue'
  );

const ReturStockist = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/retur/stockist/Index.vue'
  );

const TambahReturStockist = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/retur/stockist/Tambah.vue'
  );
const ReturSC = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/retur/stockist-center/Index.vue'
  );

const TambahReturSC = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/retur/stockist-center/Tambah.vue'
  );
const ReturKP = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/retur/pelayanan/Index.vue'
  );

const TambahReturKP = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/retur/pelayanan/Tambah.vue'
  );
const ReturSupplier = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/retur/supplier/Index.vue'
  );

const TambahReturSupplier = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/retur/supplier/Tambah.vue'
  );

const ValidasiSaldoReturStockist = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/validasi-saldo-retur/stockist/Index.vue'
  );

const SetPriceReturStockist = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/validasi-saldo-retur/stockist/SetPrice.vue'
  );

const ValidasiSaldoReturStockistCenter = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/validasi-saldo-retur/stockist-center/Index.vue'
  );

const SetPriceReturStockistCenter = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/validasi-saldo-retur/stockist-center/SetPrice.vue'
  );
const TopupDepositSc = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/topup/deposit-sc/Index.vue'
  );
const TambahTopupDepositSc = () =>
  import(
    /* webpackChunkName: "admin-penjualan" */ '@/views/pusat/penjualan/topup/deposit-sc/Tambah.vue'
  );

let root = '/penjualan';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Penjualan Pusat',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/laporan',
    component: LayoutData,
    meta: {
      breadcrumb: 'Laporan',
    },
    children: [
      {
        path: '',
        component: Laporan,
        meta: {
          auth: true,
          title: 'Laporan | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Laporan Penjualan',
        },
      },
      {
        path: 'harian/',
        component: LaporanPenjualanHarian,
        meta: {
          auth: true,
          title: 'Laporan Penjualan Harian | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Laporan Penjualan Harian',
        },
      },
      {
        path: 'jumlah-barang/',
        component: LaporanJumlahBarang,
        meta: {
          auth: true,
          title: 'Laporan Jumlah Penjualan Barang | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Laporan Jumlah Penjualan Barang',
        },
      },
    ],
  },
  {
    path: root + '/transaksi-penjualan/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Transaksi Penjualan',
    },
    children: [
      {
        path: '',
        component: TransaksiPenjualan,
        meta: {
          auth: true,
          title: 'Transaksi Penjualan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-transaksi-penjualan',
        component: TambahTransaksiPenjualan,
        meta: {
          auth: true,
          title: 'Tambah Transaksi Penjualan | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Transaksi Penjualan',
        },
      },
      {
        path: ':id',
        component: TambahTransaksiPenjualan,
        meta: {
          auth: true,
          title: 'Edit Transaksi Penjualan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/retur/stockist',
    component: LayoutData,
    meta: {
      breadcrumb: 'Retur Penjualan',
    },
    children: [
      {
        path: '',
        component: ReturStockist,
        name: 'index.retur.stockist',
        meta: {
          auth: true,
          title: 'Retur Penjualan ke Stockist | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-retur',
        component: TambahReturStockist,
        name: 'create.retur.stockist',
        meta: {
          auth: true,
          title: 'Tambah Retur Penjualan ke Stockist | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Retur Penjualan',
        },
      },
      {
        path: 'tambah-retur/:id',
        component: TambahReturStockist,
        name: 'edit.retur.stockist',
        meta: {
          auth: true,
          title: 'Edit Retur Penjualan ke Stockist | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/retur/stockist-center',
    component: LayoutData,
    meta: {
      breadcrumb: 'Retur Penjualan',
    },
    children: [
      {
        path: '',
        component: ReturSC,
        name: 'index.retur.stockist-center',
        meta: {
          auth: true,
          title: 'Retur Penjualan ke Stockist Center | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-retur',
        component: TambahReturSC,
        name: 'create.retur.stockist-center',
        meta: {
          auth: true,
          title: 'Tambah Retur Penjualan ke Stockist Center | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Retur Penjualan',
        },
      },
      {
        path: 'tambah-retur/:id',
        component: TambahReturSC,
        name: 'edit.retur.stockist-center',
        meta: {
          auth: true,
          title: 'Edit Retur Penjualan ke Stockist Center | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/retur/kantor-pelayanan',
    component: LayoutData,
    meta: {
      breadcrumb: 'Retur Penjualan',
    },
    children: [
      {
        path: '',
        component: ReturKP,
        name: 'index.retur.pelayanan',
        meta: {
          auth: true,
          title: 'Retur Penjualan ke Kantor Pelayanan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-retur',
        component: TambahReturKP,
        name: 'create.retur.pelayanan',
        meta: {
          auth: true,
          title: 'Tambah Retur Penjualan ke Kantor Pelayanan | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Retur Penjualan',
        },
      },
      {
        path: 'tambah-retur/:id',
        component: TambahReturKP,
        name: 'edit.retur.pelayanan',
        meta: {
          auth: true,
          title: 'Edit Retur Penjualan ke Kantor Pelayanan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/retur/supplier',
    component: LayoutData,
    meta: {
      breadcrumb: 'Retur Penjualan',
    },
    children: [
      {
        path: '',
        component: ReturSupplier,
        name: 'index.retur.supplier',
        meta: {
          auth: true,
          title: 'Retur Penjualan ke Supplier | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-retur',
        component: TambahReturSupplier,
        name: 'create.retur.supplier',
        meta: {
          auth: true,
          title: 'Tambah Retur Penjualan ke Supplier | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Retur Penjualan',
        },
      },
      {
        path: 'tambah-retur/:id',
        component: TambahReturSupplier,
        name: 'edit.retur.supplier',
        meta: {
          auth: true,
          title: 'Edit Retur Penjualan ke Supplier | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/validasi/',
    component: LayoutData,
    children: [
      {
        path: 'laporan-penjualan-kp',
        component: LaporanPenjualanKP,
        meta: {
          auth: true,
          title: 'Laporan Penjualan KP | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Validasi Laporan Penjualan KP',
        },
      },
      {
        path: 'laporan-penjualan-sc',
        component: LaporanPenjualanSC,
        meta: {
          auth: true,
          title: 'Laporan Penjualan SC | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Validasi Laporan Penjualan SC',
        },
      },
    ],
  },
  {
    path: root + '/faktur-sc/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Restock SC',
    },
    children: [
      {
        path: '',
        component: FakturSC,
        meta: {
          auth: true,
          title: 'Restock SC | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah',
        component: TambahFakturSC,
        meta: {
          auth: true,
          title: 'Tambah Restock SC | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Restock SC',
        },
      },
      {
        path: ':id',
        component: TambahFakturSC,
        meta: {
          auth: true,
          title: 'Tambah Restock SC | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Restock SC',
        },
      },
    ],
  },
  {
    path: root + '/validasi-saldo-retur/',
    component: LayoutData,
    children: [
      {
        path: 'retur-dari-stockist/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Validasi Saldo Retur',
        },
        children: [
          {
            path: '',
            name: 'validasi-saldo-retur.retur-dari-stockist',
            component: ValidasiSaldoReturStockist,
            meta: {
              auth: true,
              title: 'Validasi Saldo Retur dari Stockist | Penjualan',
              layout: LayoutDefault,
            },
            props: {
              title: 'Validasi Saldo Retur dari Stockist',
            },
          },
          {
            path: ':id',
            name: 'validasi-saldo-retur.retur-dari-stockist.set-price',
            component: SetPriceReturStockist,
            meta: {
              auth: true,
              title: 'Validasi Saldo Retur dari Stockist | Penjualan',
              layout: LayoutDefault,
              breadcrumb: 'Validasi Saldo Retur dari Stockist',
            },
          },
        ],
      },
      {
        path: 'retur-dari-stockist-center/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Validasi Saldo Retur',
        },
        children: [
          {
            path: '',
            name: 'validasi-saldo-retur.retur-dari-stockist-center',
            component: ValidasiSaldoReturStockistCenter,
            meta: {
              auth: true,
              title: 'Validasi Saldo Retur dari Stockist Center | Penjualan',
              layout: LayoutDefault,
            },
            props: {
              title: 'Validasi Saldo Retur dari Stockist Center',
            },
          },
          {
            path: ':id',
            name: 'validasi-saldo-retur.retur-dari-stockist-center.set-price',
            component: SetPriceReturStockistCenter,
            meta: {
              auth: true,
              title: 'Validasi Saldo Retur dari Stockist Center | Penjualan',
              layout: LayoutDefault,
              breadcrumb: 'Validasi Saldo Retur dari Stockist Center',
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/topup/deposit-sc/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: TopupDepositSc,
        name: 'penjualan.topup.deposit-sc',
        meta: {
          auth: true,
          title: 'Topup Saldo Deposit SC | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Topup Saldo Saldo Deposit SC',
        },
      },
      {
        path: 'tambah',
        component: TambahTopupDepositSc,
        name: 'penjualan.topup.deposit-sc.tambah',
        meta: {
          auth: true,
          title: 'Tambah Saldo Saldo Deposit SC | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Saldo Saldo Deposit SC',
        },
      },
    ],
  },
];
