import ApiService from '@/services/api.service';

const root = 'api/v1/offices';

const state = {
  isLoading: false,
  isLoadingExport: false,
  isLoadingOrderPaymentReportMeta: false,
  isLoadingOrderPaymentHistory: false,
  error: null,
  offices: {
    data: [],
    meta: {
      page: {
        total: 1,
        currentPage: 1,
        perPage: 1,
      },
    },
  },
  saldo: {
    data: [],
    meta: {
      page: {},
    },
  },
  officesRevenue: {
    data: [],
  },
  office: {},
  isLoadingExportDbf: false,
  orderPaymentReports: {
    meta: {
      total_order_final_price: 0,
      total_payment_amount: 0,
      total_office_balance: 0,
    },
    data: {
      current_page: 1,
      data: [],
    },
  },
  orderPaymentHistory: [],
  orderPaymentReportMeta: {
    total_order_final_price: 0,
    total_payment_amount: 0,
    total_office_balance: 0,
    grand_total_payment_amount: 0,
  },
  requiredProductToCreateMember: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getLoadingOrderPaymentReportMeta: (state) => {
    return state.isLoadingOrderPaymentReportMeta;
  },
  getLoadingOrderPaymentHistory: (state) => {
    return state.isLoadingOrderPaymentHistory;
  },
  getOffices: (state) => {
    return state.offices;
  },
  getOffice: (state) => {
    return state.office;
  },
  getError: (state) => {
    return state.error;
  },
  getSaldo: (state) => {
    return state.saldo;
  },
  getLoadingExportDbf: (state) => {
    return state.isLoadingExportDbf;
  },
  getOfficesRevenue: (state) => state.officesRevenue,
  getOrderPaymentReports: (state) => state.orderPaymentReports,
  getOrderPaymentReportMeta: (state) => state.orderPaymentReportMeta,
  getOrderPaymentHistory: (state) => state.orderPaymentHistory,
  getRequiredProductToCreateMember: (state) =>
    state.requiredProductToCreateMember,
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setLoaderOrderPaymentReportMeta: (state, status) =>
    (state.isLoadingOrderPaymentReportMeta = status),
  setLoaderOrderPaymentHistory: (state, status) =>
    (state.isLoadingOrderPaymentHistory = status),
  setOffices: (state, data) => (state.offices = data),
  setOffice: (state, data) => (state.office = data),
  setSaldo: (state, data) => (state.saldo = data),
  setError: (state, data) => (state.error = data),
  setLoaderExportDbf: (state, status) => (state.isLoadingExportDbf = status),
  setLoaderExport: (state, status) => (state.isLoadingExport = status),
  setOfficesRevenue: (state, officesRevenue) =>
    (state.officesRevenue = officesRevenue),
  setOrderPaymentReports: (state, orderPaymentReports) =>
    (state.orderPaymentReports = orderPaymentReports),
  setOrderPaymentReportMeta: (state, orderPaymentReportMeta) =>
    (state.orderPaymentReportMeta = orderPaymentReportMeta),
  setOrderPaymentHistory: (state, orderPaymentHistory) =>
    (state.orderPaymentHistory = orderPaymentHistory),
  setRequiredProductToCreateMember: (state, requiredProductToCreateMember) =>
    (state.requiredProductToCreateMember = requiredProductToCreateMember),
};

const actions = {
  async fetchOfficesByCategory({ commit, rootGetters }, config) {
    commit('setLoader', true);

    try {
      const officeCategory = rootGetters[
        'office_categories/getAvailableOfficeCategories'
      ].find((category) => category.attributes.name === config.category);

      const res = await ApiService.get(
        `api/v1/office-categories/${officeCategory.id}/offices`,
        {
          params: config.params,
        }
      );

      return res.data;
    } catch (err) {
      // console.log(err)
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchOffices(
    { commit, rootGetters },
    {
      pageNumber,
      pageSize,
      office_type,
      search,
      keyword,
      fields,
      office_category_id,
      office_category,
      ...others
    }
  ) {
    commit('setLoader', true);
    const office_categories =
      rootGetters['office_categories/getAvailableOfficeCategories'];

    if (office_category) {
      office_category_id = office_categories.filter(
        (it) => it.attributes.name === office_category
      )[0].id;
    }
    const params = { ...others };
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (search && search !== '') filter['search'] = search;
    if (office_type && office_type !== '') filter['office_type'] = office_type;
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    try {
      if (params['filter[search]'] && params['filter[search]'].length < 3) {
        throw new Error('Pencarian tidak boleh kurang dari 3 huruf');
      }
      let response = await ApiService.get(
        office_category_id
          ? `api/v1/office-categories/${office_category_id}/offices`
          : 'api/v1/offices',
        {
          params,
        }
      );
      commit('setOffices', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOffices', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async updateOffice({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/offices/' + payload.data.id + '?include=addresses',
        payload
      );

      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOffice', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchOfficeById({ commit }, { office_id, params }) {
    commit('setLoader', true);

    try {
      let response = await ApiService.get(`${root}/${office_id}`, { params });
      commit('setOffice', response.data);
      return response;
    } catch (error) {
      commit('setOffice', { data: [] });
    } finally {
      commit('setLoader', false);
    }
  },

  async addExpeditionToOffice({ commit }, { office_id, payload }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/offices/' + office_id + '/relationships/office-expeditions',
        payload
      );
      commit('setOffice', response.data);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOffice', {});
    }
    commit('setLoader', false);
  },

  async promoteToStockist({ commit }, { payload, id }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        `api/v1/offices/${id}/-actions/promoteToStockist`,
        payload
      );

      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOffice', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },

  async promoteToSC({ commit, dispatch }, { payload, id }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        `api/v1/offices/${id}/-actions/promoteToSC`,
        payload
      );
      dispatch(
        'alert/createAlert',
        { data: 'Berhasil dipromote ke SC' },
        { root: true }
      );
      commit('setOffice', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOffice', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },

  async saldoHistory(
    { commit },
    {
      office_id,
      balance_type,
      trx_type,
      fromDate,
      toDate,
      fields,
      include,
      ...others
    }
  ) {
    commit('setLoader', true);

    const params = {
      'filter[balance_type]': balance_type,
      'filter[trx_type]': trx_type,
      'filter[fromDate]': fromDate,
      'filter[toDate]': toDate,
      include,
      ...others,
    };

    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    try {
      let response = await ApiService.get(
        `api/v1/offices/${office_id}/balance-trxes`,
        { params }
      );
      commit('setSaldo', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setSaldo', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async fetchOfficeCategory(commit, { office_id, params }) {
    try {
      const res = await ApiService.get(
        `api/v1/offices/${office_id}/office-category`,
        { params }
      );

      return res;
    } catch (err) {
      //
    }
  },
  async exportDbf(
    { commit },
    { bonus_month, created_month, from_date, to_date, year }
  ) {
    commit('setLoaderExportDbf', true);

    try {
      const res = await ApiService.get(`api/v1/offices`, {
        params: {
          exportDbf: true,
          ['filter[bonus_month]']: bonus_month,
          ['filter[created_month]']: created_month,
          ['filter[from_date]']: from_date,
          ['filter[to_date]']: to_date,
          ['filter[createdYear]']: year,
        },
        responseType: 'blob',
      });

      return res;
    } catch (err) {
      //
    } finally {
      commit('setLoaderExportDbf', false);
    }
  },
  async fetchOfficesRevenue({ commit }, params) {
    commit('setLoader', true);

    try {
      const res = await ApiService.get(
        'api/v1/offices/-actions/getOfficesRevenue',
        { params }
      );

      commit('setOfficesRevenue', res.data);

      return res;
    } catch (err) {
      //
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchOrderPaymentReports({ commit }, params) {
    commit('setLoader', true);

    try {
      const res = await ApiService.get(
        'api/v1/offices/-actions/get-order-payment-report',
        { params }
      );

      commit('setOrderPaymentReports', res.data);

      return res;
    } catch (err) {
      //
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchOrderPaymentReportMeta({ commit }, params) {
    commit('setLoaderOrderPaymentReportMeta', true);

    try {
      const res = await ApiService.get(
        'api/v1/offices/-actions/get-order-payment-report-meta',
        { params }
      );

      commit('setOrderPaymentReportMeta', res.data);

      return res;
    } catch (err) {
      //
    } finally {
      commit('setLoaderOrderPaymentReportMeta', false);
    }
  },
  async fetchOrderPaymentHistory({ commit }, params) {
    commit('setLoaderOrderPaymentHistory', true);

    try {
      const res = await ApiService.get(
        'api/v1/offices/-actions/get-order-payment-history',
        { params }
      );

      commit('setOrderPaymentHistory', res.data);

      return res;
    } catch (err) {
      //
    } finally {
      commit('setLoaderOrderPaymentHistory', false);
    }
  },
  async fetchRequiredProductToCreateMember({ commit }) {
    commit('setLoader', true);

    try {
      const res = await ApiService.get(
        'api/v1/offices/-actions/get-required-product-to-create-member'
      );

      commit('setRequiredProductToCreateMember', res.data);
    } catch (err) {
      //
    } finally {
      commit('setLoader', false);
    }
  },
  async updateRequiredProductToCreateMember({ commit }, { productId }) {
    commit('setLoader', true);

    try {
      const res = await ApiService.patch(
        'api/v1/offices/-actions/update-required-product-to-create-member',
        {
          product_uuid: productId,
        }
      );

      commit('setRequiredProductToCreateMember', res.data);

      return res.data;
    } catch (err) {
      //
    } finally {
      commit('setLoader', false);
    }
  },
  async exportMitra({ commit }) {
    commit('setLoaderExport', true);
    try {
      return await ApiService.get(`api/v1/offices/-actions/exportMitra`);
    } catch (error) {
      commit('setOffices', { data: [] }); //ss
    } finally {
      commit('setLoaderExport', false);
    }
  },
  async downloadMitra({ commit }) {
    commit('setLoaderExport', true);
    try {
      return await ApiService.get(`api/v1/offices/-actions/downloadMitra`);
    } catch (error) {
      commit('setOffices', { data: [] }); //ss
    } finally {
      commit('setLoaderExport', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
