<template>
  <div
    :class="upHere && 'move'"
    @mouseover="upHere = true"
    @mouseleave="upHere = false"
    id="toast-message-cta"
    class="fixed z-10 hidden w-full max-w-xs rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
    role="alert"
  >
    <div>
      <div class="flex">
        <img
          class="h-8 w-8 rounded-full shadow-lg"
          src="https://randomuser.me/api/portraits/men/15.jpg"
          alt="Jese Leos image"
        />
        <div class="ml-3 text-sm font-normal">
          <span class="mb-1 text-sm font-semibold text-gray-900 dark:text-white"
            >Kantor Pusat</span
          >
          <div class="mb-2 text-sm font-normal">
            Tutup buku akan segera dimulai pada {{ countdown }}.
          </div>
          <a
            href="#"
            class="focus:outline-none inline-flex rounded-lg bg-blue-600 p-2 text-center text-xs font-medium text-white hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
            >Reply</a
          >
        </div>
        <button
          type="button"
          class="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
          data-dismiss-target="#toast-message-cta"
          aria-label="Close"
        >
          <span class="sr-only">Close</span>
          <svg
            aria-hidden="true"
            class="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Countdown',
  data() {
    return {
      countdown: '', // set initial countdown value
      upHere: false,
    };
  },
  methods: {
    startCountdown() {
      const countDownDate = new Date('2023-05-08T00:00:00Z').getTime();
      const countdownInterval = setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.countdown =
          days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
        if (distance < 0) {
          clearInterval(countdownInterval); // clear interval when countdown reaches 0
          this.countdown = 'Countdown has ended';
        }
      }, 1000);
    },
  },
  created() {
    this.startCountdown();
  },
};
</script>
<style>
#toast-message-cta {
  right: -260px;
  bottom: -75px;
  z-index: 100;
  transition: transform 180ms ease-in-out;
}

.move {
  transform: translateX(-260px) translateY(-75px);
}
</style>
