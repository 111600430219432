import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: '',
  suppliers: {
    data: [],
    meta: {
      page: {},
    },
  },
  supplier: {
    data: {
      attributes: {},
    },
  },
  products: {
    data: [],
    meta: {
      page: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getSuppliers: (state) => {
    return state.suppliers;
  },
  getSupplier: (state) => {
    return state.supplier;
  },
  getSupplierProducts: (state) => {
    return state.products;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setSuppliers: (state, data) => (state.suppliers = data),
  setSupplier: (state, data) => (state.supplier = data),
  setSupplierProducts: (state, data) => (state.products = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchSupplier(
    { commit, rootGetters },
    { keyword, pageNumber, pageSize, search, ...others }
  ) {
    commit('setLoader', true);
    const office_categories =
      rootGetters['office_categories/getAvailableOfficeCategories'];
    let supplier_office_category_id = office_categories.filter(function (el) {
      return el.attributes.name === 'Supplier';
    });
    const params = {};
    let filter = [];
    let page = [];
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    try {
      let response = await ApiService.get(
        'api/v1/office-categories/' +
          supplier_office_category_id[0].id +
          '/offices',
        {
          params: {
            ...params,
            ...others,
          },
        }
      );
      commit('setSuppliers', response.data);
      return response;
    } catch (error) {
      commit('setSuppliers', { data: [] });
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchSupplierById({ commit }, { id, params }) {
    commit('setLoader', true);
    try {
      const response = await ApiService.get(`api/v1/offices/${id}`, {
        params,
      });
      commit('setSupplier', response.data);
      return response;
    } catch (error) {
      commit('setSupplier', {
        data: {
          attributes: {},
        },
      });
    } finally {
      commit('setLoader', false);
    }
  },
  async createSupplier({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/offices', payload);
      commit('setSupplier', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setSupplier', {});
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async updateSupplier({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/offices/' + payload.data.id,
        payload
      );
      commit('setSupplier', response.data);
      commit('setError', null);
      commit('setLoader', false);
      dispatch(
        'alert/createAlert',
        { data: 'Data Berhasil Diupdate' },
        { root: true }
      );

      return response;
    } catch (error) {
      commit('setSupplier', {});
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchSupplierProducts({ commit, dispatch }, { id, params }) {
    commit('setLoader', true);

    try {
      const res = await ApiService.get(`api/v1/offices/${id}/products`, {
        params,
      });

      commit('setSupplierProducts', res.data);
      commit('setError', null);

      return res.data;
    } catch (err) {
      commit('setError', err);
      dispatch('alert/createAlert', { data: err }, { root: true });
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
