<template>
  <form ref="base-form" novalidate v-on:submit.prevent="handleSubmit">
    <slot :errors="errors" :validate="validate" :perform-validate="performValidate" />
  </form>
</template>

<script>
export default {
  name: 'BaseForm',
  props: {
    form: Object,
    nullable: Object,
  },
  emits: ['submit'],
  data: function () {
    return {
      errors: this.deepCopyObjectAndReplaceNull(this.form),
    };
  },
  methods: {
    validate() {
      // if theres null return true
      this.clearErrors();
      this.validateFormInputs(this.errors, this.form, this.nullable);
      if (this.checkObjectForNull(this.errors)) {
        const id = this.getFirstNonNullKey(this.errors);
        const element = document.getElementById(id);
        if (element) {
          element.focus();
          // this.$emit('error', { id, element });
          return element;
        } else {
          new Error(`element with id '${id}' is not found'`);
          return id;
        }
      }
    },
    performValidate() {
      this.clearErrors();
      this.validateFormInputs(this.errors, this.form, this.nullable);

      if (this.checkObjectForNull(this.errors)) {
        const id = this.getFirstNonNullKey(this.errors);
        const element = document.getElementById(id);

        if (element) {
          element.focus();
        } else {
          console.warn(`element with id '${id}' is not found'`);
        }

        return false
      }

      return true
    },
    deepCopyObjectAndReplaceNull: function (obj) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            return [key, this.deepCopyObjectAndReplaceNull(value)];
          }
          return [key, null];
        })
      );
    },
    deepResetObjectAndReplaceNull: function (obj) {
      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          this.deepResetObjectAndReplaceNull(value);
        } else {
          obj[key] = null;
        }
      });
    },
    validateFormInputs: function (errors, form, nullable = {}) {
      Object.entries(errors).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          this.validateFormInputs(errors[key], form[key], nullable[key]);
        } else if (
          (form[key] === null || form[key] === '') &&
          !Object.prototype.hasOwnProperty.call(nullable, key)
        ) {
          errors[key] = 'Kolom wajib diisi';
        }
      });
    },
    checkObjectForNull: function (obj) {
      return Object.entries(obj).some(([, value]) => {
        if (typeof value === 'object' && value !== null) {
          return this.checkObjectForNull(value);
        }
        return !!value;
      });
    },
    getFirstNonNullKey: function (obj, parent) {
      let res = null;

      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          res = this.getFirstNonNullKey(obj[key], key);

          if (res !== null) {
            break;
          }
        } else if (obj[key] !== null) {
          res = key;

          break;
        }
      }

      return res ? (parent ? `${parent}_${res}` : res) : res;
    },
    clearErrors() {
      this.deepResetObjectAndReplaceNull(this.errors);
    },
    // return true jika inputan valid
    // return false jika inputan terdapat error
    handleSubmit() {
      this.clearErrors();
      this.validateFormInputs(this.errors, this.form, this.nullable);

      if (this.checkObjectForNull(this.errors)) {
        const id = this.getFirstNonNullKey(this.errors);
        const element = document.getElementById(id);
        if (element) {
          element.focus();
          this.$emit('error', { id, element });
        } else {
          new Error(`element with id '${id}' is not found'`);
        }
      } else {
        this.$emit('submit');
      }
    },
  },
};
</script>
