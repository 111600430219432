const LayoutDefault = () =>
  import(/* webpackChunkName: "sc" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "sc" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/kasir/Index.vue'
  );
const Data = () =>
  import(/* webpackChunkName: "sc" */ '@/views/stockist-center/kasir/Data.vue');
const FakturPenjualanPusat = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/kasir/pembayaran/faktur-penjualan-pusat/Index.vue'
  );
const DetailPembayaran = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/kasir/pembayaran/faktur-penjualan-pusat/DetailPembayaran.vue'
  );

const Laporan = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/kasir/laporan/Index.vue'
  );
const TambahLaporan = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/kasir/laporan/TambahLaporan.vue'
  );
const LaporanRestock = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/kasir/laporan/restock/index.vue'
  );
const TambahLaporanRestock = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/kasir/laporan/restock/Tambah.vue'
  );
const LaporanSC = () =>
  import(
    /* webpackChunkName: "sc" */ '@/views/stockist-center/kasir/pembayaran/laporan-sc/Index.vue'
  );

let root = '/kasir';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Kasir',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/data',
    component: Data,
    meta: {
      auth: true,
      title: 'Data Page | Kasir',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/pembayaran/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: LayoutData,
        meta: {
          breadcrumb: 'Pembayaran',
        },
        children: [
          {
            path: '',
            component: FakturPenjualanPusat,
            meta: {
              auth: true,
              title: 'Pembayaran | Kasir',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: DetailPembayaran,
            meta: {
              auth: true,
              title: 'Detail Faktur Penjualan SC | Kasir',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Faktur Penjualan SC',
            },
          },
        ],
      },
      {
        path: 'laporan-sc/',
        component: LayoutData,
        children: [
          {
            path: '',
            component: LaporanSC,
            meta: {
              auth: true,
              title: 'Laporan SC | Kasir',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/laporan/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: Laporan,
        meta: {
          auth: true,
          title: 'Laporan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-laporan',
        component: TambahLaporan,
        meta: {
          auth: true,
          title: 'Tambah Faktur SC | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'restock/',
        component: LayoutData,
        children: [
          {
            path: '',
            component: LaporanRestock,
            meta: {
              auth: true,
              title: 'Restock | Penjualan Pusat',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah-restock',
            component: TambahLaporanRestock,
            meta: {
              auth: true,
              title: 'Tambah Restock | Penjualan Pusat',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahLaporanRestock,
            meta: {
              auth: true,
              title: 'Edit Restock | Penjualan Pusat',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
];
