import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  units: {
    data: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getUnits: (state) => {
    return state.units;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setUnits: (state, data) => (state.units = data),
};

const actions = {
  async fetchUnits({ commit }) {
    commit('setLoader', true);
    let response = await ApiService.get('api/v1/units');
    commit('setUnits', response.data);
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
