<template>
  <div class="flex">
    <div v-if="preferences?.newThemes" class="block">
      <Sidebar />
    </div>
    <div style="width: -webkit-fill-available">
      <Navbar class="z-50" :menus="getMenus.data" />
      <main style="min-height: 90vh" class="max-w-screen bg-gray-50 p-4">
        <Breadcrumbs />
        <slot />
      </main>
      <TheFooter />
      <Countdown />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Navbar from '@/components/navbar/navbar.vue';
import TheFooter from '@/components/TheFooter';
import Breadcrumbs from '@/components/Breadcrumbs';
import { StorageService } from '@/services/storage.service';
import Countdown from '@/components/Countdown.vue';
import Sidebar from '@/components/sidebar/index.vue';

export default {
  name: 'LayoutDefault',
  data() {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    return {
      modal: null,
      email: null,
      user: StorageService.getUser(),
      preferences,
    };
  },
  components: {
    Navbar,
    Breadcrumbs,
    TheFooter,
    Sidebar,
    Countdown,
  },
  created() {},
  computed: {
    ...mapGetters({
      getMenus: 'menus/getMenus',
      getRolesByUser: 'users/getRolesByUser',
    }),
  },
  methods: {
    ...mapActions({
      fetchMenus: 'menus/fetchMenus',
      verify: 'users/verify',
      authLogout: 'auth/logout',
      updateUser: 'users/updateUser',
    }),
    closeUpdateEmail() {
      this.modal = null;
      setTimeout(() => {
        if (!this.user.email) {
          this.modal = 'update-email';
        } else {
          this.modal = 'verifikasi-email';
        }
      }, 1000);
      if (!this.user.email) {
        setTimeout(() => {
          this.modal = 'update-email';
        }, 1000);
      }
    },
    closeVerification() {
      this.modal = null;
      if (!this.user.email_verified_at) {
        setTimeout(() => {
          this.modal = 'update-email';
        }, 1000);
      }
    },
    updateEmail() {
      const payload = {
        data: {
          type: 'users',
          id: this.user.uuid,
          attributes: {
            email: this.email,
          },
        },
      };
      this.updateUser(payload).then((response) => {
        if (response) {
          this.user.email = this.email;
          StorageService.saveUser(this.user);
          this.closeVerification();
        }
      });
    },
    verifyEmail() {
      this.verify().then((response) => {
        if (response) {
          this.modal = 'form-verifikasi-email';
        }
      });
    },
    logout() {
      this.authLogout();
      this.$router.push('/login');
    },
  },
};
</script>
