<template>
  <div
    v-if="!isHidden"
    class="no-print fixed top-0 right-0 p-2"
    style="z-index: 9999"
  >
    <div
      id="alert-1"
      class="flex items-center rounded-lg p-4"
      role="alert"
      :class="getClass()"
    >
      <svg
        aria-hidden="true"
        class="h-5 w-5 flex-shrink-0"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <span class="sr-only">Info</span>
      <div class="ml-3 text-sm font-medium">
        <!-- ada 2 tipe error, object dan string, jika object, tampilkan detail -->
        {{
          typeof data === 'object'
            ? data.errors[0].detail ?? 'Terjadi error'
            : data
        }}
      </div>
      <button
        @click="setHidden"
        type="button"
        class="-my-1.5 ml-2 flex h-8 w-8 items-center justify-center"
        data-dismiss-target="#alert-1"
        aria-label="Close"
        :class="getClass()"
      >
        <span class="sr-only">Close</span>
        <svg
          aria-hidden="true"
          class="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Alert',
  props: {
    data: {
      type: [Object, String],
    },
    status: {
      type: String,
      default: 'info',
      // todo, add more color
      validator: (val) => ['success', 'info', 'warning', 'error'].includes(val),
    },
  },
  data() {
    return {
      isHidden: false,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    setHidden() {
      this.isHidden = true;
      this.createAlert({ data: '' });
    },
    getClass() {
      return {
        // Color
        'bg-green-100 text-green-700 border border-transparent':
          this.status === 'success',
        'bg-blue-100 text-blue-700 border border-transparent':
          this.status === 'info',
        'bg-yellow-100 text-yellow-700 border border-transparent':
          this.status === 'warning',
        'bg-red-100 text-red-700 border border-transparent':
          this.status === 'error',
      };
    },
  },
};
</script>
