import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  stockMovements: {
    data: [],
    meta: {
      page: {},
    },
  },
  stockMovement: {
    type: 'stock-movements',
    data: {
      attributes: {
        code: '',
        packets: [
          {
            code: '',
            name: '',
          },
        ],
      },
      relationships: {
        order: {
          data: {
            id: '',
          },
        },
      },
    },
    included: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getStockMovements: (state) => {
    return state.stockMovements;
  },
  getStockMovement: (state) => {
    return state.stockMovement;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setStockMovements: (state, data) => (state.stockMovements = data),
  setStockMovement: (state, data) => (state.stockMovement = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchStockMovementsByOrder({ commit }, { orderId, params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get(
        `api/v1/orders/${orderId}/stock-movements`,
        {
          params,
        }
      );

      commit('setStockMovements', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStockMovements', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchStockMovements(
    { commit, rootGetters },
    { destination_office_category, origin_office_category, ...others }
  ) {
    commit('setLoader', true);
    const office_categories =
      rootGetters['office_categories/getAvailableOfficeCategories'];
    const params = { ...others };
    let filter = [];
    let page = [];

    if (destination_office_category) {
      let destination_office_category_id = office_categories.filter(function (
        el
      ) {
        return el.attributes.name === destination_office_category;
      });
      params['filter[destination_office_category_id]'] =
        destination_office_category_id[0].id;
    }

    if (origin_office_category) {
      let origin_office_category_id = office_categories.filter(
        (it) => it.attributes.name === origin_office_category
      )[0].id;
      params['filter[origin_office_category_id]'] = origin_office_category_id;
    }

    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    let url = 'api/v1/stock-movements';
    if (params?.orderId) {
      url = `api/v1/orders/${params.orderId}/stock-movements`;
      delete params.orderId;
    }

    try {
      let response = await ApiService.get(url, {
        params,
      });
      commit('setStockMovements', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStockMovements', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchStockMovement({ commit }, { id, ...others }) {
    commit('setLoader', true);
    const params = { ...others };

    if (!params.include) {
      Object.assign(params, {
        include: 'destination-office,origin-office,order',
      });
    }
    try {
      let response = await ApiService.get('api/v1/stock-movements/' + id, {
        params,
      });
      commit('setStockMovement', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      commit('setStockMovement', {});
    }
    commit('setLoader', false);
  },
  async createStockMovement({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/stock-movements', payload);
      commit('setStockMovement', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async updateStockMovement({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/stock-movements/' + payload.data.id,
        payload
      );
      commit('setStockMovement', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async receiveStockMovement({ commit, dispatch }, { id }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        `api/v1/stock-movements/${id}/-actions/recieve`
      );

      dispatch(
        'alert/createAlert',
        { data: 'Berhasil diterima' },
        { root: true }
      );

      commit('setError', null);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async sendStockMovement({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/stock-movements/' + payload.data.id + '/-actions/send',
        payload
      );
      commit('setStockMovement', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async classifyMovementDetail({ commit, dispatch }, { payload }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        'api/v1/stock-movements/-actions/classifyStocks',
        payload
      );
      commit('setStockMovement', response.data);
      commit('setError', null);
      dispatch(
        'alert/createAlert',
        {
          data: `Berhasil memilah barang`,
          status: 'success',
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        {
          data: `Gagal memilah barang`,
          status: 'warning',
        },
        { root: true }
      );
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async classifyStocks({ commit, dispatch }, { id, payload }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/stock-movements/' + id + '/-actions/classify',
        payload
      );
      commit('setStockMovement', response.data);
      commit('setError', null);
      dispatch(
        'alert/createAlert',
        {
          data: `Berhasil memilah barang`,
          status: 'success',
        },
        { root: true }
      );
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        {
          data: `Gagal memilah barang`,
          status: 'warning',
        },
        { root: true }
      );
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async approveDeclineComplain({ commit, dispatch }, { payload, type }) {
    commit('setLoader', true);
    const actionType =
      type == 'approve' ? 'approveComplain' : 'declineComplain';
    try {
      let response = await ApiService.patch(
        `api/v1/stock-movements/${payload.data.id}/-actions/${actionType}?include=destination-office,origin-office,order`,
        payload
      );
      dispatch(
        'alert/createAlert',
        {
          data: `Komplain berhasil di${type == 'approve' ? 'terima' : 'tolak'}`,
          status: 'warning',
        },
        { root: true }
      );
      commit('setStockMovement', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async deleteStockMovement({ commit }, { id }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.delete(`api/v1/stock-movements/${id}`);
      commit('setStockMovement', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      commit('setStockMovement', {});
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
