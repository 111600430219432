import Admin from './admin.route';
import Gudang from './gudang.route';
import CashBill from './cash-bill.route';
import Kasir from './kasir.route';

let stockist = [];

stockist = stockist.concat(Admin, Gudang, CashBill, Kasir);

export default stockist;
