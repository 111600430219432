<template>
  <div>
    <div class="flex sm:flex-row sm:gap-x-4">
      <base-input
        :value="getMemberCode()"
        type="text"
        name="no_distributor"
        id="no_distributor"
        :label="`Kode ${mapOfficeCategory[viewAs]}`"
        placeholder="Masukkan kode"
        inset
        with-label
        :disabled="disabled"
        :shadow="false"
        :invalid="errors.nama_distributor"
        :message="errors.nama_distributor"
        fullwidth
      />
    </div>
    <div class="my-4 flex sm:flex-row sm:gap-x-4">
      <base-input
        v-model="value.nama_distributor"
        type="text"
        name="nama_distributor"
        id="nama_distributor"
        :label="`Nama ${mapOfficeCategory[viewAs]}`"
        placeholder="Masukkan nama lengkap"
        inset
        with-label
        :disabled="disabled"
        :shadow="false"
        :invalid="errors.nama_distributor"
        :message="errors.nama_distributor"
        fullwidth
      />
    </div>
    <div class="flex sm:flex-row sm:gap-x-4">
      <base-input
        v-model="value.no_nik"
        type="text"
        name="no_nik"
        id="no_nik"
        placeholder="Masukkan nomor identitas"
        label="No. KTP/Identitas"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.no_nik"
        :message="errors.no_nik"
        fullwidth
      />
      <base-input
        v-model="value.email"
        type="email"
        name="email"
        id="email"
        placeholder="Masukkan email anda"
        label="Email"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.email"
        :message="errors.email"
        fullwidth
      />
    </div>
    <div class="mt-4 flex sm:flex-row sm:gap-x-4">
      <base-input
        v-model="value.no_hp"
        type="text"
        name="no_hp"
        id="no_hp"
        placeholder="Masukkan nomor hp"
        label="No. HP"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.no_hp"
        :message="errors.no_hp"
        fullwidth
      />
      <base-input
        v-model="value.tanggal_lahir"
        type="date"
        name="tanggal_lahir"
        id="tanggal_lahir"
        placeholder="Masukkan nomor identitas"
        label="Tanggal Lahir"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.tanggal_lahir"
        :message="errors.tanggal_lahir"
        fullwidth
      />
    </div>
    <div class="mt-4 flex sm:flex-row sm:gap-x-4">
      <base-input
        label="Pendidikan Terakhir"
        inset
        with-label
        :disabled="disabled"
        :invalid="errors.education_id"
        :message="errors.education_id"
        fullwidth
      >
        <base-select
          custom-height="h-auto"
          :options="selectEducationOptions"
          id="education_id"
          inset
          :disabled="disabled"
          v-model="value.education_id"
          expand
        />
      </base-input>
      <base-input
        label="Pekerjaan Terakhir"
        inset
        with-label
        :disabled="disabled"
        :invalid="errors.profession_id"
        :message="errors.profession_id"
        fullwidth
      >
        <base-select
          custom-height="h-auto"
          :options="selectProfessionOptions"
          id="profession_id"
          inset
          :disabled="disabled"
          v-model="value.profession_id"
          expand
        />
      </base-input>
    </div>
    <div
      v-if="viewAs != 'kantor_pelayanan'"
      class="mt-4 flex sm:flex-row sm:gap-x-4"
    >
      <div class="w-full">
        <base-input
          :invalid="errors.jenis_kelamin"
          :message="errors.jenis_kelamin"
        >
          <label
            for="jenis_kelamin"
            class="block text-xs font-bold text-gray-700"
          >
            Jenis Kelamin
          </label>
          <div class="my-3 flex items-center space-x-8">
            <div class="items-center text-sm text-gray-700">
              <input
                v-model="value.jenis_kelamin"
                type="radio"
                name="jenis_kelamin"
                id="jenis_kelamin"
                value="male"
                :disabled="disabled"
                class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              Laki-laki
            </div>
            <div class="items-center text-sm text-gray-700">
              <input
                v-model="value.jenis_kelamin"
                type="radio"
                name="jenis_kelamin"
                value="female"
                :disabled="disabled"
                class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              Perempuan
            </div>
          </div>
        </base-input>
      </div>
    </div>
    <div
      v-if="viewAs != 'kantor_pelayanan'"
      class="mt-4 flex sm:flex-row sm:gap-x-4"
    >
      <div class="w-full">
        <base-input
          :invalid="errors.jenis_kelamin"
          :message="errors.jenis_kelamin"
        >
          <label
            for="status_pernikahan"
            class="block text-xs font-bold text-gray-700"
          >
            Status Pernikahan
          </label>
          <div class="my-3 flex items-center space-x-8">
            <div class="items-center text-sm text-gray-700">
              <input
                @change="changeMarital"
                v-model="value.status_pernikahan"
                type="radio"
                id="status_pernikahan"
                name="status_pernikahan"
                value="married"
                :disabled="disabled"
                class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              Menikah
            </div>
            <div class="items-center text-sm text-gray-700">
              <input
                @change="changeMarital"
                v-model="value.status_pernikahan"
                type="radio"
                name="status_pernikahan"
                value="single"
                :disabled="disabled"
                class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              Belum Menikah
            </div>
          </div>
        </base-input>
      </div>
    </div>
    <div
      class="mt-4 flex sm:flex-row sm:gap-x-4"
      v-if="selectedMarital && viewAs != 'kantor_pelayanan'"
    >
      <base-input
        v-model="value.nama_pasangan"
        type="text"
        name="nama_pasangan"
        id="nama_pasangan"
        placeholder="Masukkan nama pasangan"
        label="Nama Pasangan"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.nama_pasangan"
        :message="errors.nama_pasangan"
        fullwidth
      />
      <base-input
        v-model="value.tanggal_pasangan"
        type="date"
        name="tanggal_pasangan"
        id="tanggal_pasangan"
        label="Tanggal Lahir"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.tanggal_pasangan"
        :message="errors.tanggal_pasangan"
        fullwidth
      />
    </div>
    <div class="mt-4 flex sm:flex-row sm:gap-x-4">
      <base-input
        v-model="value.nama_pewaris"
        type="text"
        name="nama_pewaris"
        id="nama_pewaris"
        placeholder="Masukkan nama pewaris"
        label="Nama Pewaris"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.nama_pewaris"
        :message="errors.nama_pewaris"
        fullwidth
      />
      <base-input
        v-model="value.tanggal_pewaris"
        type="date"
        name="tanggal_pewaris"
        id="tanggal_pewaris"
        label="Tanggal Lahir"
        with-label
        inset
        :disabled="disabled"
        :invalid="errors.tanggal_pewaris"
        :message="errors.tanggal_pewaris"
        fullwidth
      />
    </div>
  </div>
</template>
<script>
import BaseInput from '@/components/base/BaseInput.vue';
// import BaseButton from '@/components/base/BaseButton.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import { mapGetters } from 'vuex';
import { mapOfficeCategory } from '../../../utils/object';

export default {
  name: 'PersonalInformationForm',
  components: {
    // BaseButton,
    BaseInput,
    BaseSelect,
  },
  props: {
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
    },
    selectProfessionOptions: {
      type: Array,
    },
    selectEducationOptions: {
      type: Array,
    },
    selectedMarital: {
      type: Boolean,
    },
    viewAs: {
      type: String,
      default: 'member',
    },
  },
  data() {
    return {
      mapOfficeCategory,
    };
  },
  computed: {
    ...mapGetters({
      getMember: 'members/getMember',
    }),
  },
  methods: {
    saving() {},
    changeMitraCode(value) {
      this.$emit('change-mitra-code', value);
    },
    changeMarital(value) {
      this.$emit('change-marital', value);
    },
    getMemberCode() {
      const code = this.getMember?.data?.attributes?.code;
      const tmpCode = this.getMember?.data?.attributes?.temp_code;

      if (code && !tmpCode) {
        return code;
      }

      if (!code || !tmpCode) {
        return '';
      }

      if (code == tmpCode) {
        return tmpCode;
      }

      return `${code} / ${tmpCode}`;
    },
  },
};
</script>
