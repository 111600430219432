import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  packet: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setPacket: (state, data) => (state.packet = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async deletePacket({ commit }, { id }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.delete(`api/v1/packets/${id}`);
      commit('setPacket', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      commit('setPacket', {});
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
