import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  returShipments: {
    data: [],
    meta: {
      page: {},
    },
  },
  returShipment: {
    data: {
      attributes: {},
      relationships: {
        retur: {
          data: {}
        },
        'origin-office': {
          data: {}
        }
      },
    },
    included: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getReturShipments: (state) => {
    return state.returShipments;
  },
  getReturShipment: (state) => {
    return state.returShipment;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setReturShipments: (state, data) => (state.returShipments = data),
  setReturShipment: (state, data) => (state.returShipment = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchReturShipments({ commit, dispatch }, params) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get('api/v1/retur-shipments', {
        params,
      });

      commit('setReturShipments', response.data);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setReturShipments', {
        data: [],
        meta: { page: {} },
      });
    }
    commit('setLoader', false);
  },
  async fetchReturShipment({ commit, dispatch }, { id, params }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.get(`api/v1/retur-shipments/${id}`, {
        params,
      });

      commit('setReturShipment', response.data);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setReturShipment', {
        data: [],
        meta: { page: {} },
      });
    }
    commit('setLoader', false);
  },
  async receiveReturShipment({ commit, dispatch }, { id, payload }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.patch(`api/v1/retur-shipments/${id}/-actions/receive`, payload);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    }
    commit('setLoader', false);
  },
  async shipReturShipment({ commit, dispatch }, { id, payload }) {
    commit('setLoader', true);

    try {
      const response = await ApiService.patch(`api/v1/retur-shipments/${id}/-actions/ship`, payload);
      commit('setError', null);
      commit('setLoader', false);

      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
