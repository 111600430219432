import Gudang from './gudang.route';
import Penjualan from './penjualan.route';
import Kasir from './kasir.route';
import Admin from './admin.route';

let kantor_pelayanan = [];

kantor_pelayanan = kantor_pelayanan.concat(Gudang, Penjualan, Kasir, Admin);

export default kantor_pelayanan;
