import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  setups: {
    data: [],
  },
  multiplier: {
    data: [],
  },
  setup: {},
  error: null,
  errorRunClosePeriod: null,
  loadingRunClosePeriod: false,
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getSetups: (state) => {
    return state.setups;
  },
  getSetup: (state) => {
    return state.setup;
  },
  getMultiplier: (state) => {
    return state.multiplier;
  },
  getError: (state) => {
    return state.error;
  },
  getErrorRunClosePeriod: (state) => {
    return state.errorRunClosePeriod;
  },
  getLoadingRunClosePeriod: (state) => {
    return state.loadingRunClosePeriod;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setSetups: (state, data) => (state.setups = data),
  setSetup: (state, data) => (state.setup = data),
  setMultiplier: (state, data) => (state.multiplier = data),
  setError: (state, data) => (state.error = data),
  setLoaderRunClosePeriod: (state, data) =>
    (state.loadingRunClosePeriod = data),
  setErrorRunClosePeriod: (state, data) => (state.errorRunClosePeriod = data),
};

const actions = {
  async fetchSetups({ commit }, params) {
    commit('setLoader', true);
    try {
      const res =  await ApiService.get('api/v1/setups', { params });

      commit('setSetups', res.data);

      return res
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchMultiplier({ commit }) {
    commit('setLoader', true);
    let response = await ApiService.get(
      'api/v1/setups?filter[setup_key]=sc_deposit_multiplier'
    );
    commit('setMultiplier', response.data);
    commit('setLoader', false);
    return response;
  },
  async updateMultiplier({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/setups/' + payload.data.id,
        payload
      );
      commit('setMultiplier', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setMultiplier', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async runClosePeriod({ commit }) {
    commit('setLoaderRunClosePeriod', true);

    try {
      await ApiService.get('/api/v1/setups/-actions/runClosePeriod');

      commit('setErrorRunClosePeriod', null);
    } catch (err) {
      //
    } finally {
      commit('setLoaderRunClosePeriod', false);
    }
  },
  async updateSetup({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/setups/' + payload.data.id,
        payload
      );
      commit('setSetup', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setSetup', {});
      commit('setError', error.response.data.errors[0].detail);
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors[0].detail);
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
        commit('setError', error.response.data.errors);
      }
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
