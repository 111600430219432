<template>
  <div
    class="relative my-1 mx-2 flex-shrink-0"
    v-click-outside="handleClickOutside"
  >
    <button
      @click="changeProfile"
      type="button"
      onmouseover="this.style.backgroundColor='#11111122'"
      onmouseout="this.style.backgroundColor='transparent'"
      class="sm:w-fit sm:h-fit flex max-w-xs items-center gap-x-2 rounded-full text-sm"
      id="user-menu-button"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <img
        v-if="getProfileUrl"
        :src="getProfileUrl"
        class="h-8 w-8 rounded-full"
        id="profile_picture"
        style="background-color: white"
        alt="Foto Profile"
      />
      <img
        v-else-if="me.sex == 'female'"
        src="@/assets/images/default-female.png"
        class="h-8 w-8 rounded-full"
        id="profile_picture"
        style="background-color: white"
        alt="Foto Profile"
      />
      <img
        v-else
        src="@/assets/images/default-male.png"
        class="h-8 w-8 rounded-full"
        id="profile_picture"
        style="background-color: white"
        alt="Foto Profile"
      />
    </button>
    <!-- profile dropdown -->
    <ProfileDropdown
      :visible="showProfile"
      @logout="logout"
      @change-role="changeRole"
      @toggle-menu="showMenuModal"
      @set-menu="setMenu"
    />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import ProfileDropdown from '@/components//profile/profile-dropdown.vue';
import { StorageService } from '@/services/storage.service';

export default {
  name: 'Profile',
  components: {
    ProfileDropdown,
  },
  data() {
    return {
      me: StorageService.getUser(),
      showMenu: false,
      showProfile: false,
      baseUrl: process.env.VUE_APP_API_URL,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters({
      getRolesByUser: 'users/getRolesByUser',
      getUser: 'users/getUser',
    }),
    getProfileUrl() {
      // check apakah ada avatar?
      return this.me.avatar_filename
        ? this.baseUrl + '/api/v1/avatars/' + this.me.avatar_filename
        : null;
    },
  },
  methods: {
    ...mapMutations({
      setMenuId: 'auth/setMenuId',
      authSetMenu: 'auth/setMenu',
    }),
    ...mapActions({
      authLogout: 'auth/logout',
    }),
    changeProfile() {
      this.showProfile = !this.showProfile;
      this.$emit('click-profile');
    },
    handleClickOutside: function () {
      this.showProfile = false;
    },
    logout() {
      this.authLogout();
      this.$router.push('/login');
    },
    showMenuModal({ toggleProfile } = { toggleProfile: true }) {
      this.showMenu = !this.showMenu;
      if (toggleProfile) {
        this.showProfile = !this.showProfile;
      }
    },
    setMenu(menu, to) {
      this.authSetMenu(menu);

      const menus = {
        Superadmin: '/superadmin',
        Admin: '/admin',
        Gudang: '/gudang',
        Kasir: '/kasir',
        Penjualan: '/penjualan',
        Pembelian: '/pembelian',
        Pelayanan: '/kantor-pelayanan',
        'Barang Masuk': '/barang-masuk',
        'Transaksi Penjualan': '/pengiriman-barang',
        'Retur Barang': '/retur-barang',
        Laporan: '/laporan',
        'Cash Bill': '/cashbill',
        Bonus: '/bonus',
        'Stock Stockist': '/stock-stockist',
      };

      if (Object.prototype.hasOwnProperty.call(menus, menu.attributes.name)) {
        this.$router.push(to || menus[menu.attributes.name]);
      }
    },
    changeRole() {
      // kalo rolenya kurang dr 2, maka tidak bisa switch role
      if (this.getRolesByUser.data.length < 2) return;
      this.showRole = !this.showRole;
      this.showProfile = !this.showProfile;
    },
    onResizeWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResizeWidth);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeWidth);
  },
};
</script>
