<template>
  <div class="absolute z-10 w-full" id="navbar-hamburger">
    <ul
      class="mt-4 flex flex-col divide-y rounded-lg bg-white dark:border-gray-700 dark:bg-gray-800"
    >
      <template v-for="menu in menus">
        <li :key="menu.id">
          <button
            @click="onClickRow(menu)"
            v-if="menu.attributes.type == 'group'"
            class="flex w-full justify-between rounded py-2 px-3 text-gray-900"
          >
            <div>
              {{ menu.attributes.name }}
            </div>
            <div>></div>
          </button>
          <router-link
            v-else
            :to="
              menu.attributes.url?.[0] == '/'
                ? menu.attributes.url
                : '/' + menu.attributes.url
            "
            class="block rounded py-2 px-3 text-gray-900"
            aria-current="page"
            >{{ menu.attributes.name }}</router-link
          >
        </li>
        <template v-if="showChild && selectedItem.id == menu.id">
          <li v-for="menu in childs" :key="menu.id" class="pl-4 text-sm">
            <button
              @click="onClickRow(menu)"
              v-if="menu.attributes.type == 'group'"
              class="flex w-full justify-between rounded py-2 px-3 text-gray-900"
            >
              <div>
                {{ menu.attributes.name }}
              </div>
              <div>></div>
            </button>
            <router-link
              v-else
              :to="
                menu.attributes.url?.[0] == '/'
                  ? menu.attributes.url
                  : '/' + menu.attributes.url
              "
              class="block rounded py-2 px-3 text-gray-900"
              aria-current="page"
              >{{ menu.attributes.name }}</router-link
            >
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'MobileNavbar',
  props: {
    menus: {
      type: Array,
      required: true,
    },
    isChildVisible: Boolean,
    activeMenu: {
      type: Object,
    },
    childs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showChild: false,
      selectedItem: {},
    };
  },
  methods: {
    onClickRow(value) {
      this.$emit('click', value);
      this.selectedItem = value;
      this.showChild = true;
    },
  },
};
</script>
