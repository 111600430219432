<template>
  <div class="flex flex-col items-center">
    <div class="relative flex-1">
      <div class="pt-2 md:py-6">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div class="flex h-screen flex-col items-center justify-center">
            <div class="mt-6 text-center">
              <img
                src="@/assets/images/404.png"
                class="object-cover"
                alt="Login Illustrator"
              />
              <h1 class="font-mediun mt-12 text-2xl text-gray-900">
                Halaman yang anda cari tidak ditemukan ..
              </h1>
              <h1
                @click="goBack"
                class="font-mediun flex mt-12 cursor-pointer justify-center text-2xl text-blue-700"
              >
                Kembali
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
