import ApiService from '@/services/api.service';

const state = {
    notifications: {
      data: [],
      meta: {
        page: {
          total: 0
        }
      }
    }
};

const getters = {
    getNotifications: state => state.notifications
};

const mutations = {
  setNotifications: (state, notifications) => (state.notifications = notifications),
  setEducations: (state, data) => (state.educations = data),
  setEducation: (state, data) => (state.education = data),
  setError: (state, data) => (state.error = data),
  pushNotification(state, notification) {
    if (state.notifications.data.length > 10) {
      state.notifications.data.pop()
    }

    state.notifications.data.unshift(notification)
    state.notifications.meta.page.total++
  },
  editNotification(state, notification) {
    const notificationIndex = state.notifications.data.findIndex(item => item.id === notification.id)

    if (notification.attributes.read_at) {
      state.notifications.data.splice(notificationIndex, 1)
      state.notifications.meta.page.total--
    } else {
      state.notifications.data[notificationIndex] = notification
    }
  }
};

const actions = {
  async fetchNotifications({ commit }, { params }) {
    const res = await ApiService.get(`api/v1/notifications`, { params });

    commit('setNotifications', res.data);
    return res;
  },
  async updateNotifications(commit, { id, payload }) {
    await ApiService.patch(`api/v1/notifications/${id}`, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
