const LayoutDefault = () =>
  import(/* webpackChunkName: "stockist" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "stockist" */ '@/layouts/LayoutData.vue');

const Dashboard = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/admin/dashboard/Index.vue'
  );

const DataDistributor = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/admin/pencatatan/data-distributor/Index.vue'
  );
const TambahDataDistributor = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/admin/pencatatan/data-distributor/Tambah.vue'
  );

// Master
const Barang = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/admin/master/barang/Index.vue'
  );

const Ekspedisi = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/stockist/admin/master/ekspedisi/Index.vue'
  );

const SetupKasir = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/pusat/admin/setup/Kasir.vue'
  );

let root = '/admin';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Admin',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/daftar-member/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Daftar Mitra Usaha',
    },
    children: [
      {
        path: '',
        component: DataDistributor,
        meta: {
          auth: true,
          title: 'Daftar Mitra Usaha | Admin',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-member',
        component: TambahDataDistributor,
        meta: {
          auth: true,
          title: 'Pencatatan Data Mitra Usaha | Admin',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Mitra Usaha',
        },
      },
      {
        path: ':id',
        component: TambahDataDistributor,
        meta: {
          auth: true,
          title: 'Pencatatan Data Mitra Usaha | Admin',
          layout: LayoutDefault,
          breadcrumb: 'Detail Mitra Usaha',
        },
      },
    ],
  },
  {
    path: root + '/barang',
    component: Barang,
    meta: {
      auth: true,
      title: 'Barang',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/ekspedisi',
    component: Ekspedisi,
    name: 'expedition.index',
    meta: {
      auth: true,
      title: 'Ekspedisi',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/bank',
    component: SetupKasir,
    meta: {
      auth: true,
      title: 'Bank',
      layout: LayoutDefault,
    },
    props: {
      tabs: ['Metode Pembayaran'],
    },
  },
];
