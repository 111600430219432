import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  periods: {
    data: [],
    meta: {
      page: {
        total: 0,
        perPage: 0,
        currentPage: 0,
      },
    },
  },
  period: {
    data: {},
    meta: {
      page: {
        total: 0,
        perPage: 0,
        currentPage: 0,
      },
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getPeriods: (state) => {
    return state.periods;
  },
  getPeriod: (state) => {
    return state.period;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setPeriods: (state, data) => (state.periods = data),
  setPeriod: (state, data) => (state.period = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchPeriods({ commit }, params) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get('api/v1/periods', {
        params,
      });
      commit('setPeriods', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setPeriods', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchCurrentPeriod({ commit }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get('api/v1/periods/-actions/get-current-period');
      commit('setPeriod', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setPeriod', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async updatePeriod({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/periods/' + payload.data.id,
        payload
      );
      dispatch(
        'alert/createAlert',
        { data: 'Periode tutup buku berhasil diubah' },
        { root: true }
      );
      commit('setPeriod', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setPeriod', {
        data: {},
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
