const LayoutDefault = () =>
  import(/* webpackChunkName: "sc-admin" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "sc-admin" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "sc-admin" */ '@/views/stockist-center/admin/dashboard/Index.vue'
  );

const Barang = () =>
  import(
    /* webpackChunkName: "sc-admin" */ '@/views/stockist-center/admin/master/barang/Index.vue'
  );
const Stockist = () =>
  import(
    /* webpackChunkName: "sc-admin" */ '@/views/stockist-center/admin/master/stockist/Index.vue'
  );
const Ekspedisi = () =>
  import(
    /* webpackChunkName: "sc-admin" */ '@/views/stockist-center/admin/master/ekspedisi/Index.vue'
  );
const SetupKasir = () =>
  import(/* webpackChunkName: "admin" */ '@/views/pusat/admin/setup/Kasir.vue');

let root = '/admin';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/master/',
    component: LayoutData,
    children: [
      // Route Master Barang
      {
        path: 'barang/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Barang',
        },
        children: [
          {
            path: '',
            component: Barang,
            meta: {
              auth: true,
              title: 'Master Barang',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master Stockist
      {
        path: 'stockist/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Stockist',
        },
        children: [
          {
            path: '',
            component: Stockist,
            meta: {
              auth: true,
              title: 'Stockist',
              layout: LayoutDefault,
            },
          },
        ],
      },
      // Route Master Ekspedisi
      {
        path: 'ekspedisi/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Ekspedisi',
        },
        children: [
          {
            path: '',
            component: Ekspedisi,
            name: 'expedition.index',
            meta: {
              auth: true,
              title: 'Ekspedisi',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  // Menu Setup
  {
    path: root + '/setup/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Setup',
    },
    children: [
      {
        path: '',
        component: SetupKasir,
        meta: {
          auth: true,
          title: 'Setup',
          layout: LayoutDefault,
        },
        props: {
          tabs: ['Metode Pembayaran'],
        },
      },
    ],
  },
];
