import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  paymentMethodType: {},
  paymentMethodTypes: {
    data: [],
    meta: {
      page: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getPaymentMethodTypes: (state) => state.paymentMethodTypes,
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setPaymentMethodType: (state, data) => (state.paymentMethodType = data),
  setPaymentMethodTypes: (state, data) => (state.paymentMethodTypes = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchPaymentMethodTypesByOffice({ commit }, { officeId, params }) {
    commit('setLoader', true);

    try {
      const res = await ApiService.get(`api/v1/offices/${officeId}/payment-method-types`, { params });

      commit('setPaymentMethodTypes', res.data);
      commit('setError', null);

      return res;
    } catch (err) {
      commit('setPaymentMethodTypes', {});
      commit('setError', null);
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
