<template>
  <Modal
    :showing="isModalVisible"
    @close="onCloseModal"
    :showClose="isModalVisible"
    size="w-full"
  >
    <div class="flex justify-between px-4 py-5 sm:px-6">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">{{ title }}</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          {{ description }}
        </p>
      </div>
      <div>
        <img src="@/assets/images/logo.png" alt="" class="w-52" />
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div class="flex justify-end">
        <base-search
          :placeholder="searchPlaceholder"
          v-model="search"
          @input="debounceSearch"
        />
      </div>
      <div class="my-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Datatable
            :total="data.meta?.page.total"
            :perPage="data.meta?.page.perPage"
            :currentPage="data.meta?.page.currentPage"
            :meta="data.meta"
            @pagechanged="onPageChanged"
            cursor
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    v-if="tableColumns.code !== false"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {{ tableColumnLabels.code }}
                  </th>
                  <th
                    v-if="tableColumns.code !== false"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {{ tableColumnLabels.name }}
                  </th>
                  <th
                    scope="col"
                    v-if="viewAs == 'stockist' && tableColumns.upline !== false"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {{ tableColumnLabels.upline }}
                  </th>
                  <th
                    v-if="
                      viewAs != 'stockist' && tableColumns.address !== false
                    "
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {{ tableColumnLabels.address ?? '-' }}
                  </th>
                  <th
                    v-if="
                      viewAs != 'stockist' && tableColumns.address !== false
                    "
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {{ tableColumnLabels.phone ?? '-' }}
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody>
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in data.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="$emit('change-office', data)"
                >
                  <td
                    v-if="tableColumns.code !== false"
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{
                      viewAs == 'stockist' &&
                      getOfficeIncluded(data).attributes.temp_code
                        ? getOfficeIncluded(data).attributes.temp_code + ' /'
                        : null
                    }}
                    {{ getOfficeIncluded(data).attributes.code }}
                  </td>
                  <td
                    v-if="tableColumns.code !== false"
                    class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  >
                    {{ getOfficeIncluded(data).attributes.name }}
                  </td>
                  <td
                    v-if="viewAs == 'stockist' && tableColumns.upline !== false"
                    class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  >
                    {{
                      getOfficeIncluded(data).attributes.referred_by_name
                        ? getOfficeIncluded(data).attributes.referred_by_name
                        : '-'
                    }}
                    {{
                      getOfficeIncluded(data).attributes.referred_by
                        ? '(' +
                          getOfficeIncluded(data).attributes.referred_by +
                          ')'
                        : '-'
                    }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    v-if="
                      viewAs != 'stockist' && tableColumns.address !== false
                    "
                  >
                    {{ getOfficeIncluded(data).attributes.address }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    v-if="viewAs != 'stockist' && tableColumns.phone !== false"
                  >
                    {{ getOfficeIncluded(data).attributes.phone }}
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <base-button color="white" @click="onCloseModal" type="button">
        Tutup
      </base-button>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Datatable from '../base/Datatable.vue';
import debounce from 'debounce';
import Modal from '@/components/base/BaseModal.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'OfficeModal',
  data() {
    return {
      search: null,
    };
  },
  components: {
    Datatable,
    Modal,
    BaseSearch,
  },
  props: {
    isVisible: Boolean,
    viewAs: {
      type: String,
      default: 'pusat',
    },
    title: {
      type: String,
      default: 'Daftar Kantor',
    },
    description: {
      type: String,
      default: 'Berdasarkan data kantor yang telah dibuat',
    },
    tableColumnLabels: {
      type: Object,
      default: () => ({
        code: 'Kode Kantor',
        name: 'Nama Kantor',
        upline: 'Upline',
        address: 'Alamat',
        phone: 'No. Telp',
      }),
    },
    tableColumns: {
      type: Object,
      default: () => ({}),
    },
    searchPlaceholder: {
      type: String,
      default: 'Cari Kantor',
    },
    downline: Boolean
  },
  computed: {
    ...mapGetters({
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getUplineSimpleOffices: 'simple_offices/getUplineSimpleOffices',
    }),
    isModalVisible: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit('closeKantorModal', value);
      },
    },
    data() {
      return this.downline ? this.getUplineSimpleOffices : this.getSimpleOffices
    }
  },
  methods: {
    ...mapActions({}),

    debounceSearch: debounce(function () {
      this.$emit('onSearchKantor', this.search);
    }, 300),

    onPageChanged(page) {
      this.$emit('onPageChangeKantor', page);
    },

    onCloseModal() {
      this.search = '';
      // this.isModalVisible = false;
      this.$emit('closeKantorModal');
    },

    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.data,
        simpleOffice.relationships.office.data.id
      );
    },
  },
};
</script>
