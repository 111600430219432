<template>
  <div>
    <dl class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
      <div
        class="group relative flex items-start overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:py-6"
      >
        <div
          class="mr-4 inline-flex rounded-lg bg-green-50 p-2 text-green-700 ring-4 ring-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </div>
        <div>
          <dt>
            <p class="truncate text-sm font-medium text-gray-500">
              Saldo Stockist
            </p>
          </dt>
          <dd class="flex items-baseline">
            <p
              id="stockist_balance"
              class="text-2xl font-semibold text-gray-900"
            >
              {{ getOffice.data?.attributes?.order_balance | toCurrency }}
            </p>
          </dd>
        </div>
      </div>
    </dl>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Stats',
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
    }),
  },
};
</script>
