const LayoutDefault = () =>
  import(/* webpackChunkName: "mitra-usaha" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "mitra-usaha" */ '@/layouts/LayoutData.vue');

const Dashboard = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/dashboard/Index.vue'
  );

const Pembelian = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/cash-bill/Index.vue'
  );

let root = '/gudang';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Gudang',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/cashbill/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Cash Bill',
    },
    children: [
      {
        path: '',
        component: Pembelian,
        meta: {
          auth: true,
          title: 'Cash Bill | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
];
