<template>
  <div class="mx-auto">
    <base-form
      id="tambah-data-distributor"
      :form="dataDistributor"
      :nullable="nullable"
      @submit="createOrUpdateOffice"
      v-slot="{ errors }"
    >
      <div class="space-y-6 divide-y">
        <form-header :title="title" />
        <div class="space-y-6 divide-y">
          <div class="pt-6">
            <PersonalInformationForm
              v-model="dataDistributor"
              @change-marital="maritalStats"
              @change-mitra-code="changeMitraCode"
              :errors="errors"
              :select-profession-options="selectProfessionOptions"
              :select-education-options="selectEducationOptions"
              :selected-marital="selectedMarital"
              :isDetail="is_detail"
              :view-as="viewAs"
              :disabled="disabled"
            />
          </div>
          <div class="pt-6">
            <AddressForm
              :view-as="viewAs"
              v-model="dataDistributor"
              @change-city="changeCity"
              @change-district="changeDistrict"
              @change-area="changeArea"
              @change-province="changeProvince"
              :errors="errors"
              :isDetail="is_detail"
              :disabled="disabled"
            />
          </div>
          <div v-if="viewAs != 'kantor_pelayanan'" class="pt-6">
            <BanksForm
              v-model="dataDistributor"
              @search-referal="searchReferal"
              :errors="errors"
              :select-bank-options="selectBankOptions"
              :disabled="disabled"
            />
          </div>
          <div
            v-if="
              getMember?.data?.attributes?.office_type !== 'kantor_pelayanan' &&
              getMember?.data?.attributes?.office_type !== 'pusat'
            "
            class="pt-6"
          >
            <UplineDownlineForm />
          </div>
          <div v-if="!disabled" class="flex w-full justify-end gap-4 pt-6">
            <div class="">
              <div class="flex justify-end">
                <base-button
                  @click="confirmation = true"
                  id="simpan_draft"
                  type="button"
                >
                  Simpan
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-form>

    <success-modal
      v-model="success"
      @close="() => (success = !success)"
      @previous="() => this.$router.push(routeAfterSuccess)"
    />

    <failed-modal
      v-model="failed"
      :errors="getError"
      @close="() => (failed = !failed)"
      @previous="() => (failed = !failed)"
    />

    <validation-modal
      @submit="createOrUpdateOffice"
      @close="() => (confirmation = !confirmation)"
      v-model="confirmation"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import BaseForm from '@/components/base/BaseForm.vue';
import dayjs from 'dayjs';
import PersonalInformationForm from '@/components/stockist/create-mitra-form/personal-form.vue';
import AddressForm from '@/components/stockist/create-mitra-form/address-form.vue';
import BanksForm from '@/components/stockist/create-mitra-form/banks-form.vue';
import UplineDownlineForm from '@/components/stockist/create-mitra-form/upline-downline-form.vue';
import ValidationModal from '@/components/modal/validation-modal.vue';
import FailedModal from '@/components/modal/failed-modal.vue';
import SuccessModal from '@/components/modal/success-modal.vue';
import FormHeader from './form-header.vue';

export default {
  name: 'CreateOrEditOfficeForm',
  components: {
    BaseForm,
    PersonalInformationForm,
    AddressForm,
    BanksForm,
    UplineDownlineForm,
    ValidationModal,
    FailedModal,
    SuccessModal,
    FormHeader,
  },
  props: {
    title: {
      type: String,
      default: 'Edit Data Kantor',
    },
    viewAs: {
      type: String,
      default: 'stockist',
    },
    routeAfterSuccess: {
      default: '/admin/master/stockist',
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const savedState = JSON.parse(localStorage.getItem('offices'));
    if (savedState) {
      if (savedState?.creating) {
        return savedState.creating;
      }
      if (savedState?.updating && savedState.updating[this.$route.params.id]) {
        return savedState.updating[this.$route.params.id];
      }
    }
    return {
      activeStep: 0,
      steps: [
        {
          label: 'Data Pribadi',
        },
        {
          label: 'Data Alamat',
        },
        {
          label: 'Data Bank',
        },
      ],
      is_detail: false,
      success: false,
      failed: false,
      confirmation: false,
      selectedMarital: false,
      objectAddress: {
        data: [],
        meta: {
          page: {},
        },
      },
      member_office_category: null,
      dataDistributor: {
        id: null,
        nomor: null,
        nama_lengkap: null,
        nama_distributor: null,
        no_nik: null,
        email: null,
        no_hp: null,
        tanggal_lahir: null,
        education_id: null,
        profession_id: null,
        jenis_kelamin: null,
        status_pernikahan: null,
        nama_pasangan: null,
        tanggal_pasangan: null,
        nama_pewaris: null,
        tanggal_pewaris: null,
        alamat: {
          detail: null,
          desa: null,
          kecamatan: null,
          kabupaten: null,
          provinsi: null,
          kode_pos: null,
        },
        area: null,
        bank_id: null,
        bank: {
          nama_bank: null,
          no_rekening: null,
          cabang_bank: null,
          nama_rekening: null,
        },
        no_npwp: null,
        nama_npwp: null,
        is_active: null,
        is_kta_need_delivery: false,
        referal: {
          no_upline: null,
          nama_upline: null,
        },
      },
    };
  },
  watch: {
    dataDistributor: {
      handler() {
        if (this.$route.params.id) {
          localStorage.setItem(
            'offices',
            JSON.stringify({
              updating: {
                [this.$route.params.id]: this.$data,
              },
            })
          );
        } else {
          localStorage.setItem(
            'offices',
            JSON.stringify({
              creating: this.$data,
            })
          );
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadBanks();
    this.fetchEducations({
      fields: {
        educations: 'name',
      },
    });
    this.fetchProfessions({
      fields: {
        professions: 'name',
      },
    });
    if (this.$route.params.id) {
      this.is_detail = true;
      this.dataDistributor.id = this.$route.params.id;
      this.loadMember(this.$route.params.id).then((response) => {
        this.setMember(response.data.data);
      });
    }
    this.member_office_category = this.getAvailableOfficeCategories.filter(
      function (el) {
        return el.attributes.name === 'Member';
      }
    );
    const handleEscape = () => {
      this.success =
        this.failed =
        this.openListProduct =
        this.openListSupplier =
          false;
      this.keywordProduct = this.keywordSupplier = '';
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getAreas: 'areas/getAreas',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getBanks: 'banks/getBanks',
      getMember: 'members/getMember',
      getError: 'members/getError',
      getEducations: 'educations/getEducations',
      getProfessions: 'professions/getProfessions',
      getAvailableOfficeCategories:
        'office_categories/getAvailableOfficeCategories',
    }),
    nullable: function () {
      return {
        id: null,
        nomor: null,
        nama_lengkap: null,
        ...(this.selectedMarital
          ? {}
          : {
              nama_pasangan: null,
              tanggal_pasangan: null,
            }),
        is_active: null,
        referal: { nama_upline: null },
        bank: { nama_bank: null },
      };
    },
    selectBankOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Bank',
          value: null,
        },
        ...this.getBanks.data.map((bank) => ({
          key: bank.id,
          label: bank.attributes.name,
          value: bank.id,
        })),
      ];
    },
    selectEducationOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Pendidikan',
          value: null,
        },
        ...this.getEducations.data.map((education) => ({
          key: education.id,
          label: education.attributes.name,
          value: education.id,
        })),
      ];
    },
    selectProfessionOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Pekerjaan',
          value: null,
        },
        ...this.getProfessions.data.map((profession) => ({
          key: profession.id,
          label: profession.attributes.name,
          value: profession.id,
        })),
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchAreas: 'areas/fetchAreas',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      fetchBanks: 'banks/fetchBanks',
      createMember: 'members/createMember',
      fetchMember: 'members/fetchMember',
      updateMember: 'members/updateMember',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
      fetchEducations: 'educations/fetchEducations',
      fetchProfessions: 'professions/fetchProfessions',
    }),
    loadBanks: async function () {
      await this.fetchBanks({
        mitra_code: true,
        // fields: {
        // banks: 'mitra_code',
        // },
      });
    },
    loadMember: async function (id) {
      return await this.fetchMember({
        id,
        include: 'bank,addresses,upline-relations,education,profession,area',
        fields: {
          offices:
            'office_type,address,bank_account_name,bank_account_number,bank_branch_name,birthdate,code,temp_code,devisor_name,devisor_birthdate,email,is_active,ktp_number,marital_status,name,npwp_number,npwp_name,phone,postal_code,sex,spouse_name,spouse_birthdate,addresses,upline-relations,bank,area,education,profession,is_kta_need_delivery,upline_histories,downline_histories',
          addresses: 'city_id,district_id,province_id,village_id',
          'upline-relations': 'code,relation_type',
          banks: 'code',
          educations: 'name',
          professions: 'name',
          areas: 'code',
        },
      });
    },
    changeArea(e) {
      this.dataDistributor.area =
        e.target.value < 0 || e.target.value > 4
          ? 0
          : isNaN(e.target.value)
          ? 0
          : Number(e.target.value);
    },
    maritalStats() {
      if (this.dataDistributor.status_pernikahan === 'married') {
        this.selectedMarital = true;
      } else {
        this.selectedMarital = false;
      }
    },
    clearData() {
      this.dataDistributor = {
        id: null,
        nomor: null,
        nama_lengkap: null,
        nama_distributor: null,
        no_nik: null,
        email: null,
        no_hp: null,
        tanggal_lahir: null,
        education_id: null,
        profession_id: null,
        jenis_kelamin: null,
        status_pernikahan: null,
        nama_pasangan: null,
        tanggal_pasangan: null,
        nama_pewaris: null,
        tanggal_pewaris: null,
        alamat: {
          detail: null,
          desa: null,
          kecamatan: null,
          kabupaten: null,
          provinsi: null,
          kode_pos: null,
        },
        bank_id: null,
        bank: {
          nama_bank: null,
          no_rekening: null,
          cabang_bank: null,
          nama_rekening: null,
        },
        no_npwp: null,
        nama_npwp: null,
        is_active: null,
        referal: {
          no_upline: null,
          nama_upline: null,
        },
        area: null,
      };
    },
    setMember(data) {
      const referralOffice = this.getMember.included.find(
        (el) =>
          el.attributes.relation_type === 'referral' &&
          el.type === 'upline-relations'
      );
      this.dataDistributor = {
        id: data.id,
        nomor: data.attributes.code,
        nama_distributor: data.attributes.name,
        email: data.attributes.email,
        nama_lengkap: data.attributes.name,
        no_nik: data.attributes.ktp_number,
        no_hp: data.attributes.phone,
        tanggal_lahir: data.attributes.birthdate
          ? dayjs(data.attributes.birthdate).format('YYYY-MM-DD')
          : undefined,
        jenis_kelamin: data.attributes.sex,
        status_pernikahan: data.attributes.marital_status,
        nama_pasangan: data.attributes.spouse_name,
        tanggal_pasangan: data.attributes.spouse_birthdate,
        nama_pewaris: data.attributes.devisor_name,
        tanggal_pewaris: data.attributes.devisor_birthdate
          ? dayjs(data.attributes.devisor_birthdate).format('YYYY-MM-DD')
          : undefined,
        no_npwp: data.attributes.npwp_number,
        nama_npwp: data.attributes.npwp_name,
        is_active: data.attributes.is_active,
        is_kta_need_delivery: data.attributes.is_kta_need_delivery,
        area: this.getRelationships(
          this.getMember,
          this.getMember.data.relationships.area.data.id
        )?.attributes.code,
        bank_id: this.getMember.data.relationships.bank.data?.id,
        education_id: this.getMember.data.relationships.education.data?.id,
        profession_id: this.getMember.data.relationships.profession.data?.id,
        alamat: data.relationships['addresses'].data[0]
          ? {
              detail: data.attributes.address,
              desa: this.getRelationships(
                this.getMember,
                data.relationships['addresses'].data[0].id
              ).attributes.village_id,
              kecamatan: this.getRelationships(
                this.getMember,
                data.relationships['addresses'].data[0].id
              ).attributes.district_id,
              kabupaten: this.getRelationships(
                this.getMember,
                data.relationships['addresses'].data[0].id
              ).attributes.city_id,
              provinsi: this.getRelationships(
                this.getMember,
                data.relationships['addresses'].data[0].id
              ).attributes.province_id,
              kode_pos: data.attributes.postal_code,
            }
          : {},
        bank: {
          nama_bank: data.attributes.bank_name,
          no_rekening: data.attributes.bank_account_number,
          cabang_bank: data.attributes.bank_branch_name,
          nama_rekening: data.attributes.bank_account_name,
        },
        referal: referralOffice
          ? {
              id: referralOffice.id,
              no_upline: referralOffice.attributes.code,
              nama_upline: referralOffice.attributes.name,
            }
          : {
              id: null,
              no_upline: null,
              nama_upline: null,
            },
      };
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    closeModal() {
      this.success = this.failed = this.confirmation = false;
      this.clearData();
    },
    changeProvince() {
      this.fetchCities({
        province_id: this.dataDistributor.alamat.provinsi,
        pageNumber: 1,
        fields: {
          // 'provinces': 'area_code,name'
        },
      });
      this.dataDistributor.alamat.kabupaten =
        this.dataDistributor.alamat.kecamatan =
        this.dataDistributor.alamat.desa =
          null;
      this.$store.commit('indonesia/setDistricts', this.objectAddress);
      this.$store.commit('indonesia/setVillages', this.objectAddress);
    },
    changeCity() {
      const city = this.getCities.data.find(
        (el) => el.id === this.dataDistributor.alamat.kabupaten
      );
      this.dataDistributor.area = city.attributes.area_code;
      this.fetchDistricts({
        city_id: this.dataDistributor.alamat.kabupaten,
        pageNumber: 1,
      });
      this.dataDistributor.alamat.kecamatan = this.dataDistributor.alamat.desa =
        null;
      this.$store.commit('indonesia/setVillages', this.objectAddress);
    },
    changeDistrict() {
      this.fetchVillages({
        district_id: this.dataDistributor.alamat.kecamatan,
        pageNumber: 1,
      });
      this.dataDistributor.alamat.desa = null;
    },
    searchReferal(value) {
      this.fetchSimpleOffices({
        'page[limit]': 1,
        'filter[office_category_id]': 5,
        'filter[code]': value ?? this.dataDistributor.referal.no_upline,
        include: 'office',
        'fields[simple-offices]': 'office',
        'fields[offices]': 'code,name',
      }).then((response) => {
        if (response.data.data.length) {
          const simpleOffice = response.data.data[0];
          const office = this.getSingleIncluded(
            response.data,
            simpleOffice.relationships.office.data.id
          );

          this.dataDistributor.referal.id = office.id;
          this.dataDistributor.referal.no_upline = office.attributes.code;
          this.dataDistributor.referal.nama_upline = office.attributes.name;
        }
      });
    },
    async createOrUpdateOffice() {
      this.confirmation = false;
      let response = null;
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            office_type: 'member',
            // office_registration_id: StorageService.getUser().office_id,
            office_referral_id: this.dataDistributor.referal.id,
            code: this.dataDistributor.nomor,
            name: this.dataDistributor.nama_distributor,
            pasif: null,
            urut: null,
            address: this.dataDistributor.alamat.detail,
            address2: null,
            // area: this.dataDistributor.area,
            kta: null,
            phone: this.dataDistributor.no_hp,
            email: this.dataDistributor.email,
            pulau: null,
            sex: this.dataDistributor.jenis_kelamin,
            marital_status: this.dataDistributor.status_pernikahan,
            npwp_number: this.dataDistributor.no_npwp,
            npwp_name: this.dataDistributor.nama_npwp,
            ktp_number: this.dataDistributor.no_nik,
            birthdate: this.dataDistributor.tanggal_lahir,
            spouse_name: this.dataDistributor.nama_pasangan,
            spouse_birthdate: this.dataDistributor.tanggal_pasangan,
            devisor_name: this.dataDistributor.nama_pewaris,
            devisor_birthdate: this.dataDistributor.tanggal_pewaris,
            is_suspended: false,
            bank_account_number: this.dataDistributor.bank.no_rekening,
            bank_account_name: this.dataDistributor.bank.nama_rekening,
            bank_name: this.dataDistributor.bank.nama_bank,
            bank_branch_name: this.dataDistributor.bank.cabang_bank,
            postal_code: this.dataDistributor.alamat.kode_pos,
            is_kta_need_delivery: this.dataDistributor.is_kta_need_delivery,
            address_details: [
              {
                province_id: this.dataDistributor.alamat.provinsi,
                city_id: this.dataDistributor.alamat.kabupaten,
                district_id: this.dataDistributor.alamat.kecamatan,
                village_id: this.dataDistributor.alamat.desa,
              },
            ],
          },
          relationships: {
            area: {
              data: {
                type: 'areas',
                id: this.getAreas.data.find(
                  (it) =>
                    it.attributes.code == parseInt(this.dataDistributor.area)
                ).id,
              },
            },
            'office-category': this.member_office_category[0].id
              ? {
                  data: {
                    type: 'office-categories',
                    id: this.member_office_category[0].id,
                  },
                }
              : undefined,
            education: this.dataDistributor.education_id
              ? {
                  data: {
                    type: 'educations',
                    id: this.dataDistributor.education_id,
                  },
                }
              : undefined,
            profession: this.dataDistributor.profession_id
              ? {
                  data: {
                    type: 'professions',
                    id: this.dataDistributor.profession_id,
                  },
                }
              : undefined,
            bank: this.dataDistributor.bank_id
              ? {
                  data: {
                    type: 'banks',
                    id: this.dataDistributor.bank_id,
                  },
                }
              : undefined,
          },
        },
      };
      if (!this.is_detail) {
        response = await this.createMember(payload).then((response) => {
          response ? (this.success = true) : (this.failed = true);
          return response;
        });
      } else {
        payload.data.id = this.dataDistributor.id;
        response = await this.updateMember(payload).then((response) => {
          response ? (this.success = true) : (this.failed = true);
          return response;
        });
      }
      if (response) {
        localStorage.removeItem('offices');
      }
    },
    changeMitraCode(e) {
      const code = e.target.value.split('/')[0].trim();
      this.dataDistributor.nomor = code;
    },
    onNextStep({ validate }) {
      const response = validate();
      if (typeof response === 'object') {
        new Error('Form belum lengkap!');
      } else {
        this.activeStep = this.activeStep + 1;
      }
    },
  },
};
</script>
