import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  banks: {
    data: [],
    meta: {
      page: {},
    },
  },
  bank: {},
  banksByOffice: {
    data: [],
    meta: {
      page: {},
    },
  },
  officeBank: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getBanks: (state) => {
    return state.banks;
  },
  getBank: (state) => {
    return state.bank;
  },
  getBanksByOffice: (state) => {
    return state.banksByOffice;
  },
  getOfficeBank: (state) => {
    return state.officeBank;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setBanks: (state, data) => (state.banks = data),
  setBank: (state, data) => (state.bank = data),
  setBanksByOffice: (state, data) => (state.banksByOffice = data),
  setOfficeBank: (state, data) => (state.officeBank = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchBanks(
    { commit },
    { pageNumber, pageSize, search, bankType, fields, ...others } = {}
  ) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    let page = [];
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    if (bankType && bankType !== '') filter['bank_type'] = bankType;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, { sort: '-createdAt' });
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    try {
      let response = await ApiService.get('api/v1/banks', {
        params: {
          ...params,
          ...others
        },
      });
      commit('setBanks', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setBanks', { data: [], meta: { page: {} } });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async createBank({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/banks', payload);
      commit('setBank', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setBank', {});
    } finally {
      commit('setLoader', false);
    }
  },
  async updateBank({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/banks/' + payload.data.id,
        payload
      );
      commit('setBank', response.data);
      commit('setError', null);

      dispatch(
        'alert/createAlert',
        { data: 'Data bank berhasil diperbarui' },
        { root: true }
      );

      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      if (error?.response?.status === 422 || error?.response?.status === 400) {
        commit('setError', error.response.data.errors[0].detail);
      } else if (error?.response?.status === 404) {
        commit('setError', error.response.data.errors[0].title);
      } else {
        commit('setError', error.response.data.errors);
      }
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchBanksByOffice(
    { commit },
    { office_id, type, pageNumber, pageSize, isActive, fields, ...others }
  ) {
    commit('setLoader', true);
    try {
      const params = { ...others };
      const filter = [];
      let page = [];

      if (isActive && isActive !== '') filter['is_active'] = isActive;
      if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
      if (pageSize) page['size'] = pageSize;
      if (type && type !== '') filter['office_bank_type'] = type;

      Object.keys(page).map((item) => {
        const key = `page[${item}]`;
        Object.assign(params, { [key]: page[item] });
      });

      Object.keys(filter).map((item) =>
        Object.assign(params, { [`filter[${item}]`]: filter[item] })
      );

      if (fields) {
        Object.entries(fields).forEach(([prop, value]) => {
          Object.assign(params, {
            [`fields[${prop}]`]: value,
          });
        });
      }

      let response = await ApiService.get(
        'api/v1/offices/' + office_id + '/office-banks',
        {
          params,
        }
      );
      commit('setBanksByOffice', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setBanksByOffice', {});
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async createOfficeBank({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/office-banks', payload);
      commit('setOfficeBank', response.data);
      commit('setError', null);
      commit('setLoader', false);
      dispatch(
        'alert/createAlert',
        {
          data: 'Metode pembayaran berhasil dibuat',
          status: 'success',
        },
        { root: true }
      );
      return response;
    } catch (error) {
      commit('setOfficeBank', {});
      commit('setError', error.response.data.errors[0].detail);
      dispatch(
        'alert/createAlert',
        { data: 'Metode pembayaran gagal dibuat', status: 'error' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async updateOfficeBank({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/office-banks/' + payload.data.id,
        payload
      );
      commit('setOfficeBank', response.data);
      commit('setError', null);
      commit('setLoader', false);
      dispatch(
        'alert/createAlert',
        {
          data: 'Metode pembayaran berhasil diperbaharui',
          status: 'success',
        },
        { root: true }
      );
      return response;
    } catch (error) {
      commit('setOfficeBank', {});
      commit('setError', error.response.data.errors[0].detail);
      dispatch(
        'alert/createAlert',
        { data: error.response.data.errors[0].detail, status: 'error' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
  async deleteOfficeBank({ commit, dispatch }, id) {
    commit('setLoader', true);
    try {
      let response = await ApiService.delete('api/v1/office-banks/' + id);
      commit('setOfficeBank', response.data);
      commit('setError', null);
      commit('setLoader', false);
      dispatch(
        'alert/createAlert',
        { data: 'Bank berhasil dihapus', status: 'success' },
        { root: true }
      );
      return response;
    } catch (error) {
      commit('setOfficeBank', {});
      commit('setError', error.response.data.errors[0].detail);
      dispatch(
        'alert/createAlert',
        { data: error.response.data.errors[0].detail, status: 'error' },
        { root: true }
      );
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
