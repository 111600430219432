<template>
  <div
    @click="click"
    style="
      right: -20px;
      top: 45px;
      width: 40px;
      height: 40px;
      border-radius: 10px;
    "
    class="absolute flex cursor-pointer items-center justify-center bg-white p-2 shadow-md hover:bg-gray-50"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M4.58273 1.13807C4.84308 0.877722 4.84308 0.455612 4.58273 0.195262C4.32238 -0.0650874 3.90027 -0.0650874 3.63992 0.195262L0.30659 3.5286C0.0462407 3.78894 0.0462407 4.21106 0.30659 4.4714L3.63992 7.80474C3.90027 8.06509 4.32238 8.06509 4.58273 7.80474C4.84308 7.54439 4.84308 7.12228 4.58273 6.86193L1.7208 4L4.58273 1.13807Z"
        fill="black"
      />
      <path
        d="M9.91607 0.195262C9.65572 -0.0650874 9.23361 -0.0650874 8.97326 0.195262C8.71291 0.455612 8.71291 0.877722 8.97326 1.13807L11.8352 4L8.97326 6.86193C8.71291 7.12228 8.71291 7.54439 8.97326 7.80474C9.23361 8.06509 9.65572 8.06509 9.91607 7.80474L13.2494 4.4714C13.5097 4.21106 13.5097 3.78894 13.2494 3.5286L9.91607 0.195262Z"
        fill="black"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'SidebarButton',
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
