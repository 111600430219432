import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  isLoadingPaymentMethods: false,
  isLoadingReportMeta: false,
  error: null,
  payments: {
    data: [],
    meta: {
      page: {},
    },
  },
  paymentMethods: {
    data: [],
    meta: {
      page: {},
    },
  },
  payment: {
    data: {
      attributes: {
        code: '',
        createdAt: '',
      },
    },
  },
  reportMeta: {
    total_amount: 0,
    total_grand_total_price: 0
  }
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getLoadingPaymentMethods: (state) => {
    return state.isLoadingPaymentMethods;
  },
  getLoadingReportMeta: (state) => {
    return state.isLoadingReportMeta;
  },
  getPayments: (state) => {
    return state.payments;
  },
  getPayment: (state) => {
    return state.payment;
  },
  getError: (state) => {
    return state.error;
  },
  getPaymentMethods: (state) => state.paymentMethods,
  getReportMeta: (state) => state.reportMeta,
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setLoaderPaymentMethods: (state, status) =>
    (state.isLoadingPaymentMethods = status),
  setLoaderReportMeta: (state, status) =>
    (state.isLoadingReportMeta = status),
  setReportMeta: (state, status) =>
    (state.reportMeta = status),
  setPayments: (state, data) => (state.payments = data),
  setPaymentMethods: (state, data) => (state.paymentMethods = data),
  setPayment: (state, data) => (state.payment = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchPayments(
    { commit },
    { keyword, pageNumber, pageSize, search, sort, include, fields, office_id, ...others }
  ) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    let page = [];
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    if (office_id && office_id !== '') filter['office_id'] = office_id;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    Object.assign(params, { include: include ?? 'order,bank' });
    Object.assign(params, { sort: sort ?? '-createdAt' });
    try {
      let response = await ApiService.get('api/v1/payments', {
        params: {
          ...params,
          ...others
        },
      });
      commit('setPayments', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setPayments', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchPayment({ commit }, { id, include, fields }) {
    commit('setLoader', true);

    const params = {
      include,
    };

    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    try {
      let response = await ApiService.get('api/v1/payments/' + id, {
        params,
      });
      commit('setPayments', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setPayments', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchPaymentPaymentMethods({ commit }, { id, include, fields, params }) {
    commit('setLoaderPaymentMethods', true);

    const query = {
      include,
      ...params
    };

    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(query, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    try {
      const res = await ApiService.get(
        `api/v1/payments/${id}/payment-methods`,
        { params: query }
      );

      commit('setPaymentMethods', res.data);
      commit('setError', false);

      return res.data;
    } catch (err) {
      commit('setPaymentMethods', {
        data: [],
        meta: {
          page: {},
        },
      });

      commit('setError', err);
    } finally {
      commit('setLoaderPaymentMethods', false);
    }
  },
  async createPayment({ commit, dispatch }, payload) {
    commit('setLoader', true);
    let params = {};

    if (payload.include) {
      params = { include: payload.include };
      delete payload.include; // dihapus properties nya krn tdk dibutuhkan utk payload
    }

    try {
      let response = await ApiService.post('api/v1/payments', payload, {
        params,
      });
      commit('setPayment', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          'alert/createAlert',
          { data: error.response.data.errors[0].detail, type: 'warning' },
          { root: true }
        );
      } else {
        dispatch(
          'alert/createAlert',
          { data: error.response.data, type: 'warning' },
          { root: true }
        );
      }

      commit('setError', error.response.data.errors);
      commit('setPayment', {});
    } finally {
      commit('setLoader', false);
    }
  },
  async updatePayment({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/payments/' + payload.data.id,
        payload
      );
      commit('setPayment', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      commit('setPayment', {});
    }
    commit('setLoader', false);
  },
  async verifyPayment({ commit }, { paymentId }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        `api/v1/payments/${paymentId}/-actions/verify`
      );
      commit('setPayment', response.data);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      commit('setPayment', {});
    }
    commit('setLoader', false);
  },
  async fetchReportMeta({ commit }, { params }) {
    commit('setLoaderReportMeta', true);
    try {
      const response = await ApiService.get(
        `api/v1/payments/-actions/get-report-meta`,
        { params }
      );

      commit('setReportMeta', response.data);

      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
      commit('setReportMeta', {});
    } finally {
      commit('setLoaderReportMeta', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
