<template>
  <footer
    :style="{ position: this.$route.fullPath == '/' ? 'fixed' : 'static' }"
    class="no-print flex items-center justify-center rounded-lg bg-white p-4 shadow dark:bg-gray-900 md:px-6"
  >
    <span class="block text-sm text-gray-500 dark:text-gray-400 sm:text-center"
      >&copy; {{ new Date().getFullYear() }} PT. Natural Nusantara. All Rights
      Reserved. v{{ version }}
    </span>
  </footer>
</template>
<script>
import { version } from '../../package.json';
export default {
  name: 'TheFooter',
  data() {
    return {
      version,
    };
  },
};
</script>
