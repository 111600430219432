import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  isLoadingCurrentPeriod: false,
  isLoadingCloseBook: false,
  error: null,
  mitraPeriods: {
    data: [],
    meta: {
      page: {
        total: 0,
        perPage: 0,
        currentPage: 0,
      },
    },
  },
  mitraPeriod: {
    data: {},
    meta: {
      page: {
        total: 0,
        perPage: 0,
        currentPage: 0,
      },
    },
  },
  currentPeriod: {
    data: {},
    meta: {
      page: {
        total: 0,
        perPage: 0,
        currentPage: 0,
      },
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getLoadingCurrentPeriod: (state) => {
    return state.isLoadingCurrentPeriod;
  },
  getLoadingCloseBook: (state) => {
    return state.isLoadingCloseBook;
  },
  getMitraPeriods: (state) => {
    return state.mitraPeriods;
  },
  getMitraPeriod: (state) => {
    return state.mitraPeriod;
  },
  getCurrentPeriod: (state) => {
    return state.currentPeriod;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setLoaderCurrentPeriod: (state, status) =>
    (state.isLoadingCurrentPeriod = status),
  setLoaderCloseBook: (state, status) => (state.isLoadingCloseBook = status),
  setMitraPeriods: (state, data) => (state.mitraPeriods = data),
  setMitraPeriod: (state, data) => (state.mitraPeriod = data),
  setCurrentPeriod: (state, data) => (state.currentPeriod = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchMitraPeriods({ commit }, params) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get('api/v1/mitra-periods', {
        params,
      });
      commit('setMitraPeriods', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setMitraPeriods', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchMitraPeriod({ commit }, { id, params }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(`api/v1/mitra-periods/${id}`, {
        params,
      });
      commit('setMitraPeriod', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setMitraPeriod', {
        data: {},
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchCurrentMitraPeriod({ commit }, { params } = {}) {
    commit('setLoaderCurrentPeriod', true);
    try {
      let response = await ApiService.get(
        `api/v1/mitra-periods/-actions/get-current-period`,
        {
          params,
        }
      );
      commit('setCurrentPeriod', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setCurrentPeriod', {
        data: {},
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    } finally {
      commit('setLoaderCurrentPeriod', false);
    }
  },
  async createMitraPeriod({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/mitra-periods', payload);
      commit('setPeriod', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setPeriod', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async updateMitraPeriod({ commit, dispatch }, payload) {
    if (payload.setLoader ?? true) {
      commit('setLoader', true);
    }

    try {
      let response = await ApiService.patch(
        'api/v1/mitra-periods/' + payload.data.id,
        payload
      );
      dispatch(
        'alert/createAlert',
        { data: 'Periode berhasil diubah' },
        { root: true }
      );
      commit('setMitraPeriod', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async closeBook({ commit, dispatch }) {
    commit('setLoaderCloseBook', true);

    try {
      let response = await ApiService.post(
        'api/v1/mitra-periods/-actions/close-mitra-period'
      );

      dispatch(
        'alert/createAlert',
        { data: 'Tutup buku berhasil' },
        { root: true }
      );

      commit('setError', null);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data },
        { root: true }
      );
      commit('setError', error);
    } finally {
      commit('setLoaderCloseBook', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
