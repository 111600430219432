<template>
  <base-wrapper>
    <div class="space-y-8 py-4">
      <div class="space-y-4">
        <h1 class="text-2xl font-bold leading-6 text-gray-900">
          Informasi Saldo
        </h1>
        <Stats />
      </div>
      <div class="space-y-4">
        <h3 class="text-2xl font-bold leading-6 text-gray-900">
          Riwayat Transaksi Saldo Stockist
        </h3>
        <div class="space-y-4">
          <datatable
            :columns="columns"
            :data="balanceTrxes.data"
            :scroll-x="false"
            :total="balanceTrxes.meta.page.total"
            :perPage="balanceTrxes.meta.page.perPage"
            :currentPage="balanceTrxes.meta.page.currentPage"
            @pagechanged="onPageChanged"
          >
            <template v-slot:tbody="{ classes }">
              <tr
                v-for="(balanceTrx, index) in balanceTrxes.data"
                :key="balanceTrx.id"
                :class="[
                  classes.tr,
                  index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                  'bg-white hover:bg-green-100',
                ]"
              >
                <td :class="[classes.td]">
                  {{ balanceTrx.attributes.createdAt | formatDate }}
                </td>
                <td :class="classes.td">
                  {{ balanceTrx.attributes.description }}
                </td>
                <!-- debit -->
                <td :class="classes.td">
                  <span v-if="balanceTrx.attributes.order_balance_amount < 0">
                    {{
                      Math.abs(balanceTrx.attributes.order_balance_amount ?? 0)
                        | toCurrency
                    }}
                  </span>
                  <span v-else> - </span>
                </td>
                <!-- kredit -->
                <td :class="classes.td">
                  <span v-if="balanceTrx.attributes.order_balance_amount > 0">
                    {{
                      Math.abs(balanceTrx.attributes.order_balance_amount ?? 0)
                        | toCurrency
                    }}
                  </span>
                  <span v-else> - </span>
                </td>
                <td :class="classes.td">
                  {{ balanceTrx.attributes.order_balance | toCurrency }}
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </div>
    </div>
  </base-wrapper>
</template>

<script>
import Stats from './Stats.vue';
import { mapGetters, mapActions  } from 'vuex';
import { requestMixin } from '@/mixins/request/request';

export default {
  name: 'MyBalance',
  mixins: [requestMixin],
  components: { Stats },
  data() {
    return {
      balanceTrxes: {
        data: [],
        meta: {
          page: {
            total: 0
          }
        }
      },
    }
  },
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
      getPeriod: 'periods/getPeriod',
      me: 'auth/getUser'
    }),
    columns() {
      return [
        { id: 'date', name: 'Tanggal' },
        { id: 'description', name: 'Deskripsi' },
        { id: 'debt', name: 'Debit' },
        { id: 'credit', name: 'Kredit' },
        { id: 'balance', name: 'Saldo' },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchOfficeById: 'offices/fetchOfficeById',
    }),
    async loadMe() {
      await this.fetchOfficeById({
        office_id: this.me.office_id,
        params: {
          'fields[offices]': 'balance,order_balance',
        },
      })
    },
    async loadBalanceTrxes(params = {}) {
      this.loading = true;

      const [res, error] = await this.request(`/api/v1/offices/${this.me.office_id}/balance-trxes`, {
        params: {
          'page[size]': 10,
          sort: '-createdAt',
          'filter[balance_type]': 'order_balance',
          ...params
        },
      });

      if (!error) {
        this.balanceTrxes = res;
      }

      this.loading = false;
    },
    onFilter() {
    },
    onPageChanged(page) {
      this.loadBalanceTrxes({
        'page[number]': page
      })
    },
  },
  async created() {
    await Promise.all([this.loadMe(),  this.loadBalanceTrxes()])
  },
};
</script>