export function defaultLink(name) {
  if (name === 'Home') {
    return 'bg-blue-600';
  } else if (name === 'Pusat') {
    return 'hover:bg-yellow-700 hover:bg-opacity-75 bg-opacity-50';
  } else if (name === 'Kantor Pelayanan') {
    return 'hover:bg-purple-700 hover:bg-opacity-75 bg-opacity-50';
  } else if (name === 'Stockist Center') {
    return 'hover:bg-blue-700 hover:bg-opacity-75 bg-opacity-50';
  } else if (name === 'Stockist') {
    return 'hover:bg-green-700 hover:bg-opacity-75 bg-opacity-50';
  } else if (name === 'Member') {
    return 'hover:bg-red-700 hover:bg-opacity-75 bg-opacity-50';
  } else {
    return 'bg-white text-indigo-600 bg-opacity-50';
  }
}
export function subfolderActive(name) {
  if (name === 'Home') {
    return 'bg-blue-600';
  } else if (name === 'Pusat') {
    return 'bg-yellow-600 bg-opacity-50 hover:bg-opacity-75 text-white';
  } else if (name === 'Kantor Pelayanan') {
    return 'bg-purple-600 bg-opacity-50 hover:bg-opacity-75 text-white';
  } else if (name === 'Stockist Center') {
    return 'bg-blue-600 bg-opacity-50 hover:bg-opacity-75 text-white';
  } else if (name === 'Stockist') {
    return 'bg-green-600 bg-opacity-50 hover:bg-opacity-75 text-white';
  } else if (name === 'Member') {
    return 'bg-red-600 bg-opacity-50 hover:bg-opacity-75 text-white';
  } else {
    return 'bg-indigo-600 bg-opacity-50 hover:bg-opacity-75 text-white';
  }
}
export function activeColor(name) {
  if (name === 'Home') {
    return 'bg-blue-600';
  } else if (name === 'Pusat') {
    return 'bg-yellow-600 bg-opacity-50 hover:bg-opacity-75 text-white font-medium';
  } else if (name === 'Kantor Pelayanan') {
    return 'bg-purple-600 bg-opacity-50 hover:bg-opacity-75 text-white font-medium';
  } else if (name === 'Stockist Center') {
    return 'bg-blue-600 bg-opacity-50 hover:bg-opacity-75 text-white font-medium';
  } else if (name === 'Stockist') {
    return 'bg-green-600 bg-opacity-50 hover:bg-opacity-75 text-white font-medium';
  } else if (name === 'Member') {
    return 'bg-red-600 bg-opacity-50 hover:bg-opacity-75 text-white font-medium';
  } else {
    return 'bg-white text-indigo-700';
  }
}
export function bgColor(name) {
  if (name === 'Home') {
    return 'bg-blue-600';
  } else if (name === 'Pusat') {
    return 'bg-yellow-400';
  } else if (name === 'Kantor Pelayanan') {
    return 'bg-purple-500';
  } else if (name === 'Stockist Center') {
    return 'bg-blue-600';
  } else if (name === 'Stockist') {
    return 'bg-green-600';
  } else if (name === 'Member') {
    return 'bg-red-600';
  } else {
    return 'bg-indigo-400';
  }
}
export function borderColor(name) {
  if (name === 'Home') {
    return 'border-blue-600';
  } else if (name === 'Pusat') {
    return 'border-yellow-800 hover:border-yellow-800';
  } else if (name === 'Kantor Pelayanan') {
    return 'border-purple-800';
  } else if (name === 'Stockist Center') {
    return 'border-green-600';
  } else if (name === 'Stockist') {
    return 'border-green-600';
  } else if (name === 'Member') {
    return 'border-grey-600';
  } else {
    return 'border-grey-600';
  }
}
export function textColor(name) {
  if (name === 'Home') {
    return 'text-blue-600';
  } else if (name === 'Pusat') {
    return 'text-white';
  } else if (name === 'Kantor Pelayanan') {
    return 'text-white';
  } else if (name === 'Stockist Center') {
    return 'text-white';
  } else if (name === 'Stockist') {
    return 'text-white';
  } else if (name === 'Member') {
    return 'text-white';
  } else {
    return 'text-white';
  }
}
