const LayoutDefault = () =>
  import(/* webpackChunkName: "mitra-usaha" */ '@/layouts/LayoutDefault.vue');

const Dashboard = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/member/bonus/Dashboard.vue'
  );

let root = '/bonus';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Bonus',
      layout: LayoutDefault,
    },
  },
];
