<template>
  <div class="mx-auto mt-4 flex flex-col gap-y-6 px-4 pb-12 sm:px-4 lg:px-8">
    <div>
      <div>
        <div class="sm:hidden">
          <select
            id="tabs"
            name="tabs"
            v-model="tabActive"
            class="block w-full rounded-md border-gray-300 focus:border-green-600 focus:ring-green-600"
          >
            <option v-for="(tab, index) in tabs" :key="index" :value="tab">
              {{ tab }}
            </option>
          </select>
        </div>
        <div class="hidden sm:block">
          <nav
            class="relative z-0 flex rounded-t-lg border-b border-gray-200 bg-white shadow"
            aria-label="Tabs"
          >
            <button
              v-for="(tab, index) in tabs"
              :key="index"
              @click="onClickTab(tab)"
              type="button"
              class="group relative min-w-0 max-w-xs flex-1 overflow-hidden rounded-l-lg border-r border-gray-200 bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
              :class="tab === tabActive ? 'text-green-600' : 'text-gray-500'"
              aria-current="page"
            >
              <span>{{ tab }}</span>
              <span
                v-if="tab === tabActive"
                aria-hidden="true"
                class="absolute inset-x-0 bottom-0 h-0.5 bg-green-600"
              ></span>
            </button>
          </nav>
        </div>
      </div>
      <div class="rounded-b-lg bg-white p-8 shadow">
        <template v-if="tabActive === 'Preferensi'">
          <Preference />
        </template>
        <template v-else-if="tabActive === 'Akun User'">
          <Profile />
        </template>
        <template v-else-if="tabActive === 'Profile'">
          <editOfficeForm
            route-after-success="/settings"
            title="Edit Data Profile"
            view-as="stockist"
            disabled
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import Preference from '@/components/settings/preference.vue';
import Profile from '@/components/settings/profile.vue';
import editOfficeForm from '@/components/office/edit-office-form.vue';

export default {
  name: 'Settings',
  components: {
    Preference,
    Profile,
    editOfficeForm,
  },
  data() {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    return {
      tabs: ['Akun User', 'Profile', 'Preferensi'],
      tabActive: 'Akun User',
      preferences: preferences
        ? preferences
        : {
            newThemes: false,
          },
    };
  },
  created() {
    const handleEscape = () => {
      this.showProfile = false;
      this.showSidebar = false;
      this.showRole = false;
      this.showMenu = false;
    };
    this.setEscape(handleEscape);
  },
  methods: {
    dayjs,
    ...mapActions({}),
    onClickTab(tab) {
      this.tabActive = tab;
      this.$router.push({
        name: 'settings.office',
        params: {
          id: this.getUser.office_id,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      getBanksByOffice: 'banks/getBanksByOffice',
      getBanks: 'banks/getBanks',
      getError: 'setups/getError',
      getUser: 'auth/getUser',
    }),
  },
};
</script>
