/**
 * Usage:
 *
 * dispatch('alert/createAlert', { data: error.response.data }, { root: true })
 *
 */
const state = {
  data: null,
  status: null, // enum: 'success', 'warning', 'error'
};

const getters = {
  getStatus: (state) => {
    return state.status;
  },
  getData: (state) => state.data,
};

const mutations = {
  setData: (state, payload) => (state.data = payload),
  setType: (state, payload) => (state.status = payload),
};

const actions = {
  async createAlert({ commit }, { data, status }) {
    commit('setData', data);
    commit('setType', status);
    // type ? commit("setType", type) : null;
    setTimeout(() => {
      commit('setData', false);
    }, 5000);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
