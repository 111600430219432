import Admin from './admin.route';
import Gudang from './gudang.route';
import Kasir from './kasir.route';
import Penjualan from './penjualan.route';
import Bonus from './bonus.route';
import StockStockist from './stock-stockist.route';

let pusat = [];

pusat = pusat.concat(Admin, Gudang, Kasir, Penjualan, Bonus, StockStockist);

export default pusat;
