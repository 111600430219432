import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  isLoadingExport: false,
  error: null,
  products: {
    data: [],
    meta: {
      page: {},
    },
  },
  productsByOffice: {
    data: [],
    meta: {
      page: {},
    },
  },
  product: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getProducts: (state) => {
    return state.products;
  },
  getProductsByOffice: (state) => {
    return state.productsByOffice;
  },
  getProduct: (state) => {
    return state.product;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setLoaderExport: (state, status) => (state.isLoadingExport = status),
  setProducts: (state, data) => (state.products = data),
  setProductsByOffice: (state, data) => (state.productsByOffice = data),
  setProduct: (state, data) => (state.product = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchProducts(
    { commit },
    {
      keyword,
      pageNumber,
      pageSize,
      search,
      sortName,
      sortDirection,
      fields,
      include,
      orderStartDate,
      orderEndDate,
      reportMode = false,
      office_id,
      productCategoryId,
      ...others
    } = {}
  ) {
    commit('setLoader', true);
    commit('setProducts', {
      data: [],
      meta: {
        page: {},
      },
    });
    const params = { ...others };
    let filter = [];
    let page = [];
    sortName = sortName || 'createdAt';
    sortDirection = sortDirection || 'desc';
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    if (sortDirection === 'desc') sortName = '-' + sortName;
    if (orderStartDate && orderStartDate !== '')
      filter['order_start_date'] = orderStartDate;
    if (orderEndDate && orderEndDate !== '')
      filter['order_end_date'] = orderEndDate;
    if (reportMode) filter['report_mode'] = reportMode;
    if (productCategoryId) filter['product_category_id'] = productCategoryId;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }

    if (include || include == '') {
      Object.assign(params, {
        include,
      });
    } else {
      Object.assign(params, {
        include: 'product-limits,office,product-prices,product-category,unit',
      });
    }

    Object.assign(params, { sort: sortName });
    try {
      let response = await ApiService.get(
        office_id
          ? 'api/v1/offices/' + office_id + '/products'
          : 'api/v1/products',
        {
          params,
        }
      );
      commit('setProducts', response.data);
      return response;
    } catch (error) {
      commit('setProducts', { data: [] });
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchProductById({ commit }, { id, fields, include }) {
    commit('setLoader', true);
    const params = {};
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    if (include || include == '') {
      Object.assign(params, {
        include,
      });
    } else {
      Object.assign(params, {
        include: 'product-limits,office,product-prices,product-category,unit',
      });
    }
    try {
      let response = await ApiService.get(`api/v1/products/${id}`, { params });
      commit('setProduct', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async createProduct({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/products', payload);
      commit('setProduct', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async updateProduct({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/products/' + payload.data.id,
        payload
      );
      commit('setProduct', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
    commit('setLoader', false);
  },
  async updateProductWithParams({ commit, dispatch }, { payload, params }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/products/' + payload.data.id,
        payload,
        {
          params,
        }
      );

      dispatch(
        'alert/createAlert',
        { data: 'Produk Diperbarui', type: 'success' },
        { root: true }
      );

      commit('setProduct', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );

      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },

  async deleteProduct({ commit }, { id }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch('api/v1/products/' + id, {
        data: {
          type: 'products',
          id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      });
      commit('setProduct', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    } finally {
      commit('setLoader', false);
    }
  },
  async exportProducts({ commit }) {
    commit('setLoaderExport', true);
    try {
      return await ApiService.get(`api/v1/products/-actions/exportProduct`);
    } catch (_error) {
      // commit('setProducts', { data: [] });
    } finally {
      commit('setLoaderExport', false);
    }
  },
  async exportProductSaleReport(_, { params }) {
    try {
      return await ApiService.get(`api/v1/products/-actions/export-sales`, {
        params,
      });
    } catch (_error) {
      // commit('setProducts', { data: [] });
    }
  },
  async downloadProducts({ commit }) {
    commit('setLoaderExport', true);
    try {
      return await ApiService.customRequest({
        method: 'GET',
        responseType: 'blob',
        url: `${process.env.VUE_APP_API_URL}/api/v1/products/-actions/downloadProduct`,
      });
    } catch (_error) {
      // commit('setProducts', { data: [] });
    } finally {
      commit('setLoaderExport', false);
    }
  },
  async fetchProductsByOffice(
    { commit },
    { office_id, keyword, pageNumber, pageSize, search, include, fields }
  ) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    let page = [];
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    if (fields) {
      Object.entries(fields).forEach(([prop, value]) => {
        Object.assign(params, {
          [`fields[${prop}]`]: value,
        });
      });
    }
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });

    Object.assign(params, {
      include:
        include ?? 'product-limits,office,product-prices,product-category,unit',
    });
    Object.assign(params, { sort: '-createdAt' });
    let response = await ApiService.get(
      'api/v1/offices/' + office_id + '/products',
      {
        params,
      }
    );
    commit('setProductsByOffice', response.data);
    commit('setLoader', false);
  },
  async fetchProductPrices({ commit }, { productId }) {
    try {
      const res = await ApiService.get(`/api/v1/products/${productId}/product-prices`)

      commit('setError', null)

      return res
    } catch (err) {
      commit('setError', err)
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
