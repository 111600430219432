import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  menus: {
    data: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getMenus: (state) => {
    return state.menus;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setMenus: (state, data) => (state.menus = data),
};

const actions = {
  async fetchMenus({ commit }, { search }) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    let page = [];

    if (search !== undefined && search !== null) filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, { include: 'addresses' });
    Object.assign(params, { sort: '-createdAt' });

    let response = await ApiService.get('api/v1/menus', {
      params: {
        'filter[owned]': true,
        'filter[is_disabled]' : false
      }
    });
    commit('setMenus', response.data);
    commit('setLoader', false);
    return response.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
