<template>
  <div class="transition duration-300 ease-in-out">
    <div class="grid grid-cols-6 space-y-8">
      <p class="col-span-3">Tampilkan Sidebar (Beta)</p>
      <div
        style="margin: 0"
        class="col-span-3 m-0 flex items-start justify-end"
      >
        <toggle-button
          v-model="preferences.newThemes"
          @change="onChangeTheme"
          :labels="{
            checked: 'Aktif',
            unchecked: 'Non Aktif',
          }"
          :width="preferences.newThemes ? 60 : 80"
        />
      </div>
      <p class="col-span-3"></p>
      <p class="col-span-3 flex justify-end">
        <base-button
          class="tooltip"
          @click="onDeleteLocalStorage"
          color="white"
        >
          Hapus Cache
          <p class="tooltiptext" style="width: 120px">
            Digunakan untuk menghapus localstorage pada aplikasi
          </p>
        </base-button>
      </p>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/base/BaseButton.vue';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  name: 'Preference',
  components: {
    ToggleButton,
    BaseButton,
  },
  props: {},
  data() {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    return {
      preferences: preferences
        ? preferences
        : {
            newThemes: false,
          },
    };
  },
  methods: {
    onChangeTheme() {
      localStorage.setItem('preferences', JSON.stringify(this.preferences));
      if (this.preferences.newThemes) {
        document.documentElement.style.fontFamily = 'Inter';
      } else {
        document.documentElement.style.fontFamily = 'Poppins';
      }
      location.reload();
    },
    onDeleteLocalStorage() {
      // Define the keys you want to keep
      const keysToKeep = ['access_token', 'refresh_token'];

      // Iterate through localStorage keys
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        // Check if the key is not in the keysToKeep array
        if (!keysToKeep.includes(key)) {
          // If not in keysToKeep, remove the item from localStorage
          localStorage.removeItem(key);
        }
      }
    },
  },
};
</script>
