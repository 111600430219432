<template>
  <modal
    :showing="visible"
    @close="close"
    :showClose="true"
    :backgroundClose="true"
    size="max-w-xl"
  >
    <div class="p-6 text-center">
      <svg
        class="mx-auto mb-4 h-12 w-12 text-gray-400 dark:text-gray-200"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
        Sistem kami mendeteksi bahwa akun anda belum mengganti password, segera
        perbarui password anda di menu
        <span class="cursor-pointer text-blue-600 underline"> profile </span>
      </h3>
      <BaseButton @click="toProfile"> Oke, saya mengerti </BaseButton>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/base/BaseModal.vue';
import BaseButton from '@/components/base/BaseButton.vue';
export default {
  components: {
    Modal,
    BaseButton,
  },
  props: {
    visible: {
      required: true,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toProfile() {
      this.$router.push('/my-profile');
    },
  },
};
</script>
