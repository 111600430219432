<template>
  <div>
    <stockist-balance v-if="role === 'stockist'" />
    <stockist-center-balance v-else />

    <loading v-if="isLoading" />
  </div>
</template>

<script>
import StockistBalance from '@/components/balance/stockist/MyBalance.vue';
import StockistCenterBalance from '@/components/balance/stockist-center/MyBalance.vue';
import { StorageService } from '@/services/storage.service';
import { mapGetters } from 'vuex';

export default {
  name: 'MyBalance',
  components: { StockistBalance, StockistCenterBalance },
  computed: {
    ...mapGetters({
      getOfficesLoading: 'offices/getLoading',
      getPeriodsLoading: 'periods/getLoading',
      getBalanceTrxesLoading: 'balance_trxes/getLoading',
      getLoadingOrderPaymentReportMeta: 'offices/getLoadingOrderPaymentReportMeta',
      getLoadingOrderPaymentReportHistory: 'offices/getLoadingOrderPaymentHistory'
    }),
    role() {
      return StorageService.getUser().current_role.toLowerCase();
    },
    isLoading() {
      return this.getBalanceTrxesLoading || this.getOfficesLoading || this.getPeriodsLoading || this.getLoadingOrderPaymentReportMeta || this.getLoadingOrderPaymentReportHistory;
    },
  },
};
</script>
