const LayoutDefault = () =>
  import(/* webpackChunkName: "kp-kasir" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "kp-kasir" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "kp-kasir" */ '@/views/pelayanan/kasir/Index.vue'
  );

// Faktur
const FakturPenjualanPusat = () =>
  import(
    /* webpackChunkName: "kp-kasir" */ '@/views/pelayanan/kasir/pembayaran/faktur-penjualan-pusat/Index.vue'
  );
const DetailPembayaran = () =>
  import(
    /* webpackChunkName: "kp-kasir" */ '@/views/pelayanan/kasir/pembayaran/faktur-penjualan-pusat/DetailPembayaran.vue'
  );
const DetailPenjualan = () =>
  import(
    /* webpackChunkName: "kp-kasir" */ '@/views/pelayanan/kasir/pembayaran/faktur-penjualan-pusat/DetailPenjualan.vue'
  );

const Laporan = () =>
  import(
    /* webpackChunkName: "kp-kasir" */ '@/views/pelayanan/kasir/laporan/Index.vue'
  );
const TambahLaporan = () =>
  import(
    /* webpackChunkName: "kp-kasir" */ '@/views/pelayanan/kasir/laporan/TambahLaporan.vue'
  );
const LaporanRestock = () =>
  import(
    /* webpackChunkName: "kp-kasir" */ '@/views/pelayanan/kasir/laporan/restock/index.vue'
  );
const TambahLaporanRestock = () =>
  import(
    /* webpackChunkName: "kp-kasir" */ '@/views/pelayanan/kasir/laporan/restock/Tambah.vue'
  );
const LaporanSC = () =>
  import(
    /* webpackChunkName: "kp-kasir" */ '@/views/pelayanan/kasir/pembayaran/laporan-sc/Index.vue'
  );

// Laporan Penjualan KP
const LaporanPenjualanKP = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/kasir/laporan-penjualan-kp/Index.vue'
  );
const TambahLaporanPenjualanKP = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/kasir/laporan-penjualan-kp/Tambah.vue'
  );
const LaporanViaKP = () =>
  import(
    /* webpackChunkName: "kp" */ '@/views/pelayanan/kasir/laporan-via-kp/Index.vue'
  );

let root = '/kasir';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Kasir',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/pembayaran/',
    component: FakturPenjualanPusat,
    meta: {
      breadcrumb: 'Faktur Penjualan KP | Kasir',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/pembayaran/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: LayoutData,
        meta: {
          breadcrumb: 'Faktur Penjualan KP',
        },
        children: [
          {
            path: ':id',
            component: DetailPenjualan,
            meta: {
              auth: true,
              title: 'Detail Faktur Penjualan KP | Kasir',
              layout: LayoutDefault,
            },
          },
          {
            path: '',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Penjualan',
            },
            children: [
              {
                path: 'faktur-pembayaran/:id_pembayaran',
                component: DetailPembayaran,
                meta: {
                  auth: true,
                  title: 'Detail Faktur Pembayaran | Kasir',
                  layout: LayoutDefault,
                  breadcrumb: 'Detail Faktur Pembayaran',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'laporan-sc/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Validasi Laporan SC',
        },
        children: [
          {
            path: '',
            component: LaporanSC,
            meta: {
              auth: true,
              title: 'Laporan SC | Kasir',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/laporan/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: Laporan,
        meta: {
          auth: true,
          title: 'Laporan | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-laporan',
        component: TambahLaporan,
        meta: {
          auth: true,
          title: 'Tambah Faktur SC | Penjualan Pusat',
          layout: LayoutDefault,
        },
      },
      {
        path: 'restock/',
        component: LayoutData,
        children: [
          {
            path: '',
            component: LaporanRestock,
            meta: {
              auth: true,
              title: 'Restock | Penjualan Pusat',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah-restock',
            component: TambahLaporanRestock,
            meta: {
              auth: true,
              title: 'Tambah Restock | Penjualan Pusat',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahLaporanRestock,
            meta: {
              auth: true,
              title: 'Edit Restock | Penjualan Pusat',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/laporan-kasir-kp/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Laporan Penjualan KP',
    },
    children: [
      {
        path: '',
        component: LaporanPenjualanKP,
        meta: {
          auth: true,
          title: 'Laporan Penjualan KP | Gudang',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-laporan-penjualan-kp',
        component: TambahLaporanPenjualanKP,
        meta: {
          auth: true,
          title: 'Tambah Laporan Penjualan KP | Gudang',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Laporan Penjualan KP',
        },
      },
    ],
  },
  {
    path: root + '/laporan-via-kp/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Laporan Penjualan KP',
    },
    children: [
      {
        path: '',
        component: LaporanViaKP,
        meta: {
          auth: true,
          title: 'Laporan Penjualan Via KP | Penjualan',
          layout: LayoutDefault,
        },
      },
    ],
  },
];
