import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  productLimitsByProduct: {
    data: [],
    meta: {
      page: {},
    },
  },
  productLimit: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getProductLimitsByProduct: (state) => {
    return state.warehouseByOffice;
  },
  getProductLimit: (state) => {
    return state.productLimit;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setProductLimitsByProduct: (state, data) =>
    (state.productLimitsByProduct = data),
  setProductLimit: (state, data) => (state.productLimit = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchProductLimitsByProduct(
    { commit, rootGetters },
    { productId, originOffice, destinationOffice }
  ) {
    commit('setLoader', true);
    const office_categories =
      rootGetters['office_categories/getAvailableOfficeCategories'];
    let origin_office_id = office_categories.filter(function (el) {
      return el.attributes.name === originOffice;
    });
    let destination_office_id = office_categories.filter(function (el) {
      return el.attributes.name === destinationOffice;
    });
    const params = {};
    let filter = [];
    if (originOffice && originOffice !== '')
      filter['origin_office_category_id'] = origin_office_id[0].id;
    if (destinationOffice && destinationOffice !== '')
      filter['destination_office_category_id'] = destination_office_id[0].id;
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, { sort: '-createdAt' });
    try {
      let response = await ApiService.get(
        'api/v1/products/' + productId + '/product-limits',
        {
          params,
        }
      );
      commit('setProductLimitsByProduct', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setProductLimitsByProduct', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async updateProductLimit({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/product-limits/' + payload.data.id,
        payload
      );
      commit('setProductLimit', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setProductLimit', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
