<template>
  <div
    :class="withStyle ? ['rounded-lg bg-white shadow', padless ? '' : 'px-4 py-5 sm:p-6'] : null"
  >
    <div
      v-if="title || description"
      class="flex items-center justify-between pb-5"
    >
      <div class="space-y-2">
        <h3 v-if="title" class="text-lg font-bold leading-6 text-gray-900">
          {{ title }}
        </h3>
        <p v-if="description" class="max-w-4xl text-sm text-gray-500">
          {{ description }}
        </p>
      </div>
      <slot name="action"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'BaseCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    withStyle: {
      default: true,
    },
    padless: Boolean
  },
};
</script>
