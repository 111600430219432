import ApiService from '@/services/api.service';
const officeExpedition = {
  id: '',
  attributes: {},
};
const state = {
  isLoading: false,
  error: null,
  officeExpedition,
  officeExpeditions: {
    data: [officeExpedition],
    meta: {
      page: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getOfficeExpeditions: (state) => {
    return state.officeExpeditions;
  },
  getOfficeExpedition: (state) => {
    return state.officeExpedition;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setError: (state, status) => (state.error = status),
  setOfficeExpeditions: (state, data) => (state.officeExpeditions = data),
  setOfficeExpedition: (state, data) => (state.officeExpedition = data),
};

const actions = {
  async fetchOfficeExpeditions({ commit }, { params } = {}) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get('api/v1/office-expeditions', {
        params,
      });
      commit('setOfficeExpeditions', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOfficeExpeditions', { data: [], meta: { page: {} } });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async createOfficeExpedition({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        'api/v1/office-expeditions',
        payload
      );
      commit('setOfficeExpedition', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setOfficeExpedition', officeExpedition);
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async updateOfficeExpedition({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/office-expeditions/' + payload.data.id,
        payload
      );
      commit('setOfficeExpedition', response.data);
      commit('setError', null);
      return response;
    } catch (error) {
      commit('setError', error.response.data.errors);
    }
    commit('setLoader', false);
  },
  async fetchOfficeExpeditionsByOffice({ commit }, { office_id, params } = {}) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get(
        'api/v1/offices/' + office_id + '/office-expeditions',
        { params }
      );
      commit('setOfficeExpeditions', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOfficeExpeditions', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async attachToExpedition({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        'api/v1/office-expeditions/-actions/attachToExpedition',
        payload
      );
      commit('setOfficeExpedition', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      dispatch(
        'alert/createAlert',
        { data: error.response.data, type: 'warning' },
        { root: true }
      );
      commit('setOfficeExpedition', officeExpedition);
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
