import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: '',
  movementDetails: {
    data: [],
    meta: {
      page: {},
    },
  },
  movementDetail: {
    data: {
      attributes: {},
    },
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getSuppliers: (state) => {
    return state.movementDetails;
  },
  getSupplier: (state) => {
    return state.movementDetail;
  },
  getSupplierProducts: (state) => {
    return state.products;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setMovementDetails: (state, data) => (state.movementDetails = data),
  setMovementDetail: (state, data) => (state.movementDetail = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async fetchMovementDetails({ commit }, { params }) {
    commit('setLoader', true);
    try {
      let response = await ApiService.get('api/v1/movement-details', {
        params,
      });
      commit('setMovementDetails', response.data);
      return response;
    } catch (error) {
      commit('setMovementDetails', { data: [] });
    } finally {
      commit('setLoader', false);
    }
  },
  async fetchMovementDetailById({ commit }, { id, params }) {
    commit('setLoader', true);
    try {
      const response = await ApiService.get(`api/v1/movement-details/${id}`, {
        params,
      });
      commit('setMovementDetail', response.data);
      return response;
    } catch (error) {
      commit('setMovementDetail', {
        data: {
          attributes: {},
        },
      });
    } finally {
      commit('setLoader', false);
    }
  },
  async createMovementDetail({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/movement-details', payload);
      commit('setMovementDetail', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setMovementDetail', {});
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
  async updateMovementDetail({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/movement-details/' + payload.data.id,
        payload
      );
      commit('setMovementDetail', response.data);
      commit('setError', null);
      commit('setLoader', false);
      dispatch(
        'alert/createAlert',
        { data: 'Data Berhasil Diubah', status: 'success' },
        { root: true }
      );

      return response;
    } catch (error) {
      commit('setMovementDetail', {});
      commit('setError', error);
    } finally {
      commit('setLoader', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
