<template>
  <div class="mx-auto flex flex-col gap-y-6 px-4 pb-12 sm:px-4 lg:px-8">
    <h1 class="pt-4 text-2xl font-bold leading-6 text-gray-900">
      Informasi Saldo
    </h1>
    <Stats
      :active-tab="activeTab"
      :tabs="tabs"
      @set-tabs="setTabs"
      @set-active-tab="setActiveTab"
    />
    <h3 class="pt-4 text-2xl font-bold leading-6 text-gray-900">
      {{ tabs.find((it) => it.value == activeTab).name }}
    </h3>
    <div class="space-y-4">
      <div class="inline-block min-w-full align-middle">
        <Datatable>
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Kode
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Tanggal
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Catatan
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Debit
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Kredit
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Balance
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <template v-if="getSaldo.data.length">
              <tbody>
                <tr
                  class="bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getSaldo.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.data.code ?? '-' }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.createdAt | formatDate }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.data.description ?? '-' }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    {{
                      (user.current_role === 'Stockist Center'
                        ? data.attributes.restock_amount > 0
                          ? data.attributes.restock_amount
                          : 0
                        : data.attributes.data.deposit_topup) | toCurrency
                    }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    {{
                      (user.current_role === 'Stockist Center'
                        ? 0 > data.attributes.restock_amount
                          ? data.attributes.restock_amount
                          : 0
                        : data.attributes.data.deposit_topup) | toCurrency
                    }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    {{
                      (data.attributes.data.bundle_report_price ?? '-')
                        | toCurrency
                    }}
                  </td>
                </tr>
              </tbody>
              <tbody class="bg-gray-50">
                <tr>
                  <td
                    colspan="5"
                    class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                  >
                    Begining Balance
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                  >
                    {{ getSaldo.meta.begining_restock_balance | toCurrency }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="5"
                    class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                  >
                    Total Credit
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                  >
                    {{
                      getSaldo.meta.total_credit_restock_balance | toCurrency
                    }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="5"
                    class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                  >
                    Total Debit
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                  >
                    {{ getSaldo.meta.total_debit_restock_balance | toCurrency }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="5"
                    class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                  >
                    Ending Balance
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                  >
                    {{ getSaldo.meta.ending_restock_balance | toCurrency }}
                  </td>
                </tr>
              </tbody>
            </template>
            <tbody v-else>
              <tr class="bg-white hover:bg-green-100">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  colspan="7"
                >
                  Kosong
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </div>
    </div>
  </div>
</template>

<script>
import Stats from './Stats.vue';
import Datatable from '@/components/base/Datatable';
import { StorageService } from '@/services/storage.service';
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';

const tabs = [
  {
    name: 'Total Deposit',
    stat: 0,
    value: 'total_deposit',
  },
  {
    name: 'Nilai Deposit Awal / Tambahan',
    stat: 0,
    value: 'deposit_awal',
  },
  {
    name: 'Saldo Restock',
    stat: 0,
    class: 'bg-blue-100 hover:bg-blue-200',
    value: 'restock',
  },
  {
    name: 'Saldo Sisa Pembayaran',
    stat: 0,
    value: 'sisa_pembayaran',
  },
  // { name: 'Saldo Utang', stat: attributes.debt_balance }, // Sementara Di Hide
];
export default {
  name: 'MyBalance',
  data() {
    return {
      showFilter: false,
      activeTab: tabs[2].value, // defaultnya saldo restock
      tabs,
    };
  },
  created() {
    const handleEscape = () => {
      this.showProfile = false;
      this.showSidebar = false;
      this.showRole = false;
      this.showMenu = false;
    };
    this.setEscape(handleEscape);

    this.saldoHistory({
      office_id: StorageService.getUser().office_id,
      sort: '-createdAt',
    });
  },
  methods: {
    dayjs,
    ...mapActions({
      saldoHistory: 'offices/saldoHistory',
    }),
    clickFilter() {
      this.showFilter = !this.showFilter;
    },
    setActiveTab(stat) {
      this.activeTab = stat.value;
    },
    setTabs(tabs) {
      this.tabs = tabs;
    },
  },
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
      getSaldo: 'offices/getSaldo',
      user: 'auth/getUser',
    }),
  },
  components: { Stats, Datatable },
};
</script>
