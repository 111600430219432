var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"parent",staticClass:"flex h-full items-center font-light",staticStyle:{"flex-shrink":"1","flex-grow":"0"},style:({ 'max-width': `${50 + 2 * _vm.visibleIndexCount}vw` })},[(_vm.currentIndex != 0)?_c('button',{staticClass:"px-2",on:{"click":_vm.prev}},[_c('Icon',{staticClass:"h-5 w-5",attrs:{"icon":"heroicons:chevron-double-left-20-solid"}})],1):_vm._e(),_vm._l((_vm.visibleItems),function(item){return [_c('div',{key:item.id,ref:"visibleItem",refInFor:true,staticClass:"flex mx-1 h-full items-center",on:{"click":_vm.toggle}},[(item.attributes.type != 'group')?[_c('router-link',{staticClass:"flex mx-1 rounded text-sm",attrs:{"to":item.attributes.url[0] == '/'
              ? item.attributes.url
              : '/' + item.attributes.url,"exact-active-class":'font-semibold'}},[_vm._v(" "+_vm._s(item.attributes.name)+" ")])]:[_c('div',{staticClass:"flex h-full items-center",staticStyle:{"flex-shrink":"1"},attrs:{"id":"tree-dropdown"},on:{"click":function($event){$event.stopPropagation();return _vm.onMouseEnter(item)}}},[_c('div',{staticClass:"inline-flex cursor-pointer items-center rounded",class:[]},[_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(item.attributes.name)+" ")]),_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":"heroicons:chevron-down-20-solid"}}),_c('div',[_c('transition',{attrs:{"enter-active-class":"transition duration-100 ease-out","enter-from-class":"transform scale-95 opacity-0","enter-to-class":"transform scale-100 opacity-100","leave-active-class":"transition duration-100 ease-in","leave-to-class":"transform scale-95 opacity-0"}},[(_vm.isOpen && item.id == _vm.selectedItem.id)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClose),expression:"onClose"}],staticClass:"relative z-50"},[_c('div',{staticClass:"focus:outline-none absolute right-0 z-50 mt-6 w-72 origin-top-right rounded-md bg-white py-3 shadow-lg ring-1 ring-black ring-opacity-5",attrs:{"role":"menu","aria-orientation":"vertical","aria-labelledby":"user-menu-button","tabindex":"-1"}},_vm._l((_vm.childrenMenus(item.id)),function(currentMenu){return _c('div',{key:currentMenu.id,staticClass:"relative space-y-2 px-3"},[_c('router-link',{staticClass:"flex h-full items-center gap-x-2 rounded-md p-4 text-sm text-gray-900",class:_vm.defaultLink,attrs:{"to":currentMenu.attributes.url[0] == '/'
                            ? currentMenu.attributes.url
                            : '/' + currentMenu.attributes.url,"active-class":'font-semibold'}},[_c('div',{staticClass:"flex w-full items-center justify-between"},[_c('div',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(currentMenu.attributes.name)+" "),_c('p',{staticClass:"text-xs font-light text-gray-400"},[_vm._v(" "+_vm._s(currentMenu.attributes.description)+" ")])]),(_vm.subChild(currentMenu).length)?_c('div',[_c('Icon',{attrs:{"icon":"heroicons:chevron-right-20-solid"}})],1):_vm._e()]),(_vm.subChild(currentMenu).length)?_c('ul',{staticClass:"focus:outline-none absolute right-0 z-50 w-72 origin-top-right rounded-md bg-white py-3 text-gray-900 shadow-lg ring-1 ring-black ring-opacity-5",staticStyle:{"right":"-100%","top":"0"}},_vm._l((_vm.subChild(currentMenu)),function(child){return _c('router-link',{key:child.id,staticClass:"block p-4 text-center",class:_vm.defaultLink,attrs:{"to":child.attributes.url[0] == '/'
                                ? child.attributes.url
                                : '/' + child.attributes.url,"active-class":_vm.activeColor}},[_vm._v(" "+_vm._s(child.attributes.name)+" ")])}),1):_vm._e()])],1)}),0)]):_vm._e()])],1)],1)])]],2)]}),(_vm.currentIndex < _vm.items.length - _vm.visibleIndexCount)?_c('button',{staticClass:"px-2",on:{"click":_vm.next}},[_c('Icon',{staticClass:"h-5 w-5",attrs:{"icon":"heroicons:chevron-double-right-20-solid"}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }